import React, { useEffect, useState } from 'react';
import { ModalBody, FormGroup } from 'reactstrap';
import Modal from 'react-modal';
import { DefaultInput } from '../../components/Input/Input';
import { AiOutlineCheck, AiOutlineClose } from 'react-icons/ai';
import { DefaultButton } from '../../components/Button/Button';
import GridTable from '@nadavshaar/react-grid-table';
import CarComponent from '../../components/3dCar';
import { useSelector } from 'react-redux';
import { supabase } from '../../supabaseClient';

import { isMobile } from 'react-device-detect';
import { inPlaceSort } from 'fast-sort';
import { IoCar, IoCarOutline } from 'react-icons/io5';
import { AddParts } from '../ModalBudget/components/TabsContent/AddParts';

const PieceAddScreen = ({ setOpen, isOpen, setValues, partsArray, budget }) => {
  const company = useSelector((state) => state.app.company || {});
  const [parts, setParts] = useState([]);
  const [data, setData] = useState([]);
  const [search, setSearch] = useState('');
  const [toggle, setToggle] = useState(false);
  const [isModalToCreatePartOpen, setIsModalToCreatePartOpen] = useState(false);

  useEffect(() => {
    loadParts();
  }, [isOpen, isModalToCreatePartOpen]);

  useEffect(() => {
    const addGridtableFilter = document.querySelector('.add-gridtable-service');

    if (addGridtableFilter) {
      addGridtableFilter.classList.toggle('add-gridtable-filter', toggle);
    }
  }, [toggle]);

  const loadParts = async () => {
    let { data, error } = await supabase
      .from('Part')
      .select('*')
      .eq('company', company.id)
      .is('deleted_at', null);
    if (!error) {
      setData(data);
    }
  };

  const onSave = () => {
    const filteredData = data?.filter((item) => item.check);
    setValues(filteredData);
    setData([]);
    setSearch('');
    setParts([null]);
    setOpen((open) => !open);
  };

  const closeModal = () => {
    setData([]);
    setSearch('');
    setParts([null]);
    setOpen((open) => !open);
  };

  const changeCheck = (item) => {
    setData((values) =>
      values.map((verify) => {
        const check = !verify.check ? true : false;
        return verify.id === item.id ? { ...verify, check } : { ...verify };
      })
    );
  };

  const changeAllChecked = (isChecked) => {
    setData((data) => {
      const filteredData = data.filter(
        (item) =>
          !partsArray?.some((partsArray) => partsArray.name === item.name)
      );
      return filteredData.map((item) => ({
        ...item,
        check: isChecked,
      }));
    });
  };

  const pieceChecklist = filteredData(data, search, parts, budget).filter(
    (item) => {
      const filteredPieces = partsArray?.find((row) => item?.name === row.name);
      return filteredPieces?.name !== item?.name;
    }
  );

  const columns = [
    {
      id: 'checkbox',
      field: 'checkbox',
      label: '',
      resizable: false,
      width: '60px',
      headerCellRenderer: ({ data }) => {
        return (
          <div className="field-actions-budget">
            <input
              type="checkbox"
              checked={data?.check}
              className="checkbox-item"
              onChange={(event) => changeAllChecked(event.target.checked)}
            />
          </div>
        );
      },
      cellRenderer: ({ data }) => {
        return (
          <div className="field-actions-budget">
            <input
              type="checkbox"
              checked={data?.check}
              className="checkbox-item"
              onChange={(event) => changeCheck(data)}
            />
          </div>
        );
      },
    },
    {
      id: 2,
      field: 'name',
      label: 'Peça',
      width: '1fr',
      className: 'field-description',
      resizable: false,
      cellRenderer: ({ data }) => {
        return (
          <div style={{ width: '100%' }}>
            <div
              style={{
                paddingLeft: '5px',
              }}
            >
              {data.name}
            </div>
          </div>
        );
      },
    },
  ];

  inPlaceSort(pieceChecklist).by('name');

  return (
    <Modal
      onRequestClose={closeModal}
      className={'prospera-modal'}
      isOpen={isOpen}
    >
      <input style={{ visibility: 'hidden', position: 'absolute' }} />
      <ModalBody className="modal-body">
        {isModalToCreatePartOpen ? (
          <AddParts
            isModalToCreatePartOpen={isModalToCreatePartOpen}
            setIsModalToCreatePartOpen={setIsModalToCreatePartOpen}
          />
        ) : (
          <div className="modal-container add-service-modal">
            <div className="modal-header" style={{ paddingBottom: 10 }}>
              <DefaultButton
                className="modal-footer-button margin-0"
                label={isMobile ? '' : 'Salvar'}
                icon={<AiOutlineCheck className="button-icon" />}
                onClick={onSave}
              />
              <DefaultButton
                className="modal-footer-button cancel"
                label={isMobile ? '' : 'Cancelar'}
                icon={<AiOutlineClose className="button-icon-cancel" />}
                onClick={closeModal}
              />
            </div>
            <div
              style={{
                paddingTop: '15px',
                paddingLeft: 5,
                display: 'flex',
                justifyContent: 'space-between',
                alignItems: 'center',
              }}
            >
              <text
                style={{
                  color: 'white',
                  fontStyle: 'normal',
                  fontFamily: 'poppins',
                  fontSize: '14px',
                  fontWeight: '500',
                  marginBottom: '0.5rem',
                  margin: '5px 0px 0px 5px',
                }}
              >
                Serviços
              </text>

              <DefaultButton
                className="add-button"
                label={'Cadastrar Peça'}
                onClick={() => setIsModalToCreatePartOpen(true)}
              />
            </div>
            <div>
              <FormGroup className={'field-actions-budget'}>
                <DefaultInput
                  placeholder={'Buscar peças'}
                  typeChange={2}
                  onChange={(e) => {
                    setSearch(e);
                  }}
                />
                {toggle ? (
                  <IoCarOutline
                    onClick={() => {
                      setToggle((toggle) => !toggle);
                    }}
                    style={{ paddingLeft: 10 }}
                    color="white"
                    fontSize={30}
                  />
                ) : (
                  <IoCar
                    onClick={() => {
                      setToggle((toggle) => !toggle);
                    }}
                    style={{ paddingLeft: 10 }}
                    color="white"
                    fontSize={30}
                  />
                )}
              </FormGroup>
            </div>
            {toggle ? (
              <div>
                <div className="service-upper-div">
                  <div
                    id="myCarComponent"
                    className="service-img-placement"
                    style={{
                      maxWidth: '700px',
                      maxHeight: '700px',
                      overflow: 'hidden',
                      flexDirection: 'column',
                    }}
                  >
                    <CarComponent
                      containerWidth={isMobile ? 300 : 500}
                      containerHeight={350}
                      defaultValue={''}
                      onChange={(value) =>
                        setParts((oldValues) => partsChange(oldValues, value))
                      }
                      partsAsFilter={true}
                    />
                  </div>
                </div>
              </div>
            ) : (
              <div />
            )}
            <div className="bodyshop-body modal-budget modal-budget-gridtable add-gridtable-service">
              <GridTable
                columns={columns}
                rows={pieceChecklist}
                showSearch={false}
                canReorder={false}
                enableColumnsReorder={false}
                isPaginated={false}
                showColumnVisibilityManager={false}
                showRowsInformation={false}
                texts={{ noResults: 'Nenhum resultado foi encontrado' }}
              />
            </div>
          </div>
        )}
      </ModalBody>
    </Modal>
  );
};
export default PieceAddScreen;

const partsChange = (oldValues, newValue) => {
  const array =
    oldValues && oldValues.length > 0
      ? oldValues?.filter((item) => item !== null && item)
      : [];
  if (array?.filter((item) => item === newValue[0])?.length > 0) {
    return array?.filter((item) => item !== newValue[0]);
  } else {
    return array.concat(newValue[0]);
  }
};

const filteredData = (data, search, parts) => {
  return data.filter((item) => doFilter(item, search, parts));
};

const doFilter = (item, search, parts) => {
  let isSearch = true;
  if (search) {
    isSearch =
      item?.name?.toLowerCase().includes(search.toLowerCase()) ||
      item?.synonymous?.toLowerCase().includes(search.toLowerCase());
  }

  let isParts = true;
  if (
    parts &&
    parts.length > 0 &&
    parts?.filter((value) => value !== null)?.length > 0
  ) {
    isParts = item.part === parts?.find((value) => value === item?.part);
  }

  return isSearch && isParts;
};
