import {
  DropzoneInput,
  FractionInput,
  LabelInput,
  TextInput,
} from "../../components/Input/Input";
import { AiOutlineCheck, AiOutlineClose } from "react-icons/ai";
import { DefaultButton } from "../../components/Button/Button";
import { BsFillTrashFill } from "react-icons/bs";
import CarComponent from "../../components/3dCar";
import { isMobile } from "react-device-detect";

const ModalAddParts = ({
  values,
  closeModal,
  isNew,
  onChange,
  onSave,
  compareValues,
  onRemove,
}) => {
  return (
    <div className="modal-container">
      <div className="modal-header">
        <div className="header-title">
          <h4 className="modal-title">
            {isNew ? "Adicionar Peça" : "Editar Peça"}
          </h4>
        </div>
        <div className="header-buttons">
          {compareValues ? (
            <DefaultButton
              className="modal-footer-button"
              label={isMobile ? "" : "Salvar"}
              icon={<AiOutlineCheck className="button-icon" />}
              onClick={onSave}
            />
          ) : (
            <div />
          )}
          <DefaultButton
            className="modal-footer-button cancel"
            label={isMobile ? "" : "Cancelar"}
            icon={<AiOutlineClose className="button-icon-cancel" />}
            onClick={closeModal}
          />
        </div>
      </div>
      <div>
        <div className="desc-input company">
          <br />
          <div className="bodyshop-title">
            <TextInput
              value={values.name}
              placeholder="Adicionar nome da peça"
              onChange={(event) =>
                onChange("name", event.target.value.toUpperCase())
              }
              className="item-title"
            />
          </div>
          <br />
          <div>
            <div className="service-part-type">
              <FractionInput
                onChange={(value) => onChange("fraction", value)}
                value={values.fraction}
                placeholder={"0,00"}
                label="Fração peça"
                className="budget-details-margin"
              />
            </div>
            {isMobile ? (
              <div style={{ alignItems: "center", paddingTop: "10px" }}>
                <text
                  style={{
                    color: "white",
                    fontFamily: "poppins",
                    fontSize: "14",
                    fontWeight: "500",
                    paddingTop: "35px",
                    paddingLeft: "5px",
                    width: "220px",
                  }}
                >
                  Quantidade de tinta / ml
                </text>
                <div
                  className="service-part-type"
                  style={{ display: "flex", flexDirection: "row", gap: "10px" }}
                >
                  <LabelInput
                    onChange={(value) => onChange("p_paint", value)}
                    value={values.p_paint}
                    className="piece-paint"
                    label="P"
                    type={"number"}
                  />
                  <LabelInput
                    onChange={(value) => onChange("m_paint", value)}
                    value={values.m_paint}
                    className="piece-paint"
                    label="M"
                    type={"number"}
                  />
                  <LabelInput
                    onChange={(value) => onChange("g_paint", value)}
                    value={values.g_paint}
                    label="G"
                    type={"number"}
                    className="piece-paint"
                  />
                </div>
              </div>
            ) : (
              <div style={{ display: "flex", alignItems: "center" }}>
                <text
                  style={{
                    color: "white",
                    fontFamily: "poppins",
                    fontSize: "14",
                    fontWeight: "500",
                    paddingTop: "35px",
                    width: "220px",
                  }}
                >
                  Quantidade de tinta / ml
                </text>
                <LabelInput
                  onChange={(value) => onChange("p_paint", value)}
                  value={values.p_paint}
                  className="piece-paint"
                  label="P"
                  type={"number"}
                />
                <LabelInput
                  onChange={(value) => onChange("m_paint", value)}
                  value={values.m_paint}
                  className="piece-paint"
                  label="M"
                  type={"number"}
                />
                <LabelInput
                  onChange={(value) => onChange("g_paint", value)}
                  value={values.g_paint}
                  className="piece-paint"
                  label="G"
                  type={"number"}
                />
              </div>
            )}
            {isMobile ? (
              <div style={{ alignItems: "center" }}>
                <text
                  style={{
                    color: "white",
                    fontFamily: "poppins",
                    fontSize: "14",
                    fontWeight: "500",
                    width: "220px",
                  }}
                >
                  Quantidade de verniz / ml
                </text>
                <div
                  className="service-part-type"
                  style={{ display: "flex", gap: "10px" }}
                >
                  <LabelInput
                    onChange={(value) => onChange("p_varnish", value)}
                    value={values.p_varnish}
                    type={"number"}
                  />
                  <LabelInput
                    onChange={(value) => onChange("m_varnish", value)}
                    value={values.m_varnish}
                    type={"number"}
                  />
                  <LabelInput
                    onChange={(value) => onChange("g_varnish", value)}
                    value={values.g_varnish}
                    type={"number"}
                  />
                </div>
              </div>
            ) : (
              <div style={{ display: "flex", alignItems: "center" }}>
                <text
                  style={{
                    color: "white",
                    fontFamily: "poppins",
                    fontSize: "14",
                    fontWeight: "500",
                    width: "220px",
                  }}
                >
                  Quantidade de verniz / ml
                </text>
                <LabelInput
                  onChange={(value) => onChange("p_varnish", value)}
                  value={values.p_varnish}
                  type={"number"}
                />
                <LabelInput
                  onChange={(value) => onChange("m_varnish", value)}
                  value={values.m_varnish}
                  type={"number"}
                />
                <LabelInput
                  onChange={(value) => onChange("g_varnish", value)}
                  value={values.g_varnish}
                  type={"number"}
                />
              </div>
            )}
          </div>
        </div>
        <div className="production-phase-main-upper-div">
          <div className="div-production-description service-part-type">
            <LabelInput
              value={values.synonymous}
              label={"Sinônimos"}
              placeholder={
                "Adicionar sinônimos, para inserir mais de um acrescente vírgula (,) entre eles"
              }
              onChange={(value) => onChange("synonymous", value)}
              className="budget-details-margin"
            />
          </div>
        </div>
        <div>
          <div style={{ paddingTop: "20px" }}>
            <div className="service-part-type">
              <DropzoneInput
                table={"parts"}
                onChange={(value) => onChange("images", value)}
                label={"Imagem"}
                value={values.images}
                single={false}
                className="budget-details-margin"
              />
            </div>
            <div className="service-upper-div">
              <div
                id="myCarComponent"
                className="service-img-placement"
                style={{
                  paddingTop: "30px",
                  maxWidth: "700px",
                  maxHeight: "700px",
                  overflow: "hidden",
                  flexDirection: "column",
                }}
              >
                <CarComponent
                  containerWidth={isMobile ? 300 : 500}
                  containerHeight={350}
                  defaultValue={values && values.part && values.part[0]}
                  onChange={(value) => onChange("part", value)}
                  partsAsFilter={true}
                />
              </div>
            </div>
          </div>
        </div>
      </div>
      <div className="modal-footer-table">
        {values && !values.id ? (
          <div />
        ) : (
          <DefaultButton
            className="modal-footer-button delete"
            label={"Excluir"}
            icon={<BsFillTrashFill className="button-icon-cancel" />}
            onClick={onRemove}
          />
        )}
      </div>
    </div>
  );
};

export default ModalAddParts;
