import React, { useEffect, useState } from 'react';
import { useDispatch, useSelector } from 'react-redux';
import { useHistory, useLocation, useParams } from 'react-router-dom';
import { setData } from '../../core/redux/app/Actions';
import { supabase } from '../../supabaseClient';
import { compareValues } from '../ModalClients/ModalClientsContainer';
import Swal from 'sweetalert2';
import ModalService from '../../screens/ModalService/ModalService';
import { saveService } from '../../core/utils/service';
import { alertError, alertSuccess } from '../../components/Toast';

const ModalServiceContainer = ({ props, isOpen }) => {
  const dispatch = useDispatch();
  const history = useHistory();
  const location = useLocation();
  const id = location.pathname.split('/')[3];
  const params = useParams();
  const isNew = params.id === '0';
  const [pieces, setPieces] = useState([]);
  const [values, setValues] = useState({});
  const [serviceType, setServiceType] = useState([]);
  const [oldValues, setOldValues] = useState({ active: true });
  const reloadServices = useSelector(
    (state) => state.app.reloadServices || false
  );
  const company = useSelector((state) => state.app.company || {});
  const user = useSelector((state) => state.app.user || {});
  const [mesh, setMesh] = useState(false);

  const loadData = async () => {
    let { data, error } = await supabase
      .from('Services')
      .select('*')
      .eq('id', id)
      .is('deleted_at', null);

    if (!error) {
      const item = data[0];
      const service_type = serviceType.find((row) => {
        const regex = /\(([^)]+)\)/;
        const match = item.title.match(regex);
        return match ? row.title === match[1] : false;
      });

      setValues({ ...item, service_type });
      setOldValues({ ...item, service_type });
    }
  };

  useEffect(() => {
    loadPieces();
  }, [isOpen]);

  const loadPieces = async () => {
    let { data, error } = await supabase
      .from('Part')
      .select('*')
      .eq('company', company.id)
      .is('deleted_at', null);
    if (!error) {
      setPieces(
        data.map((item) => ({
          ...item,
          value: item.id,
          label: item.name,
          parts: item.part,
        }))
      );
    }
  };

  useEffect(() => {
    if (id !== '0' && serviceType.length > 0) {
      loadData();
    }
  }, [id, serviceType]);

  useEffect(() => {
    if (values.pieces && values.service_type && values.service_type.title) {
      setValues((prevValues) => ({
        ...prevValues,
        title: `${values.pieces} (${values.service_type.title})`,
      }));
    }
  }, [values.pieces, values.service_type && values.service_type.title]);

  useEffect(() => {
    loadServiceType();
  }, []);

  const loadServiceType = async () => {
    let { data, error } = await supabase
      .from('ServiceTypes')
      .select('*')
      .eq('id_company', company.id)
      .is('deleted_at', null);
    if (!error) {
      setServiceType(
        data.map((item) => ({ ...item, label: item.title, value: item.id }))
      );
    }
  };

  useEffect(() => {
    const path = location.pathname.split('/')[1];
    dispatch(
      setData({ [`compareValues_${path}`]: compareValues(oldValues, values) })
    );
  }, [values, oldValues]);

  const closeModal = () => {
    if (compareValues(oldValues, values)) {
      Swal.fire({
        title: 'Deseja sair sem salvar?',
        showCancelButton: true,
        showConfirmButton: false,
        showDenyButton: true,
        icon: 'warning',
        text: 'Ao sair, seus dados seram descartados.',
        denyButtonText: `Descartar`,
        cancelButtonText: 'Cancelar',
      }).then((result) => {
        if (result.isDenied) {
          history.push('/bodyshop');
        }
      });
    } else {
      history.push('/bodyshop');
    }
  };

  const onChange = (field, value) => {
    setValues((values) => ({ ...values, [field]: value }));
  };

  const onSave = async () => {
    const parsedPValue = parseFloatWithoutCurrencySymbolOrCommas(
      values.p_value
    );
    const parsedGValue = parseFloatWithoutCurrencySymbolOrCommas(
      values.g_value
    );
    const parsedMValue = parseFloatWithoutCurrencySymbolOrCommas(
      values.m_value
    );

    const updatedValues = {
      ...values,
      p_value: parsedPValue,
      g_value: parsedGValue,
      m_value: parsedMValue,
    };

    try {
      const extraProps = {};
      if (updatedValues && !updatedValues.title) {
        alertError('Título é obrigatório.');
        return;
      }

      if (updatedValues && !updatedValues.service_type) {
        alertError('Tipo de serviço é obrigatório.');
        return;
      }

      if (isNew) {
        let { data, error } = await supabase
          .from('Services')
          .select('*')
          .eq('id_company', company.id)
          .eq('pieces', updatedValues?.pieces)
          .eq('service_type', updatedValues?.service_type?.value)
          .is('deleted_at', null);

        if (error) {
          throw new Error('Erro ao carregar');
        } else if (data && data.length > 0) {
          alertError(
            'Já existe um serviço com essa configuração de peça e tipo de serviço.'
          );
          return;
        }
      }

      const save = await saveService(updatedValues.id, {
        ...updatedValues,
        ...extraProps,
        id_company: company.id,
        service_type: updatedValues?.service_type?.value,
      });
      alertSuccess('Sucesso ao salvar o serviço!');
      history.push('/bodyshop');
      dispatch(setData({ reloadServices: !reloadServices }));
    } catch (error) {
      alertError('Erro ao salvar o serviço, tente novamente mais tarde.');
    }
  };

  const parseFloatWithoutCurrencySymbolOrCommas = (value) => {
    if (value != null) {
      const numberString = value.toString().replace('R$', '').replace(',', '.');
      const number = parseFloat(numberString);
      return number;
    }
  };

  const onRemove = async () => {
    const confirmation = await Swal.fire({
      title: 'Deseja mesmo excluir?',
      showCancelButton: true,
      showConfirmButton: false,
      showDenyButton: true,
      icon: 'warning',
      text: 'Seus dados serão completamente perdidos!',
      denyButtonText: 'Excluir',
      cancelButtonText: 'Cancelar',
    });

    if (confirmation.isDenied) {
      if (values && values.id) {
        const { data, error } = await supabase
          .from('Services')
          .update({ deleted_at: new Date(), deleted_by: user.id })
          .eq('id', values.id);

        if (!error) {
          alertSuccess('Removido com sucesso!');
          history.push('/bodyshop');
          dispatch(setData({ reloadServices: !reloadServices }));
        } else {
          alertError('Erro ao remover, tente novamente mais tarde');
        }
      } else {
        alertError('Erro ao remover, tente novamente mais tarde');
      }
    } else {
      history.push('/bodyshop');
    }
  };

  return (
    <ModalService
      pieces={filteredData(pieces)}
      compareValues={compareValues(oldValues, values)}
      closeModal={closeModal}
      onChange={onChange}
      values={values}
      serviceType={serviceType}
      isNew={isNew}
      onSave={onSave}
      onRemove={onRemove}
      mesh={mesh}
    />
  );
};

const filteredData = (data, search, parts) => {
  return data?.filter((item) => doFilter(item, search, parts));
};

const doFilter = (item, search, parts) => {
  let isSearch = true;
  if (search) {
    isSearch = item.name.toLowerCase().includes(search);
  }

  let isParts = true;
  if (
    parts &&
    parts.length > 0 &&
    parts?.filter((value) => value !== null)?.length > 0
  ) {
    isParts = item.part === parts?.find((value) => value === item?.part);
  }

  return isSearch && isParts;
};

export default ModalServiceContainer;
