import React, { useState } from 'react';
import GridTable from '@nadavshaar/react-grid-table';
import { inPlaceSort } from 'fast-sort';

const ValueHourChartScreen = ({ columns, rows }) => {

    inPlaceSort(rows).by("title");

    return (
      <div className='gridtable-for-hour-value-chart'>
        <GridTable
          columns={columns}
          rows={rows}
          enableColumnsReorder={false}
          canReorder={false}
          showSearch={true}
          isPaginated={false}
          showColumnVisibilityManager={false}
          showRowsInformation={false}
          texts={{ noResults: 'Nenhum resultado foi encontrado', search: 'Buscar:' }}
            />
      </div>
    )
}


export default ValueHourChartScreen