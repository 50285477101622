import {
  LabelInput,
  LicensePlateInput,
  ChassiInput,
  SelectInput,
  LabelCreatable,
} from "../../components/Input/Input";
import { AiOutlineCheck, AiOutlineClose } from "react-icons/ai";
import { DefaultButton } from "../../components/Button/Button";
import { BsCardText, BsFillTrashFill, BsPerson } from "react-icons/bs";
import { Tab, Tabs, TabList, TabPanel } from "react-tabs";
import { isMobile } from "react-device-detect";

const ModalVehicles = ({
  loadingPlate,
  values,
  closeModal,
  isNew,
  onChange,
  onSave,
  responsible,
  changePlate,
  compareValues,
  onRemove,
  brandOptions,
  modelOptions,
  createOption,
}) => {
  var containPlate = `${values?.plate}`.match(/[A-Z]{3}-[0-9][0-9A-Z][0-9]{2}/);
  return (
    <div className="modal-container">
      <div className="modal-header">
        <div className="header-title">
          <h4 className="modal-title">
            {isNew ? "Adicionar Veículo" : "Editar Veículo"}
          </h4>
        </div>
        <div className="header-buttons">
          {compareValues ? (
            <DefaultButton
              className="modal-footer-button"
              label={isMobile ? "" : "Salvar"}
              icon={<AiOutlineCheck className="button-icon" />}
              onClick={onSave}
            />
          ) : (
            <div />
          )}
          <DefaultButton
            className="modal-footer-button cancel"
            label={isMobile ? "" : "Cancelar"}
            icon={<AiOutlineClose className="button-icon-cancel" />}
            onClick={closeModal}
          />
        </div>
      </div>
      <Tabs>
        <TabList style={{ display: "flex", flexFlow: "row" }}>
          <Tab style={{ width: "100%" }}>
            <text> Dados </text>
            <BsPerson />
          </Tab>
          <Tab style={{ width: "100%" }}>
            <text> Observações </text>
            <BsCardText />
          </Tab>
        </TabList>
        <TabPanel>
          {isMobile ? (
            <div>
              <div className="">
                <LicensePlateInput
                  loadingPlate={loadingPlate}
                  value={values.plate}
                  onChange={(value) => changePlate(value)}
                  placeholder={"Insira a placa do veículo"}
                  label={"Placa"}
                />
              </div>
              <div className="">
                <LabelInput
                  value={values.color}
                  onChange={(value) => onChange("color", value)}
                  placeholder={"Insira a cor do veículo"}
                  label={"Cor do veículo"}
                />
                <LabelInput
                  value={values.fuel}
                  onChange={(value) => onChange("fuel", value)}
                  placeholder={"Insira o combustível do veículo"}
                  label={"Combustível"}
                />
              </div>
            </div>
          ) : (
            <div>
              <div className="main-upper-div width-correction">
                <LicensePlateInput
                  loadingPlate={loadingPlate}
                  value={values.plate}
                  onChange={(value) => changePlate(value)}
                  placeholder={"Insira a placa do veículo"}
                  label={"Placa"}
                />
              </div>
              <div className="main-upper-div width-correction">
                <LabelInput
                  value={values.color}
                  onChange={(value) => onChange("color", value)}
                  placeholder={"Insira a cor do veículo"}
                  label={"Cor do veículo"}
                />
                <LabelInput
                  value={values.fuel}
                  onChange={(value) => onChange("fuel", value)}
                  placeholder={"Insira o combustível do veículo"}
                  label={"Combustível"}
                />
              </div>
            </div>
          )}
          <div className="void-class">
            <SelectInput
              onChange={(event) => onChange("responsible", event.value)}
              placeholder={"Selecione"}
              // isSearchable={!isMobile}
              label={"Responsável"}
              options={responsible}
              defaultValue={
                responsible &&
                responsible.find((item) => item.value === values.responsible) &&
                responsible.find((item) => item.value === values.responsible)
                  .value
                  ? responsible.find(
                      (item) => item.value === values.responsible
                    )
                  : null
              }
            />
          </div>
          <LabelCreatable
            onCreateOption={(name) => createOption(name, "brand")}
            options={brandOptions?.map((item) => ({
              value: item.codigo,
              label: item.nome,
            }))}
            className="generic-input select-creatable"
            value={
              values?.brand
                ? [
                    brandOptions?.find(
                      (item) =>
                        item.nome === values.brand ||
                        (item.nome &&
                          typeof item?.nome === "string" &&
                          item?.nome?.includes(values?.brand)) ||
                        (values?.brand?.includes &&
                          typeof values?.brand?.includes === "function" &&
                          values?.brand?.includes(item?.nome))
                    ) || { label: values?.brand, value: null },
                  ]
                : values.brand
            }
            defaultValue={values.brand}
            onChange={(value) => onChange("brand", value)}
            placeholder={"INSIRA A MARCA DO VEÍCULO"}
            label={"Marca"}
            disabled={!containPlate}
          />
          <LabelCreatable
            disabled={!values.brand}
            onCreateOption={(name) => createOption(name, "model")}
            options={modelOptions?.map((item) => ({
              value: item.codigo,
              label: item.nome,
            }))}
            className="generic-input select-creatable"
            value={
              values?.model
                ? [
                    modelOptions?.find(
                      (item) =>
                        item?.nome === values?.model ||
                        (item?.nome &&
                          typeof item?.nome === "string" &&
                          item?.nome?.includes(values?.model)) ||
                        (values?.model?.includes &&
                          typeof values?.model?.includes === "function" &&
                          values?.model?.includes(item?.nome))
                    ) || { label: values?.model, value: null },
                  ]
                : values.model
            }
            defaultValue={values.model}
            onChange={(value) => onChange("model", value)}
            placeholder={"INSIRA O MODELO DO VEÍCULO"}
            label={"Modelo"}
          />
          <LabelInput
            value={values.year}
            onChange={(value) => onChange("year", value)}
            placeholder={"Insira o ano do veículo"}
            label={"Ano de fabricação"}
          />
          <LabelInput
            value={values.yearmodel === "" ? values.year : values.yearmodel}
            onChange={(value) => onChange("yearmodel", value)}
            placeholder={"Insira o ano do modelo do veículo"}
            label={"Ano do modelo"}
          />
          <div>
            <ChassiInput
              value={values.chassi}
              onChange={(value) => onChange("chassi", value)}
              placeholder={"Insira o Chassi"}
              label={"Chassi do veículo"}
            />
            <LabelInput
              value={values.engine}
              onChange={(value) => onChange("engine", value)}
              placeholder={"Insira o motor"}
              label={"Motor do veículo"}
            />
            <div className="div-dropzone-vehicles">
              {/* <label for="cameraFileInput">
            <DropzoneInput
              onChange={value => onChange('image', value)}
              label={'Imagem'}
              table={'vehicle'}
              value={values.image}
              id="cameraFileInput"
              type="file"
              accept="image/*"
              capture="camera"
            />
          </label> */}
              {/* <DropzoneInput onChange={value => onChange('image', value)} label={'Imagem'} table={'vehicle'} value={values.image} /> */}
            </div>
          </div>
        </TabPanel>

        <TabPanel>
          <LabelInput
            type={"textarea"}
            value={values.observation}
            onChange={(value) => onChange("observation", value)}
            placeholder={"Insira a observação"}
            inputClassname={"text-area-min"}
            label={"Observação"}
          />
        </TabPanel>
      </Tabs>

      {values && !values.id ? (
        <div />
      ) : (
        <DefaultButton
          className="modal-footer-button delete"
          label={"Excluir"}
          icon={<BsFillTrashFill className="button-icon-cancel" />}
          onClick={onRemove}
        />
      )}
      <div className="modal-footer-table"></div>
    </div>
  );
};

export default ModalVehicles;
