import { useMemo, React, useRef, useEffect } from "react";
import GridTable from "@nadavshaar/react-grid-table";
import { BsPencilFill, BsTrashFill } from "react-icons/bs";
import { useHistory } from "react-router-dom";
import { isMobile } from "react-device-detect";
import { inPlaceSort } from "fast-sort";

const ClientScreen = ({ data, company, user, onRemove }) => {
  let history = useHistory();
  const onSearchRef = useRef();
  const isEditable =
    (user && [1, 2].includes(user.id)) ||
    (company && (company.type === 1 || company.type === 2));

  // const isMobileDevice = () => {
  //     return window.innerWidth < 768;
  // }
  // const isMobile = isMobileDevice();

  useEffect(() => {
    onSearchRef.current = (value, searchText) => {
      const user = data.find((item) => item.id == value);
      // const teste = user.Vehicle.find(item => item.plate.toLowerCase().includes(searchText.toLowerCase()))
      // return teste
    };
  }, [data]);

  inPlaceSort(data).by("name");

  const onSearch = (value, searchText) => {
    return onSearchRef.current(value, searchText);
  };

  return (
    <div className="client-container">
      <div
        className={`client-body ${
          isEditable ? "default-table-client" : "non-editable"
        }`}
      >
        <GridTable
          columns={columns(
            (user && [1, 2].includes(user.id)) |
              (company && company.type === 1) ||
              (company && company.type === 2),
            onRemove,
            onSearch,
            history
          )}
          rows={data}
          enableColumnsReorder={false}
          canReorder={false}
          showSearch={true}
          isPaginated={false}
          showColumnVisibilityManager={false}
          showRowsInformation={false}
          texts={{
            noResults: "Nenhum resultado foi encontrado",
            search: "Buscar:",
          }}
        />
      </div>
    </div>
  );
};

export default ClientScreen;

const columns = (isEditable = false, onRemove, onSearch, history) => {
  return [
    // {
    //   id: 1,
    //   field: "created_at",
    //   label: "Data",
    //   width: "1fr",
    //   resizable: false,
    //   cellRenderer: ({ data }) => (
    //     <div
    //       className="clickable-name"
    //       onClick={() => history.push(`/clients/details/${data.id}`, data)}
    //     >
    //       {data.created_at} <br />
    //     </div>
    //   ),
    // },
    {
      id: 2,
      field: "name",
      label: "Nome",
      width: "1fr",
      resizable: false,
      cellRenderer: ({ data }) => (
        <div
          className="clickable-name"
          onClick={() => history.push(`/clients/details/${data.id}`, data)}
        >
          {data.name} <br />
          {isMobile ? data.cellphone : ""}
        </div>
      ),
    },
    !isMobile && {
      id: 3,
      field: "cellphone",
      label: "Celular",
      width: "180px",
      resizable: false,
      cellRenderer: ({ data }) => (
        <div
          className="clickable-name"
          onClick={() => history.push(`/clients/details/${data.id}`, data)}
        >
          {data.cellphone}
        </div>
      ),
    },
    isEditable && {
      id: 4,
      field: "actions",
      label: "Ações",
      resizable: false,
      width: "70px",
      cellRenderer: ({ data }) => (
        <ActionCell data={data} onRemove={onRemove} history={history} />
      ),
    },
    {
      id: 5,
      field: "Vehicle",
      label: "Placa",
      search: ({ value, searchText }) => onSearch(value, searchText),
      getValue: ({ rowData }) => {
        return `${rowData.id}`;
      },
      // width: '180px',
      visible: false,
      // resizable: false
    },
  ].filter((item) => item);
};

const ActionCell = ({ data, onRemove, history }) => {
  return (
    <div>
      <BsPencilFill
        className="icon-client edit"
        onClick={() => history.push(`/clients/details/${data.id}`, data)}
      />
      {/* <BsTrashFill className='icon-client trash' onClick={() => onRemove(data)} /> */}
    </div>
  );
};
