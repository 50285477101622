import { useState, useRef, useEffect } from "react";
import * as THREE from "three";
import { OrbitControls } from "three/examples/jsm/controls/OrbitControls";
import { GLTFLoader } from "three/examples/jsm/loaders/GLTFLoader.js";
// import { RGBELoader } from 'three/examples/jsm/loaders/RGBELoader.js';

import SUV from "../../assets/models/scene.glb";
import background from "../../assets/hdr/kloofendal_misty_morning_puresky_8k.hdr";

// Manter esse arquivo com comentários/documentação pra maior facilidade de manutenção no futuro

const CarComponent = ({
  containerWidth,
  containerHeight,
  defaultValue,
  onChange,
  partsAsFilter = false,
}) => {
  const graphicViewRef = useRef(null);
  const [defaultAtual, setDefaultAtual] = useState(defaultValue);
  const render = use3D(
    containerWidth,
    containerHeight,
    onChange,
    partsAsFilter,
    defaultAtual
  );

  useEffect(() => {
    if (defaultAtual && render && render.scene && render.renderer) {
      let selectedPartIndex = null;
      let selected_parts = [];
      var meshes = [];

      var loader = new GLTFLoader();
      loader.load(SUV, function (gltf) {
        gltf.scene.traverse(function (child) {
          if (child.isMesh) {
            meshes.push(child);
            child.userData.originalColor = child.material.color.clone();
          }
        });
        for (let i = 0; i < meshes.length; i++) {
          const meshies = meshes[i];
          if (meshies.parent.name === defaultAtual) {
            meshies.material.color = new THREE.Color(0xa90c0c);
            const siblings = meshies.parent.children;
            siblings.forEach((sibling) => {
              if (sibling.isMesh && sibling !== meshies) {
                sibling.material.color = new THREE.Color(0xa90c0c);
              }
            });
            selectedPartIndex = i;
            break;
          }
        }
        if (selectedPartIndex !== null) {
          selected_parts.push([selectedPartIndex]);
        }
        render.renderer.clear();
        render.scene.add(gltf.scene);
        render.renderer.render(render.scene, render.camera);
      });
    }
  }, [defaultAtual]);

  useEffect(() => {
    setDefaultAtual(defaultValue);
  }, [defaultValue]);

  return (
    <div>
      <canvas id="graphic_view" ref={graphicViewRef}></canvas>
    </div>
  );
};

export default CarComponent;

const use3D = (
  containerWidth,
  containerHeight,
  onChange,
  partsAsFilter,
  defaultAtual
) => {
  const [render, setRender] = useState(null);

  useEffect(() => {
    let selected_parts = [];
    last_colored_info = {};

    let selectedPartIndex = null;

    // Setting: HTML ID's //
    const GRAPHIC_VIEW_ID = "graphic_view";
    const OBJ_CNT_ID = "nenhuma";

    // Setting: Model //
    const MODEL_LOC = SUV;

    // Setting: canvas size //
    window.innerHeight = containerHeight;
    window.innerWidth = containerWidth;

    // Setting: mesh colors //
    const COLOR_SELECTED = 0xa90c0c;
    const COLOR_HOVERED = 0x00aaff;

    // Globals //
    var meshes = []; // todas as meshes são armazenadas aqui
    var gltfModel;

    // Setup //
    const canvas = document.querySelector("#" + GRAPHIC_VIEW_ID);
    canvas.style.cursor = "pointer";

    //Renderer
    const renderer = new THREE.WebGLRenderer({
      canvas: canvas,
      antialias: true,
      alpha: true,
    });
    renderer.setSize(containerWidth, containerHeight);
    renderer.setPixelRatio(window.devicePixelRatio);
    renderer.setSize(window.innerWidth, window.innerHeight);
    renderer.toneMapping = THREE.ACESFilmicToneMapping;
    renderer.toneMappingExposure = 1;
    // renderer.setSize(WIDTH, HEIGHT); //<-------

    var pmremGenerator = new THREE.PMREMGenerator(renderer);
    pmremGenerator.compileEquirectangularShader();

    document.getElementById("myCarComponent").appendChild(renderer.domElement);

    const scene = new THREE.Scene();
    const camera = new THREE.PerspectiveCamera(
      7,
      containerWidth / containerHeight,
      1,
      10000
    );
    camera.position.set(60, 64, 10);
    camera.position.z = 80;
    const controls = new OrbitControls(camera, renderer.domElement);
    controls.update();
    renderer.render(scene, camera);

    renderer.setClearColor(0xffffff, 0);
    renderer.shadowMap.enabled = true;

    /// Lighting ///
    const light = new THREE.SpotLight(0xffffff);
    light.intensity = 30;
    light.position.set(0, 0, 10);
    scene.add(light);

    const ambient = new THREE.AmbientLight(0xffffff);
    ambient.intensity = 1;
    ambient.position.set(0, 0, 0);
    scene.add(ambient);

    // Geometry //
    const geometry = new THREE.CylinderGeometry(8, 180, 8, 500);
    const material = new THREE.MeshPhongMaterial({ color: 0x303030 });
    const cylinder = new THREE.Mesh(geometry, material);
    cylinder.position.set(0, 0, 0);

    // model
    var loader = new GLTFLoader();
    loader.load(MODEL_LOC, function (gltf) {
      gltfModel = gltf;

      gltf.scene.traverse(function (child) {
        if (child.isMesh) {
          meshes.push(child);
          child.userData.originalColor = child.material.color.clone();
        }
      });

      for (let i = 0; i < meshes.length; i++) {
        const meshies = meshes[i];
        if (meshies.parent.name === defaultAtual) {
          meshies.material.color = new THREE.Color(COLOR_SELECTED);

          const siblings = meshies.parent.children;
          siblings.forEach((sibling) => {
            if (sibling.isMesh && sibling !== meshies) {
              sibling.material.color = new THREE.Color(COLOR_SELECTED);
            }
          });

          selectedPartIndex = i;
          break;
        }
      }

      if (selectedPartIndex !== null) {
        selected_parts.push([selectedPartIndex]);
      } else {
      }

      scene.add(gltf.scene);
      render();
    });

    // Ray casting
    const raycaster = new THREE.Raycaster();
    raycaster.params.Line.threshold = 0.1;
    const mouse = new THREE.Vector2();
    var last_colored_info;

    const section = document.getElementById("myCarComponent");

    let onMouseMove = function (event) {
      var rect = canvas.getBoundingClientRect();
      mouse.x = ((event.clientX - rect.left) / window.innerWidth) * 2 - 1;
      mouse.y = -((event.clientY - rect.top) / window.innerHeight) * 2 + 1;
      mouse.z = 0;

      raycaster.setFromCamera(mouse, camera);
      var intersects = raycaster.intersectObjects(meshes, true);

      if (intersects.length > 0) {
        var selectedPartIndex = meshes.indexOf(intersects[0].object);
        if (
          last_colored_info !== undefined &&
          last_colored_info.object !== undefined
        ) {
          if (
            !selected_parts.includes(meshes.indexOf(last_colored_info.object))
          ) {
            last_colored_info.object.material.color = last_colored_info.color;
          }
        }

        //Backup só se não estiver selected
        if (!selected_parts.includes(selectedPartIndex)) {
          last_colored_info = {
            object: intersects[0].object,
            color: intersects[0].object.material.color.clone(),
          };
        }

        if (!selected_parts.includes(meshes.indexOf(intersects[0].object))) {
          intersects[0].object.material.color = new THREE.Color(COLOR_HOVERED);
        }

        if (
          intersects &&
          intersects[0] &&
          intersects[0].object &&
          intersects[0].object.name &&
          document &&
          document.getElementById(OBJ_CNT_ID) &&
          document.getElementById(OBJ_CNT_ID).innerHTML
        ) {
          document.getElementById(OBJ_CNT_ID).innerHTML =
            intersects[0].object.name;
          // document.getElementById(POPUP_PARTS_NAME_ID).innerHTML =
          //   intersects[0].object.name;
          // document.getElementById(POPUP_PARTS_DRATE_ID).innerHTML =
          //   selected_parts.includes(meshes.indexOf(intersects[0].object))
          //     ? 100
          //     : 0;
        }
      } else {
        //Reset de cor
        if (
          last_colored_info !== undefined &&
          last_colored_info.object !== undefined
        ) {
          if (
            !selected_parts.includes(meshes.indexOf(last_colored_info.object))
          ) {
            last_colored_info.object.material.color = last_colored_info.color;
            if (
              document &&
              document.getElementById(OBJ_CNT_ID) &&
              document.getElementById(OBJ_CNT_ID).innerHTML
            ) {
              document.getElementById(OBJ_CNT_ID).innerHTML = OBJ_CNT_ID;
            }
          }
        }
      }
      renderer.render(scene, camera);
    };

    let onMouseClick = function (event) {
      event.preventDefault();
      var rect = canvas.getBoundingClientRect();
      mouse.x = ((event.clientX - rect.left) / window.innerWidth) * 2 - 1;
      mouse.y = -((event.clientY - rect.top) / window.innerHeight) * 2 + 1;
      mouse.z = 0;

      raycaster.setFromCamera(mouse, camera);
      var intersects = raycaster.intersectObjects(meshes, true);

      if (intersects.length > 0) {
        var selectedGroup = getParentObject(intersects[0].object);
        var selectedPartIndex = meshes.indexOf(selectedGroup);
      }

      var parentObject = getParentObject(intersects[0].object);
      var parentName = parentObject.parent.name;

      if (!partsAsFilter) {
        // Se não for permitido selecionar várias peças, seleciona só o conjunto atual
        if (selected_parts.includes(selectedPartIndex)) {
          // Se o grupo já estiver selecionado, remova-o
          selectedGroup.parent.children.forEach((child) => {
            if (child.isMesh) {
              changeColor(child, child.userData.originalColor);
              selected_parts = selected_parts.filter(
                (partIndex) => partIndex !== meshes.indexOf(child)
              );
            }
          });
          // parentName = null;
        } else {
          // Desselecionar todas as partes selecionadas anteriormente
          selected_parts.forEach((partIndex) => {
            const sibling = meshes[partIndex];
            changeColor(sibling, sibling.userData.originalColor);
          });
          selected_parts = [];

          // Selecionar o novo conjunto de partes
          selectedGroup.parent.children.forEach((child) => {
            if (child.isMesh) {
              const partName = child.name;

              // Desselecionar todas as partes pertencentes ao defaultValue
              meshes.forEach((mesh) => {
                if (mesh.parent.name === defaultAtual) {
                  changeColor(mesh, mesh.userData.originalColor);
                }
              });

              // Selecionar a nova parte
              changeColor(child, COLOR_SELECTED);
              selected_parts.push(meshes.indexOf(child));
            }
          });
        }
      } else {
        // Se for permitido selecionar várias peças, adicione ou remova o grupo inteiro
        if (selected_parts.includes(selectedPartIndex)) {
          // Se o grupo já estiver selecionado, remova-o
          selectedGroup.parent.children.forEach((child) => {
            if (child.isMesh) {
              changeColor(child, child.userData.originalColor);
              selected_parts = selected_parts.filter(
                (partIndex) => partIndex !== meshes.indexOf(child)
              );
            }
          });
          // parentName = undefined;
        } else {
          // Caso contrário, adicione-o à lista de peças selecionadas
          selectedGroup.parent.children.forEach((child) => {
            if (child.isMesh) {
              changeColor(child, COLOR_SELECTED);
              selected_parts.push(meshes.indexOf(child));
            }
          });
        }
      }

      let array = [];
      selected_parts.map((item) => array.push(meshes[item]));
      onChange([parentName]);

      render();
    };

    function changeColor(object, color) {
      object.material.color.set(color);
    }

    function getParentObject(object) {
      if (
        object.parent &&
        object.parent.name &&
        meshes.includes(object.parent)
      ) {
        return object.parent;
      }
      return object;
    }

    section.addEventListener("mousemove", onMouseMove);
    section.addEventListener("mousedown", onMouseClick);

    function render() {
      renderer.render(scene, camera);
    }

    function animate() {
      requestAnimationFrame(animate);
      renderer.render(scene, camera);
    }
    animate();

    setRender({ renderer, scene, camera });
    return () => {
      section.removeEventListener("mousemove", onMouseMove);
      section.removeEventListener("mousedown", onMouseClick);
    };
  }, [containerWidth, containerHeight]);

  return render;
};
