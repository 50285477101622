import { useEffect, useState } from "react";
import { useSelector } from "react-redux";
import ClientScreen from "../../screens/Client/ClientScreen";
import { supabase } from "../../supabaseClient";
import dayjs from "dayjs";

const ClientsContainer = ({}) => {
  const [data, setData] = useState([]);
  const reloadClient = useSelector((state) => state.app.reloadClient || false);
  const company = useSelector((state) => state.app.company || {});
  const user = useSelector((state) => state.app.user || {});

  useEffect(() => {
    loadData();
  }, [reloadClient, company]);

  const loadData = async () => {
    let { data, error } = await supabase
      .from("Client")
      .select(
        `
        *,
        Vehicle(responsible, *),
        Origin(id, *)
        `
      )
      .eq("id_company", company.id)
      .is("deleted_at", null);

    if (!error) {
      setData(
        data.map((item) => ({
          ...item,
          created_at: dayjs(item.created_at).format("DD/MM/YYYY"),
        }))
      );
    }
  };

  return (
    <ClientScreen
      data={data}
      company={company}
      user={user}
      // onRemove={item => swalRemove({ withError: false, functionConfirmRemove: () => onRemove(item) })}
    />
  );
};

export default ClientsContainer;
