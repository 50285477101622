export const getTotalSum = (bodyshopService, parts, aeService) => {
  let totalSum = 0;
  for (const item of bodyshopService) {
    totalSum += parseFloat(item.value) || 0;
  }
  for (const item of parts) {
    totalSum += parseFloat(item.value) || 0;
  }
  for (const item of aeService) {
    totalSum += parseFloat(item.value) || 0;
  }

  return totalSum;
};

export function validateIfTimeIsNotZero(time) {
  // Verifica se time é uma string válida e está no formato "HH:MM"
  if (typeof time !== 'string' || !/^\d{1,2}:\d{2}$/.test(time)) {
    return false; // time não está no formato adequado
  }

  // Separa o valor em horas e minutos
  const [hours, minutes] = time.split(':').map(Number);

  // Verifica se horas e minutos são números válidos e se o tempo é maior que 0
  if (hours === 0 && minutes === 0) {
    return false; // Não aceita "00:00" ou tempos iguais a zero
  }

  // Verifica se horas e minutos são válidos
  if (
    isNaN(hours) ||
    isNaN(minutes) ||
    hours < 0 ||
    minutes < 0 ||
    minutes >= 60
  ) {
    return false; // Horas ou minutos inválidos ou fora dos limites
  }

  // Se o tempo é válido e maior que zero, retorna true
  return hours * 60 + minutes > 0;
}
