import React, { useEffect , useState } from "react"
import { useHistory } from 'react-router-dom/cjs/react-router-dom.min';
import { BsPencilFill } from "react-icons/bs";
import ServiceAestheticScreen from "../../screens/ServiceAesthetic/ServiceAestheticScreen";
import { supabase } from "../../supabaseClient";
import { useSelector } from "react-redux";

const ServiceAestheticContainer = ({ }) => {
    const [data, setData] = useState([])
    const company = useSelector(state => state.app.company || {})
    const reloadAeServices = useSelector(state => state.app.reloadAeServices || false)

    useEffect(() => {
        loadData()
    }, [company, reloadAeServices])


    const loadData = async () => {
        let { data, error } = await supabase
            .from('AestheticServices')
            .select('*')
            .eq("id_company", company.id)
            .eq("module_type", 1)
            .is('deleted_at', null)
        if (!error) {
            setData(data)
        }
    }

    return (
      <ServiceAestheticScreen
        columns={columns}
        rows={data}
        />
    )
}

export default ServiceAestheticContainer

const columns = [
    {
        id: 1,
        field: 'title',
        label: 'Serviços',
        width: '1fr',
        paddingLeft: '30px !important',
        resizable: false,
    },
    {
        id: 2,
        field: 'actions',
        label: 'Ações',
        pinned: true,
        resizable: false,
        width: '70px',
        cellRenderer: ({ data }) => <ActionCell data={data} />,
    },
];

const ActionCell = ({ data }) => {
    let history = useHistory();
    return (
      <div className='field-actions'>
        <BsPencilFill className='icon-action edit' onClick={() => history.push(`/aesthetic/details/${data.id}`, data)} />
      </div>
    );
};
