import React, { useState, useEffect } from "react"
import { useHistory } from 'react-router-dom/cjs/react-router-dom.min';
import { useDispatch, useSelector } from 'react-redux';
import ServiceTypeScreen from '../../screens/ServiceType/ServiceTypeScreen';
import { BiBrush, BiReset } from "react-icons/bi";
import { FiScissors } from "react-icons/fi";
import { BsPencilFill } from "react-icons/bs";
import { supabase } from '../../supabaseClient';

const ServiceTypeContainer = ({ }) => {
    const [data, setData] = useState([])
    const company = useSelector(state => state.app.company || {})
    const reloadServiceType = useSelector(state => state.app.reloadServiceType || false)

    useEffect(() => {
        loadData()
    }, [company, reloadServiceType])

    const loadData = async () => {
        let { data, error } = await supabase
            .from('ServiceTypes')
            .select('*')
            .eq("id_company", company.id)
            .is('deleted_at', null)
        if (!error) {
            setData(data)
        }
    }

    return (
      <ServiceTypeScreen
        columns={columns}
        rows={data}
        />
    )

}

export default ServiceTypeContainer

const columns = [
    {
        id: 1,
        field: 'title',
        label: 'Tipos de serviço',
        width: '1fr',
        paddingLeft: '30px !important',
        resizable: false,
    },
    {
        id: 2,
        field: 'actions',
        label: 'Ações',
        pinned: true,
        resizable: false,
        width: '70px',
        cellRenderer: ({ data }) => <ActionCell data={data} />,
    },
];

const ActionCell = ({ data }) => {
    let history = useHistory();
    return (
      <div className='field-actions'>
        <BsPencilFill className='icon-action edit' onClick={() => history.push(`/bodyshop/details/${data.id}`, data)} />
        {/* <BsTrashFill className='icon-action trash' onClick={() => onRemove(data)} /> */}
      </div>
    );
};