import { supabase } from "../../supabaseClient"

export const saveAeService = async (id = undefined, item) => {

    const { relatedServices, discount_percentage_temp, discount_temp, finalValue, ...itemWithoutRelatedServices } = item;

    if (id) {
        return await supabase
            .from('AestheticServices')
            .update({ ...itemWithoutRelatedServices })
            .match({ id })
    } else {
        return await supabase
            .from('AestheticServices')
            .insert([{ ...itemWithoutRelatedServices }])
    }
}