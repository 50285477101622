import React from 'react';
import { BsHouseDoor } from 'react-icons/bs';
import DashboardContainer from '../../containers/Dashboard/DashboardContainer';
import { useHistory } from 'react-router-dom';
import { DefaultButton } from '../Button/Button';
import { setData } from '../../core/redux/app/Actions';
import { useDispatch } from 'react-redux';
import { AiOutlineClose } from 'react-icons/ai';

const HomeButton = ({ routeBackName }) => {
  const dispatch = useDispatch();
  const history = useHistory();

  return (
    <DefaultButton
      className="btn-cancel cancel new-table-button"
      label={'Fechar'}
      icon={<AiOutlineClose className="button-icon" />}
      onClick={(e) => {
        dispatch(setData({ navigate: routeBackName, courseName: null }));
        history.push(routeBackName);
      }}
    />
  );
};

export default HomeButton;
