import { useEffect, useState } from 'react'
import { useHistory } from 'react-router-dom';
import { alertError, alertSuccess } from '../../components/Toast';
import { getTranslation } from '../../core/Translator';
import NewPasswordScreen from '../../screens/NewPassword/NewPasswordScreen';
import { supabase } from '../../supabaseClient'

const ChangePasswordContainer = ({ reloadApp }) => {
    let history = useHistory();
    const { location: { hash, key } } = history
    const [loading, setLoading] = useState(false)
    const [session, setSession] = useState({})
    const [values, setValues] = useState({ email: '', newPassword: '', repeatPassword: '' });

    useEffect(() => {
        setSession(supabase.auth.session())

        supabase.auth.onAuthStateChange((_event, session) => {
            if (session && session.access_token) {
                setSession(session)
            }
        })
    }, [session])

    useEffect(() => {
        if (session && session.user && session.user.email) {
            loadUser(session.user.email)
        }
    }, [session && session.user && session.user.email])

    useEffect(() => {
        if (hash.includes("401")) {
            history.push("/login");
            setSession(null)
        }
    }, [hash])

    const loadUser = async (email) => {
        let { data: user, error } = await supabase
            .from('User')
            .select("*")
            .eq('email', email)
        if (!error) {
            setValues(user[0])
        } else {
            alertError('Ocorreu um erro')
            history.push("/login");
            setSession(null)
        }
    }

    const handleNewPassword = async () => {
        try {
            setLoading(true)
            if (values.newPassword !== "" && values.newPassword === values.repeatPassword) {
                const { error } = await supabase.auth.api.updateUser(session.access_token, { password: values.newPassword })
                if (error) throw error
                alertSuccess('Senha cadastrada com sucesso!')
                const { data, error: errorTable } = await supabase
                    .from('User')
                    .update({ isRegistered: true })
                    .eq('id', values.id)
                if (!errorTable) {
                    reloadApp()
                }
            } else {
                alertError('Senhas diferentes')
            }
        } catch (error) {
            alertError(getTranslation(error.error_description || error.message))
            console.log('erros', [error])
        } finally {
            setLoading(false)
        }
    }

    const onChange = (field, value) => {
        setValues(values => ({ ...values, [field]: value }))
    }

    return (
      <NewPasswordScreen
        handleNewPassword={handleNewPassword}
        onChange={onChange}
        values={values}
        loading={loading}
        history={history}
        isInside={false}
        />
    )
}

export default ChangePasswordContainer