import React, { useEffect, useState } from 'react';
import ModalAesthetic from '../../screens/ModalAesthetic/ModalAesthetic';
import {
  useHistory,
  useLocation,
  useParams,
} from 'react-router-dom/cjs/react-router-dom.min';
import { useDispatch, useSelector } from 'react-redux';
import { setData } from '../../core/redux/app/Actions';
import { compareValues } from '../ModalClients/ModalClientsContainer';
import Swal from 'sweetalert2';
import { supabase } from '../../supabaseClient';

const ModalAestheticContainer = () => {
  const aesthetic = useSelector((state) => state.app.aesthetic || 1);
  const dispatch = useDispatch();
  const history = useHistory();
  const location = useLocation();
  const params = useParams();
  const isNew = params.id === '0';
  const company = useSelector((state) => state.app.company || {});
  const [values, setValues] = useState({});
  const [oldValues, setOldValues] = useState({});
  const [responsible, setResponsible] = useState([]);

  useEffect(() => {
    if (location && location.state /* && !isNew */) {
      var image = undefined;
      if (location && location.state && location.state.image) {
        image = JSON.parse(location.state.image);
      }
      setOldValues({ ...location.state, image });
      setValues({ ...location.state, image });
    }
  }, [location]);

  useEffect(() => {
    const path = location.pathname.split('/')[1];
    dispatch(
      setData({ [`compareValues_${path}`]: compareValues(oldValues, values) })
    );
  }, [values, oldValues]);

  useEffect(() => {
    if (values.pieces && values.service_type && values.service_type.title) {
      setValues((prevValues) => ({
        ...prevValues,
        title: `${values.pieces} (${values.service_type.title})`,
      }));
    }
  }, [values.pieces, values.service_type && values.service_type.title]);

  const closeModal = () => {
    if (compareValues(oldValues, values)) {
      Swal.fire({
        title: 'Deseja sair sem salvar?',
        showCancelButton: true,
        showConfirmButton: false,
        showDenyButton: true,
        icon: 'warning',
        text: 'Ao sair, seus dados seram descartados.',
        denyButtonText: `Descartar`,
        cancelButtonText: 'Cancelar',
      }).then((result) => {
        if (result.isDenied) {
          history.push('/aesthetic');
        }
      });
    } else {
      history.push('/aesthetic');
    }
  };

  useEffect(() => {
    loadResponsible();
  }, [company.id]);

  const loadResponsible = async () => {
    let { data, error } = await supabase
      .from('Client')
      .select('*')
      .eq('id_company', company.id)
      .is('deleted_at', null);

    if (!error) {
      setResponsible(
        data.map((item) => ({ label: item.name, value: item.id }))
      );
    }
  };

  const onChange = (field, value) => {
    setValues((values) => ({ ...values, [field]: value }));
  };

  return (
    <ModalAesthetic
      compareValues={compareValues(oldValues, values)}
      responsible={responsible}
      closeModal={closeModal}
      onChange={onChange}
      values={values}
      isNew={isNew}
      aesthetic={aesthetic}
    />
  );
};

export default ModalAestheticContainer;
