import React, { useEffect } from "react";
import { useSelector } from "react-redux";
import { Route, Redirect, useHistory } from "react-router-dom";
import { ModalNewCheckList, RouteModal } from "../../components/Modal/Modal";
import HeaderContainer from "../../containers/Header/HeaderContainer";
import RightNav from "../../containers/RightNav/RightNav";
import { SidebarContainer } from "../../containers/Sidebar/SidebarContainer";
import { externalRoute, firstRoute, getEmpty, menuRoutes } from "./routes";

export const AuthRoutes = (props) => {
  const history = useHistory();

  useEffect(() => {
    const unlisten = history.listen(() => {
      sessionStorage.setItem("lastPath", history.location.pathname);
    });
    return unlisten;
  }, [history]);

  const lastPath = sessionStorage.getItem("lastPath");
  const user = useSelector((state) => state.app.user || {});
  const company = useSelector((state) => state.app.company || {});

  return (
    <div className="container-app">
      <SidebarContainer {...props} />
      <div className="body-app">
        <HeaderContainer {...props} />
        <div className="admin-screen-content">
          <div className={"admin-screen-main"}>
            {menuRoutes(user, company).map(({ path, Component }) => (
              <Route key={path} path={path} component={Component} />
            ))}
          </div>
          {menuRoutes(user, company)
            .filter((item) => item.right)
            .map((route, index) => (
              <Route
                key={`route-right-${index}`}
                path={route.path}
                render={(props) => (
                  <RightNav path={route.path}>{route.right(props)}</RightNav>
                )}
              />
            ))}
          {menuRoutes(user, company)
            .filter((item) => item.path === "/modules")
            .map((route, index) => (
              <Route
                key={`route-details-${index}`}
                path={`${route.path}/details/:action/:id`}
                render={(props) => (
                  <RouteModal expanded={true} {...route}>
                    {route.details({ ...props, parentPath: route.path })}
                  </RouteModal>
                )}
              />
            ))}
          {menuRoutes(user, company)
            .filter((item) => item.details)
            .map((route, index) => (
              <Route
                key={`route-details-${index}`}
                path={`${route.path}/details/:id`}
                render={(props) => (
                  <RouteModal expanded={true} {...route}>
                    {route.details({ ...props, parentPath: route.path })}
                  </RouteModal>
                )}
              />
            ))}
          <Route
            path="*"
            render={() => <Redirect to={lastPath || "/home"} />}
          />
        </div>
      </div>
      {/* <Redirect to="/dashboard" /> */}
    </div>
  );
};

export const FirstRoute = (props) => {
  return (
    <>
      {firstRoute.map(({ path, Component }) => (
        <Route
          key={path}
          path={path}
          component={() => <Component {...props} />}
        />
      ))}
      <Redirect to="/change-password" />
    </>
  );
};

export const ExternalRoute = ( props) => {
  return (
    <>
      {externalRoute.map(({ path, Component }) => (
        <Route
          key={path}
          path={path}
          component={() => <Component {...props} />}
        />
      ))}
    </>
  );
}