import DatePicker from 'react-datepicker';
import { forwardRef, useState } from 'react';
import ptBR from 'date-fns/locale/pt-BR';
import { SelectInput } from '../Input/Input';
import { isMobile } from 'react-device-detect';
import { MdFilterAltOff, MdFilterListAlt } from 'react-icons/md';
import {
  statusBadges,
  statusOptions,
} from '../../screens/ModalBudget/utils/constants';

const CustomHeader = ({ filter, setFilter }) => {
  const [startDate, endDate] = filter.dates || [null, null];
  const [toggle, setToggle] = useState(false);

  const ExampleCustomInput = forwardRef(function Example() {
    ({ value, onClick }, ref) => (
      <button
        style={{
          maxHeight: '32px',
          minHeight: '32px',
          borderRadius: '8px',
          minWidth: '100%',
          fontSize: '13px',
          backgroundColor: 'rgb(217, 219, 233)',
          border: 'none',
          display: 'flex',
          alignItems: 'center',
          fontFamily: 'poppins',
          fontWeight: '400',
        }}
        className="example-custom-input"
        onClick={onClick}
        ref={ref}
        placeholder="Insira uma Data"
      >
        {value ? (
          ''
        ) : (
          <text
            style={{
              fontWeight: '400',
              fontFamily: 'poppins',
              fontSize: '15px',
            }}
          >
            Filtrar período
          </text>
        )}
        {value}
      </button>
    );
  });
  return (
    <div className="header-mobile">
      <div style={{ display: 'flex', flexDirection: 'row' }}>
        <div style={{ display: 'flex', width: '100%', paddingRight: '10px' }}>
          {isMobile ? (
            <span className="rgt-search-icon custom-header-icon">⚲</span>
          ) : (
            <label className="rgt-search-label" htmlFor="rgt-search">
              <span className="rgt-search-icon custom-header-icon">⚲</span>
              Buscar:
            </label>
          )}
          <input
            className="rgt-search-input custom-header-input"
            type="search"
            placeholder="Digite para Pesquisar"
            value={filter.search}
            onChange={(e) =>
              setFilter((filter) => ({ ...filter, search: e.target.value }))
            }
            style={{ backgroundColor: '#D9DBE9', color: 'black' }}
          />
        </div>
        {isMobile ? (
          toggle ? (
            <MdFilterAltOff
              onClick={() => {
                setToggle((toggle) => !toggle);
              }}
              style={{ paddingLeft: 10 }}
              color="white"
              fontSize={30}
            />
          ) : (
            <MdFilterListAlt
              onClick={() => {
                setToggle((toggle) => !toggle);
              }}
              style={{ paddingLeft: 10 }}
              color="white"
              fontSize={30}
            />
          )
        ) : (
          <div style={{ display: 'flex', width: '70%', flexDirection: 'row' }}>
            <DatePicker
              dateFormat="dd/MM/yyyy"
              locale={ptBR}
              selectsRange={true}
              shouldCloseOnSelect={true}
              startDate={startDate}
              endDate={endDate}
              onChange={(dates) => {
                setFilter((filter) => ({ ...filter, dates }));
              }}
              isClearable={true}
              placeholderText="Filtrar período"
            />
            <div className="custom-header-select">
              <SelectInput
                isClearable={true}
                placeholder={'Filtre o status'}
                onChange={(value) =>
                  setFilter((filter) => ({ ...filter, status: value }))
                }
                options={statusOptions}
              />
            </div>
            <div className="custom-header-select">
              <SelectInput
                isClearable={true}
                placeholder={'Status cliente'}
                onChange={(value) =>
                  setFilter((filter) => ({ ...filter, status_client: value }))
                }
                options={statusBadges}
              />
            </div>
          </div>
        )}
      </div>
      {toggle ? (
        <div className="mobile-date-picker">
          <DatePicker
            dateFormat="dd/MM/yyyy"
            locale={ptBR}
            selectsRange={true}
            shouldCloseOnSelect={true}
            startDate={startDate}
            endDate={endDate}
            onChange={(dates) => {
              setFilter((filter) => ({ ...filter, dates }));
            }}
            isClearable={true}
            customInput={<ExampleCustomInput />}
            placeholderText="Filtrar período"
          />
          <div className="custom-header-select">
            <SelectInput
              isClearable={true}
              placeholder={'Status'}
              onChange={(value) =>
                setFilter((filter) => ({ ...filter, status: value }))
              }
              defaultValue={filter.status}
              isSearchable={!isMobile}
              options={statusOptions}
            />
          </div>
          <div className="custom-header-select">
            <SelectInput
              isClearable={true}
              placeholder={'Status Cliente'}
              onChange={(value) =>
                setFilter((filter) => ({ ...filter, status_client: value }))
              }
              defaultValue={filter.status_client}
              isSearchable={!isMobile}
              options={statusBadges}
            />
          </div>
        </div>
      ) : (
        <></>
      )}
    </div>
  );
};

export default CustomHeader;
