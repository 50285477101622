export const totalHour = (services) => {
  let totalMinutes = 0;
  services?.forEach((r) => {
    const time = r?.p_time;
    if (time) {
      const [hours, minutes] = time.split(':').map(Number);
      totalMinutes += hours * 60 + minutes;
    }
  });
  const totalHours = Math.floor(totalMinutes / 60);
  const remainingMinutes = totalMinutes % 60;
  return `${String(totalHours).padStart(2, '0')}:${String(
    remainingMinutes
  ).padStart(2, '0')}`;
};

export const totalQnt = (services) => {
  let totalMinutes = 0;
  services?.forEach((r) => {
    const time = r?.quantity;
    if (time) {
      totalMinutes += parseFloat(time);
    }
  });
  return `${String(totalMinutes)}`;
};

export const totalValue = (services) => {
  let totalValue = 0;
  services?.forEach((r) => {
    totalValue += Number(fixMoney(String(r.quantity), r.p_value));
  });
  return `${totalValue.toFixed(2)}`;
};

export const totalValueQnt = (services) => {
  let totalValue = 0;
  let totalTime = 1;
  services?.forEach((r) => {
    // Captura e soma p_time, se for válido
    const time = r?.quantity;
    if (time) {
      totalTime += parseFloat(time);
    }
    // Captura e converte p_value para número, tratando o formato monetário com vírgula
    const value =
      typeof r.p_value === 'string'
        ? parseFloat(r.p_value.replace(',', '.'))
        : Number(r.p_value);
    // Garante que time e value são números válidos antes de somar
    if (!isNaN(time) && !isNaN(value)) {
      totalValue += Number(fixMoneyQnt(time, value));
    }
  });

  return `${totalValue.toFixed(2)}`;
};

export const valuePerHour = (services) => {
  const [totalHours, totalMinutes] = totalHour(services).split(':').map(Number);
  const totalMinutesOverall = totalHours * 60 + totalMinutes;
  const totalValueNumber = Number(totalValue(services));
  const valuePerMinute = totalValueNumber / totalMinutesOverall;
  const valuePerHour = valuePerMinute * 60;
  return `${valuePerHour.toFixed(2)}`;
};

export const valuePerQnt = (services) => {
  const total = totalQnt(services);
  const totalValueNumber = Number(totalValue(services));
  const ratio = totalValueNumber / total;
  return `${ratio.toFixed(2)}`;
};

export const fixMoney = (value1, value2) => {
  value2 = value2.toString();

  value2 = value2.replace(/[^\d,.]/g, '');
  value2 = value2.replace(/(?<=\.\d*?)0+$/, '');

  if (value1 && value2) {
    const number1 = formatHourToNumber(value1);
    const number2 = parseFloat(value2.replace(',', '.'));

    const result = number1 * number2;

    return result.toFixed(2);
  }
};

export const fixMoneyQnt = (value1, value2) => {
  value2 = value2.toString();

  value2 = value2.replace(/[^\d,.]/g, '');
  value2 = value2.replace(/(?<=\.\d*?)0+$/, '');

  if (value1 && value2) {
    const number1 = parseFloat(value1);
    const number2 = parseFloat(value2.replace(',', '.'));

    const result = number1 * number2;

    return result.toFixed(2);
  }
};

export const formatHourToNumber = (hora) => {
  const partes = hora.split(':');
  const horas = parseInt(partes[0], 10);
  const minutos = partes[1] ? parseInt(partes[1], 10) : 0;
  return horas + minutos / 60;
};
