import react, { useEffect, useState } from 'react';
import { useDispatch, useSelector } from 'react-redux';
import { useHistory } from 'react-router-dom/cjs/react-router-dom.min';
import { setData } from '../../core/redux/app/Actions';
import { getAllCourses } from '../../core/utils/Course';

const ManualScreen = () => {
  const dispatch = useDispatch();
  const history = useHistory();
  const company = useSelector((state) => state.app.company || {});
  const [courses, setCourses] = useState([])

  const loadData = async () => {
    let { data, error } = await getAllCourses()

    if (!error) {
      setCourses(data)
    }
  }

  useEffect(() => {
    loadData()
  }, [])


  return (
    <div className="dashboard-placement" style={{ overflowY: 'auto' }}>
      <div className="dashboard-home">
        <div>
          <img
            className="dashboard-logo"
            src={require('../../assets/images/web.png').default}
          />
        </div>
        <div className="dashboard-grid-placement">
          {courses.map(i => (
            <div
              key={i?.id}
              className="image-text-button"
              onClick={() => {
                history.push(`/manual-video/${i?.id}`);
                dispatch(setData({ navigate: `/manual-video/${i?.id}`, courseName: i?.name }));
              }}
            >
              <img
                className="image-button-logo"
                src={`${process.env.REACT_APP_SUPABASE_URL}/storage/v1/object/public/${i?.thumbnail}`}
              />
              <text style={{ marginTop: -8 }}>{i?.name}</text>
            </div>
          ))}
        </div>
      </div>
    </div>
  );
};

export default ManualScreen;
