import {
  FormGroup,
  Input,
  InputGroup,
  InputGroupText,
  Label,
  Spinner,
  Row,
} from 'reactstrap';
import Select, { components } from 'react-select';
import Creatable from 'react-select/creatable';
import DatePicker, { registerLocale } from 'react-datepicker';
import 'react-datepicker/dist/react-datepicker.css';
import CurrencyInput, { formatValue } from 'react-currency-input-field';
import pt from 'date-fns/locale/pt';
import { BsCaretDownFill } from 'react-icons/bs';
import NumberFormat from 'react-number-format';
import { DateRangePicker } from 'react-date-range';
import { moneyExtractor } from '../../core/utils/string';
import React, { useEffect, useState, useRef } from 'react';
import moment from 'moment';
import { AiOutlineCheckCircle } from 'react-icons/ai';
import { MultipleDropzone, SingleDropzone } from '../Dropzone/Dropzone';
import Switch from 'react-switch';
import GridTable from '@nadavshaar/react-grid-table';
import CpfCnpj from '@react-br-forms/cpf-cnpj-mask';
import { MdAdd } from 'react-icons/md';
import { useHistory } from 'react-router-dom';
import InputMask from 'react-input-mask';

registerLocale('pt', pt);

export const DefaultInput = ({
  onEnter = () => { },
  min,
  max,
  onInput,
  disabled = false,
  value,
  placeholder,
  defaultValue,
  type,
  onChange = () => { },
  onBlur = () => { },
  inputClassname = '',
  inputGroupClassname = '',
  InputGroupLeft = () => <></>,
  InputGroupRight = () => <></>,
  typeChange = 1,
}) => {
  const [controlledValue, setControlledValue] = useState(defaultValue || value);

  useEffect(() => {
    if (controlledValue) {
      onChange(controlledValue);
    }
  }, [controlledValue]);

  useEffect(() => {
    if (controlledValue !== value) {
      setControlledValue(value);
    }
  }, [value]);

  const onChangeData = (text) => {
    setControlledValue(text);
  };
  return (
    <InputGroup>
      {InputGroupLeft()}
      <Input
        max={max}
        min={min}
        className={`default-input ${inputClassname}`}
        type={type}
        placeholder={placeholder}
        value={controlledValue}
        defaultValue={defaultValue}
        onChange={(e) =>
          typeChange === 1
            ? onChangeData(e.target.value)
            : onChange(e.target.value)
        }
        onInput={onInput}
        onBlur={onBlur}
        disabled={disabled}
        onKeyUp={(e) =>
          e.keyCode && e.keyCode === 13 ? onEnter(e.target.value) : {}
        }
      />
      {InputGroupRight()}
    </InputGroup>
  );
};

export const IconInput = ({
  disabled = false,
  icon,
  value,
  placeholder,
  defaultValue,
  type,
  onChange,
  onBlur = () => { },
  inputClassname = '',
  inputGroupClassname = '',
  /* InputGroupLeft = () => <>   <div><BsSearch className="search-icon" /></div> </> */ InputGroupRight = () => (
    <></>
  ),
  InputGroupLeft = () => <></>,
}) => {
  return (
    <InputGroup>
      {InputGroupLeft()}

      <Input
        id="search"
        className={`search-input ${inputClassname}`}
        type={type}
        placeholder={placeholder}
        value={value}
        defaultValue={defaultValue}
        onChange={(e) => {
          onChange(e.target.value);
        }}
        onBlur={(e) => onBlur(e.target.value)}
        disabled={disabled}
      ></Input>
      {InputGroupRight()}
      <InputGroupText>{icon}</InputGroupText>
    </InputGroup>
  );
};

let timeout;

const InputContainer = (props) => {
  const { children, icon, title, action, loading, success, area, error } =
    props;

  return (
    <div>
      <Row className="header-row">
        {title && <p className="form-title">{title}</p>}
      </Row>
      <InputGroup
        className={`generic-input-group ${area && 'area'} ${success ? 'success' : ''
          } ${error ? 'error' : ''}`}
      >
        {children}
        {action}
        {loading && <Spinner className="input-spinner" />}
        {success && <AiOutlineCheckCircle className="success-label" />}
      </InputGroup>
      {error && <p className="error-message">{error}</p>}
    </div>
  );
};

export const TextInput = (props) => {
  const { area } = props;

  return (
    <InputContainer {...props}>
      <Input type={area ? 'textarea' : 'text'} {...props} />
    </InputContainer>
  );
};

export const LabelInput = ({
  min,
  max,
  disabled,
  label,
  classLabel = 'label',
  className = 'generic-input',
  value,
  defaultValue,
  placeholder,
  type,
  onChange,
  inputClassname,
  inputGroupClassname,
  InputGroupLeft,
  InputGroupRight,
}) => {
  return (
    <FormGroup className={className}>
      {label && <Label className={classLabel}>{label}</Label>}
      <DefaultInput
        max={max}
        min={min}
        value={value}
        defaultValue={defaultValue}
        placeholder={placeholder}
        type={type}
        onChange={onChange}
        onInput={(e) => onChange(e.target.value)}
        inputClassname={inputClassname}
        InputGroupRight={InputGroupRight}
        InputGroupLeft={InputGroupLeft}
        inputGroupClassname={inputGroupClassname}
        disabled={disabled}
      />
    </FormGroup>
  );
};

export const MoneyLabel = ({
  value,
  className,
  renderText = (label) => label,
}) => {
  return (
    <Label className={`money-label ${className}`}>
      {formatValue({
        value,
        groupSeparator: '.',
        decimalSeparator: ',',
        prefix: 'R$',
        decimalScale: 2,
      })}
    </Label>
  );
};

export const PercentLabel = ({ value, className }) => {
  return (
    <Label className={`money-label ${className}`}>
      {formatValue({
        value,
        suffix: '%',
        decimalsLimit: 5,
      })}
    </Label>
  );
};

export const DayLabel = ({ value, className }) => {
  return (
    <Label className={`money-label ${className}`}>
      {formatValue({
        value,
        suffix: ' dias',
      })}
    </Label>
  );
};

export const ValueInput = ({
  label,
  classLabel = 'label',
  className = 'generic-input',
  value,
  placeholder,
  type,
  onChange,
  inputClassname,
  inputGroupClassname,
  InputGroupLeft,
  InputGroupRight,
}) => {
  return (
    <FormGroup className={className}>
      {label && <Label className={classLabel}>{label}</Label>}
      <CurrencyInput
        placeholder={placeholder}
        defaultValue={value}
        value={value}
        prefix={`R$`}
        groupSeparator={'.'}
        decimalSeparator={','}
        className={`default-input form-control ${inputClassname}`}
      />
    </FormGroup>
  );
};

export const MoneyInput = ({
  label,
  classLabel = 'label',
  className = 'generic-input',
  value,
  placeholder,
  disabled,
  onChange,
  inputClassname,
  onChangeWithoutBlur,
}) => {
  return (
    <FormGroup className={className}>
      {label && <Label className={classLabel}>{label}</Label>}
      <CurrencyInput
        placeholder={placeholder}
        allowDecimals={true}
        value={moneyExtractor(value)}
        decimalScale={2}
        onChange={
          onChangeWithoutBlur
            ? (e) => onChangeWithoutBlur(e.target.value)
            : undefined
        }
        onValueChange={
          !onChangeWithoutBlur ? (value) => onChange(value) : undefined
        }
        prefix={'R$'}
        groupSeparator={'.'}
        disabled={disabled}
        decimalSeparator={','}
        className={`default-input form-control ${inputClassname}`}
      />
    </FormGroup>
  );
};

export const PercentageInput = ({
  label,
  classLabel = 'label',
  className = 'generic-input',
  value,
  placeholder,
  onChange,
  disabled,
  inputClassname,
  style = {},
}) => {
  return (
    <FormGroup className={className} style={style}>
      {label && <Label className={classLabel}>{label}</Label>}
      <CurrencyInput
        placeholder={placeholder}
        value={value}
        disabled={disabled}
        decimalsLimit={5}
        onValueChange={(value) => onChange(value)}
        suffix="%"
        disableGroupSeparators={true}
        className={`default-input form-control ${inputClassname}`}
      />
    </FormGroup>
  );
};

export const NumberInput = ({
  label,
  prefix = '',
  classLabel = 'label',
  className = 'generic-input',
  value,
  decimalSeparator = '.',
  placeholder,
  type,
  onBlur,
  onChange,
  inputClassname,
  inputGroupClassname,
  InputGroupLeft,
  InputGroupRight,
  disabled,
}) => {
  return (
    <FormGroup className={className}>
      {label && <Label className={classLabel}>{label}</Label>}
      <NumberFormat
        value={value}
        displayType={'text'}
        decimalSeparator={decimalSeparator}
        renderText={(value) => (
          <DefaultInput
            value={value}
            placeholder={placeholder}
            type={type}
            onChange={onChange}
            onBlur={onBlur}
            inputClassname={inputClassname}
            InputGroupRight={InputGroupRight}
            InputGroupLeft={InputGroupLeft}
            inputGroupClassname={inputGroupClassname}
            disabled={disabled}
          />
        )}
      />
      {/*  */}
    </FormGroup>
  );
};

export const DayInput = ({
  label,
  classLabel = 'label',
  className = 'generic-input',
  value,
  placeholder,
  onChange,
  disabled,
  inputClassname,
}) => {
  return (
    <FormGroup className={className}>
      {label && <Label className={classLabel}>{label}</Label>}
      <CurrencyInput
        placeholder={placeholder}
        value={value}
        disabled={disabled}
        onValueChange={(value) => onChange(value)}
        suffix=" dias"
        disableGroupSeparators={true}
        className={`default-input form-control ${inputClassname}`}
      />
    </FormGroup>
  );
};

export const DateInput = ({
  showMonthYearPicker = false,
  onBlur,
  disabled,
  label,
  classLabel = 'label',
  className = 'generic-input',
  value,
  placeholder,
  onChange,
  dateFormat = 'dd/MM/yyyy',
}) => {
  return (
    <FormGroup className={className}>
      {label && <Label className={classLabel}>{label}</Label>}
      {/* <DefaultInput
                value={value}
                placeholder={placeholder}
                type={type}
                onChange={onChange}
                inputClassname={inputClassname}
                InputGroupRight={InputGroupRight}
                InputGroupLeft={InputGroupLeft}
                inputGroupClassname={inputGroupClassname}
            /> */}
      <DatePicker
        selected={value && moment(value).isValid() ? value : undefined}
        onBlur={onBlur}
        inline
        onClickOutside={onBlur}
        onChange={onChange}
        placeholderText={placeholder}
        dateFormat={dateFormat}
        locale="pt"
        disabled={disabled}
        showMonthYearPicker={showMonthYearPicker}
      />
    </FormGroup>
  );
};

export const DateInputRange = ({ }) => {
  const handleSelect = (ranges) => { };
  const selectionRange = {
    startDate: new Date(),
    endDate: new Date(),
    key: 'selection',
  };
  return (
    <div>
      <DateRangePicker ranges={[selectionRange]} onChange={handleSelect} />
    </div>
  );
};

const defaultComponent = () => <></>;
const DropdownIndicator = (props) => {
  return (
    <components.DropdownIndicator {...props}>
      <BsCaretDownFill />
    </components.DropdownIndicator>
  );
};
const Option = (props) => <components.Option {...props} />;

export const LabelSelect = ({
  customOption = undefined,
  disabled,
  isClearable,
  formatOptionLabel = (item) => item.label,
  styles = {},
  menuIsOpen = false,
  label,
  classLabel = 'label',
  className = 'generic-input select-input',
  options = [],
  value,
  placeholder,
  onChange,
  isMulti = false,
}) => {
  return (
    <FormGroup className={className}>
      {label && <Label className={classLabel}>{label}</Label>}
      <Select
        // className="basic-single"
        // classNamePrefix="select"
        value={value}
        // isDisabled={isDisabled}
        // isLoading={isLoading}
        isClearable={isClearable}
        // isRtl={isRtl}
        // isSearchable={isSearchable}
        // name="color"
        // menuIsOpen={menuIsOpen}
        isDisabled={disabled}
        options={options}
        placeholder={placeholder}
        onChange={onChange}
        isMulti={isMulti}
        styles={styles}
        formatOptionLabel={formatOptionLabel}
        noOptionsMessage={() => <label>Nenhum item encontrado</label>}
        components={{
          IndicatorSeparator: defaultComponent,
          DropdownIndicator,
          Option: customOption ? customOption : Option,
        }}
        theme={(theme) => ({
          ...theme,
          colors: {
            ...theme.colors,
            primary50: 'rgba(110, 113, 145, 0.05)',
            primary25: 'rgba(110, 113, 145, 0.05)',
            primary: 'rgba(110, 113, 145, 0.1)',
          },
        })}
      />
    </FormGroup>
  );
};

export const LabelCreatable = ({
  label,
  disabled,
  formatCreateLabel,
  classLabel = 'label',
  className = 'generic-input select-input',
  options,
  onCreateOption,
  value,
  defaultValue,
  placeholder,
  onChange,
  isMulti = false,
  customOption,
  menuIsOpen,
  components = {},
}) => {
  return (
    <FormGroup className={className}>
      {label && <Label className={classLabel}>{label}</Label>}
      <Creatable
        // className="basic-single"
        // classNamePrefix="select"
        value={defaultValue}
        // isDisabled={isDisabled}
        // isLoading={isLoading}
        // isClearable={isClearable}
        // isRtl={isRtl}
        // isSearchable={isSearchable}
        // name="color"
        menuIsOpen={menuIsOpen}
        isDisabled={disabled}
        options={options}
        onCreateOption={onCreateOption}
        placeholder={placeholder}
        onChange={onChange}
        formatCreateLabel={(inputText) => `Criar "${inputText}"`}
        isMulti={isMulti}
        noOptionsMessage={() => <label>Nenhum item encontrado</label>}
        components={{
          IndicatorSeparator: defaultComponent,
          DropdownIndicator,
          ...components,
        }}
        theme={(theme) => ({
          ...theme,
          colors: {
            ...theme.colors,
            primary50: 'rgba(110, 113, 145, 0.05)',
            primary25: 'rgba(110, 113, 145, 0.05)',
            primary: 'rgba(110, 113, 145, 0.1)',
          },
        })}
      />
    </FormGroup>
  );
};

export const LabelRadios = ({
  classLabel = 'label',
  className = 'generic-input select-input',
  label,
  options,
  onClick,
  value = {},
  classNameRadio = 'radio-input',
}) => {
  return (
    <FormGroup className={className}>
      {label && <Label className={classLabel}>{label}</Label>}
      {options.map((option) => {
        return (
          <LabelRadio
            onClick={onClick}
            option={option}
            checked={value}
            classNameRadio={classNameRadio}
          />
        );
      })}
    </FormGroup>
  );
};

export const LabelRadio = ({ onClick, option, checked, classNameRadio }) => {
  const isChecked = checked.value === option.value;
  return (
    <FormGroup
      check
      className={`${classNameRadio} ${isChecked && 'isChecked'}`}
    >
      <Input checked={isChecked} type="radio" onClick={() => onClick(option)} />
      <Label check>{option.label}</Label>
    </FormGroup>
  );
};

export const DropzoneInput = ({
  single = false,
  table = 'company',
  value,
  disabled,
  onChange,
  label,
  classLabel = 'label',
  className = 'generic-input',
}) => {
  return (
    <FormGroup className={className}>
      {label && <Label className={classLabel}>{label}</Label>}
      {single ? (
        <SingleDropzone onChange={onChange} value={value} table={table} />
      ) : (
        <MultipleDropzone onChange={onChange} value={value} table={table} />
      )}
    </FormGroup>
  );
};

export const SwitchInput = ({
  disabled,
  value,
  onChange,
  label,
  classLabel = 'label',
  className = 'generic-input switch',
}) => {
  return (
    <FormGroup className={className}>
      {label && <Label className={classLabel}>{label}</Label>}
      <Switch
        onChange={onChange}
        checked={value}
        onColor={'#667A55'}
        offColor={'#67676A'}
        onHandleColor={'#63C601'}
        offHandleColor={'#667A55'}
        uncheckedIcon={null}
        checkedIcon={null}
        width={44}
        height={20}
        borderRadius={8}
      />
    </FormGroup>
  );
};

export const TableInput = ({
  columns = [],
  rows = [],
  disabled,
  placeholder,
  onChange,
  onClickButton,
  label,
  classLabel = 'label',
  className = 'generic-input default-table',
  componentHeader = <></>,
}) => {
  const [controlledValue, setControlledValue] = useState('');

  const history = useHistory();

  const onChangeData = (text) => {
    setControlledValue(text);
  };

  const onEnter = () => {
    onChange(controlledValue);
    setControlledValue('');
  };
  return (
    <FormGroup className={className}>
      <div>
        {label && <Label className={classLabel}>{label}</Label>}
        {componentHeader}
      </div>
      <GridTable
        columns={columns}
        rows={rows}
        // placeholder = {placeholder}
        texts={{
          noResults: '',
          search: 'Buscar:',
          totalRows: 'Número de itens:',
          rows: 'Itens:',
          selected: 'Selecionado',
          rowsPerPage: 'Itens por página:',
          page: 'Página:',
          of: 'de',
          prev: 'Anterior',
          next: 'Próximo',
          columnVisibility: 'Colunas visíveis',
        }}
        canReorder={false}
        showSearch={false}
        showRowsInformation={false}
        showColumnVisibilityManager={false}
        isPaginated={false}
        enableColumnsReorder={false}
      />
      {onChange ? (
        <DefaultInput
          placeholder={placeholder}
          value={controlledValue}
          onChange={onChangeData}
          onEnter={onEnter}
          InputGroupRight={() => (
            <div className="button-add-table">
              <MdAdd onClick={onEnter} />
            </div>
          )}
        />
      ) : (
        <></>
      )}
    </FormGroup>
  );
};

export const CNPJInput = ({
  label,
  classLabel = 'label',
  className = 'generic-input',
  value,
  defaultValue,
  placeholder,
  onChange,
}) => {
  const [controlledValue, setControlledValue] = useState(defaultValue || value);

  useEffect(() => {
    const timer = setTimeout(() => {
      if (controlledValue) {
        onChange(controlledValue);
      }
    }, 1000);

    return () => clearTimeout(timer);
  }, [controlledValue]);

  useEffect(() => {
    if (controlledValue !== value) {
      setControlledValue(value);
    }
  }, [value]);

  const onChangeData = (text) => {
    setControlledValue(text);
  };

  return (
    <FormGroup className={className}>
      {label && <Label className={classLabel}>{label}</Label>}
      <InputGroup>
        <CpfCnpj
          value={controlledValue}
          className={`default-input form-control`}
          placeholder={placeholder}
          onChange={(e) => onChangeData(e.target.value)}
        />
      </InputGroup>
    </FormGroup>
  );
};

function PhoneMask(props) {
  return (
    <InputMask
      alwaysShowMask={true}
      mask={props.mask}
      value={props.value}
      defaultValue={props.defaultValue}
      placeholder={props.placeholder}
      onChange={(e) => props.onChange(e.target.value)}
      // maskChar={null}
    ></InputMask>
  );
}

export const CellphoneInput = ({
  mask = '(99) 99999-9999',
  value,
  onChange,
  label,
  classLabel = 'label',
  className = 'generic-input',
  placeholder,
}) => {
  const [controlledValue, setControlledValue] = useState(value);

  useEffect(() => {
    const timer = setTimeout(() => {
      if (controlledValue) {
        onChange(controlledValue);
      }
    }, 1000);

    return () => clearTimeout(timer);
  }, [controlledValue]);

  useEffect(() => {
    if (controlledValue !== value) {
      setControlledValue(value);
    }
  }, [value]);

  const onChangeData = (text) => {
    setControlledValue(text);
  };
  return (
    <FormGroup className={className}>
      {label && <Label className={classLabel}>{label}</Label>}
      <InputGroup>
        <div className={`default-input form-control`}>
          <PhoneMask
            className={`masked-input`}
            value={controlledValue}
            placeholder={placeholder}
            onChange={onChangeData}
            mask={mask}
          />
        </div>
      </InputGroup>
    </FormGroup>
  );
};

function RGMask(props) {
  return (
    <InputMask
      alwaysShowMask={true}
      mask="99.999.999"
      value={props.value}
      placeholder={props.placeholder}
      onChange={(e) => props.onChange(e.target.value)}
    ></InputMask>
  );
}

export const RGInput = ({
  defaultValue,
  value,
  onChange,
  label,
  classLabel = 'label',
  className = 'generic-input',
  placeholder,
}) => {
  const [controlledValue, setControlledValue] = useState(value);

  useEffect(() => {
    const timer = setTimeout(() => {
      if (controlledValue) {
        onChange(controlledValue);
      }
    }, 1000);

    return () => clearTimeout(timer);
  }, [controlledValue]);

  useEffect(() => {
    if (controlledValue !== value) {
      setControlledValue(value);
    }
  }, [value]);

  const onChangeData = (text) => {
    setControlledValue(text);
  };
  return (
    <FormGroup className={className}>
      {label && <Label className={classLabel}>{label}</Label>}
      <InputGroup>
        <div className={`default-input form-control`}>
          <RGMask
            className={`masked-input`}
            value={controlledValue}
            placeholder={placeholder}
            onChange={onChangeData}
          ></RGMask>
          {/* <div style={{paddingTop: '12px'}}></div> */}
        </div>
      </InputGroup>
    </FormGroup>
  );
};

function CEPMask(props) {
  return (
    <InputMask
      alwaysShowMask={true}
      mask="99999-999"
      value={props.value}
      placeholder={props.placeholder}
      defaultValue={props.defaultValue}
      onChange={(e) => props.onChange(e.target.value)}
    />
  );
}

export const CEPInput = ({
  onChange,
  value,
  label,
  classLabel = 'label',
  className = 'generic-input',
  placeholder,
}) => {
  const [controlledValue, setControlledValue] = useState(value);

  useEffect(() => {
    const timer = setTimeout(() => {
      if (controlledValue) {
        onChange(controlledValue);
      }
    }, 1000);

    return () => clearTimeout(timer);
  }, [controlledValue]);

  useEffect(() => {
    if (controlledValue !== value) {
      setControlledValue(value);
    }
  }, [value]);

  const onChangeData = (text) => {
    setControlledValue(text);
  };
  return (
    <FormGroup className={className}>
      {label && <Label className={classLabel}>{label}</Label>}
      <InputGroup>
        <div className={`default-input form-control`}>
          <CEPMask
            className={`masked-input`}
            value={controlledValue}
            placeholder={placeholder}
            onChange={onChangeData}
          />
        </div>
      </InputGroup>
    </FormGroup>
  );
};

function DateMask(props) {
  return (
    <InputMask
      alwaysShowMask={true}
      mask="99/99/9999"
      value={props.value}
      placeholder={props.placeholder}
      defaultValue={props.defaultValue}
      onChange={(e) => props.onChange(e.target.value)}
    ></InputMask>
  );
}

export const DateMaskInput = ({
  defaultValue,
  value,
  onChange,
  label,
  classLabel = 'label',
  className = 'generic-input',
  placeholder,
}) => {
  const [controlledValue, setControlledValue] = useState(value);

  useEffect(() => {
    const timer = setTimeout(() => {
      if (controlledValue) {
        onChange(controlledValue);
      }
    }, 1000);

    return () => clearTimeout(timer);
  }, [controlledValue]);

  useEffect(() => {
    if (controlledValue !== value) {
      setControlledValue(value);
    }
  }, [value]);

  const onChangeData = (text) => {
    setControlledValue(text);
  };
  return (
    <FormGroup className={className}>
      {label && <Label className={classLabel}>{label}</Label>}
      <InputGroup>
        <div className={`default-input form-control`}>
          <DateMask
            className={`masked-input`}
            value={controlledValue}
            placeholder={placeholder}
            onChange={onChangeData}
          ></DateMask>
          {/* <div style={{paddingTop: '12px'}}></div> */}
        </div>
      </InputGroup>
    </FormGroup>
  );
};

function FractionMask(props) {
  return (
    <InputMask
      alwaysShowMask={true}
      mask="9,99"
      value={props.value}
      placeholder={props.placeholder}
      defaultValue={props.defaultValue}
      onChange={(e) => props.onChange(e.target.value)}
    ></InputMask>
  );
}

export const FractionInput = ({
  defaultValue,
  value,
  onChange,
  label,
  classLabel = 'label',
  className = 'generic-input',
  placeholder,
}) => {
  const [controlledValue, setControlledValue] = useState(value);

  useEffect(() => {
    const timer = setTimeout(() => {
      if (controlledValue) {
        onChange(controlledValue);
      }
    }, 1000);

    return () => clearTimeout(timer);
  }, [controlledValue]);

  useEffect(() => {
    if (controlledValue !== value) {
      setControlledValue(value);
    }
  }, [value]);

  const onChangeData = (text) => {
    setControlledValue(text);
  };
  return (
    <FormGroup className={className}>
      {label && <Label className={classLabel}>{label}</Label>}
      <InputGroup>
        <div className={`default-input form-control`}>
          <FractionMask
            className={`masked-input`}
            value={controlledValue}
            placeholder={placeholder}
            onChange={onChangeData}
          ></FractionMask>
        </div>
      </InputGroup>
    </FormGroup>
  );
};

function CnaeMask(props) {
  return (
    <InputMask
      alwaysShowMask={true}
      mask="9999-9/99"
      value={props.value}
      placeholder={props.placeholder}
      defaultValue={props.defaultValue}
      onChange={(e) => props.onChange(e.target.value)}
    ></InputMask>
  );
}

export const CnaeInput = ({
  defaultValue,
  value,
  onChange,
  label,
  classLabel = 'label',
  className = 'generic-input',
  placeholder,
}) => {
  const [controlledValue, setControlledValue] = useState(value);

  useEffect(() => {
    const timer = setTimeout(() => {
      if (controlledValue) {
        onChange(controlledValue);
      }
    }, 1000);

    return () => clearTimeout(timer);
  }, [controlledValue]);

  useEffect(() => {
    if (controlledValue !== value) {
      setControlledValue(value);
    }
  }, [value]);

  const onChangeData = (text) => {
    setControlledValue(text);
  };
  return (
    <FormGroup className={className}>
      {label && <Label className={classLabel}>{label}</Label>}
      <InputGroup>
        <div className={`default-input form-control`}>
          <CnaeMask
            className={`masked-input`}
            value={controlledValue}
            placeholder={placeholder}
            onChange={onChangeData}
          ></CnaeMask>
          {/* <div style={{paddingTop: '12px'}}></div> */}
        </div>
      </InputGroup>
    </FormGroup>
  );
};

function LicensePlateMask(props) {
  return (
    <InputMask
      alwaysShowMask={true}
      mask="aaa-9*99"
      value={props.value}
      placeholder={props.placeholder}
      defaultValue={props.defaultValue}
      onChange={(e) => props.onChange(e.target.value)}
    ></InputMask>
  );
}

export const LicensePlateInput = ({
  loadingPlate,
  defaultValue,
  value,
  onChange,
  label,
  classLabel = 'label',
  className = 'generic-input',
  placeholder,
}) => {
  const [controlledValue, setControlledValue] = useState(value);

  useEffect(() => {
    const timer = setTimeout(() => {
      if (controlledValue) {
        onChange(controlledValue);
      }
    }, 1000);

    return () => clearTimeout(timer);
  }, [controlledValue]);

  useEffect(() => {
    if (controlledValue !== value) {
      setControlledValue(value);
    }
  }, [value]);

  const onChangeData = (text) => {
    setControlledValue(text);
  };
  return (
    <FormGroup className={className}>
      {label && <Label className={classLabel}>{label}</Label>}
      <InputGroup>
        <div className={`default-input form-control`}>
          <LicensePlateMask
            className={`masked-input`}
            value={controlledValue}
            placeholder={placeholder}
            loadingPlate={loadingPlate}
            onChange={onChangeData}
          />
          {loadingPlate && (
            <div style={{ display: 'flex', alignItems: 'center' }}>
              <Spinner color="primary" size="sm" />
            </div>
          )}
        </div>
      </InputGroup>
    </FormGroup>
  );
};

function ChassiMask(props) {
  return (
    <InputMask
      alwaysShowMask={true}
      mask="*** ***** * ** ******"
      value={props.value}
      placeholder={props.placeholder}
      defaultValue={props.defaultValue}
      onChange={(e) => props.onChange(e.target.value)}
    ></InputMask>
  );
}

export const ChassiInput = ({
  defaultValue,
  value,
  onChange,
  label,
  classLabel = 'label',
  className = 'generic-input',
  placeholder,
}) => {
  const [controlledValue, setControlledValue] = useState(value);

  useEffect(() => {
    const timer = setTimeout(() => {
      if (controlledValue) {
        onChange(controlledValue);
      }
    }, 1000);

    return () => clearTimeout(timer);
  }, [controlledValue]);

  useEffect(() => {
    if (controlledValue !== value) {
      setControlledValue(value);
    }
  }, [value]);

  const onChangeData = (text) => {
    setControlledValue(text);
  };
  return (
    <FormGroup className={className}>
      {label && <Label className={classLabel}>{label}</Label>}
      <InputGroup>
        <div className={`default-input form-control`}>
          <ChassiMask
            className={`masked-input`}
            value={controlledValue}
            placeholder={placeholder}
            onChange={onChangeData}
          ></ChassiMask>
          {/* <div style={{paddingTop: '12px'}}></div> */}
        </div>
      </InputGroup>
    </FormGroup>
  );
};

const CustomInput = React.forwardRef((props, ref) => (
  <input {...props} ref={ref} placeholder="00:00" />
));

function HourMask({ value, disabled, onChange, inputStyles }) {
  const inputRef = useRef(null);

  const formatHour = (value) => {
    if (!value) return '';

    const totalMinutes = parseInt(value, 10);
    const hours = Math.floor(totalMinutes / 100);
    const minutes = totalMinutes % 100;

    const formattedHours = String(hours).padStart(2, '0');
    const formattedMinutes = String(minutes).padStart(2, '0');

    return `${formattedHours}:${formattedMinutes}`;
  };

  const handleValueChange = (values) => {
    const { value: newValue } = values;

    const sanitizedValue = newValue === '' ? '0' : newValue;

    const formattedValue = sanitizedValue.replace(/(\d{2})(\d{2})/, '$1:$2');
    onChange(formattedValue);
  };

  const setCursorToEnd = () => {
    const inputElement = inputRef.current;
    inputElement.setSelectionRange(
      inputElement.value.length,
      inputElement.value.length
    );
  };

  const handleKeyDown = (event) => {
    const allowedKeys = [
      'Backspace',
      'Delete',
      'ArrowLeft',
      'ArrowRight',
      'Tab',
    ];
    if (allowedKeys.includes(event.key)) {
      if (event.key === 'Backspace') {
        event.preventDefault();
        const newValue = value.slice(0, -1);
        onChange(newValue);
      }
      return;
    }

    if (!/^[0-9]$/.test(event.key)) {
      event.preventDefault();
    }

    setTimeout(setCursorToEnd, 0);
  };

  const handleFocus = () => {
    setTimeout(setCursorToEnd, 0);
  };

  const handleMouseDown = () => {
    setTimeout(setCursorToEnd, 0);
  };

  const handleMouseUp = (event) => {
    event.preventDefault();
    setCursorToEnd();
  };

  const handleInput = () => {
    setCursorToEnd();
  };

  const handleSelect = () => {
    setTimeout(setCursorToEnd, 0);
  };

  return (
    <NumberFormat
      style={inputStyles}
      customInput={CustomInput}
      format={formatHour}
      mask="_"
      allowNegative={false}
      fixedDecimalScale
      allowEmptyFormatting={false}
      decimalScale={0}
      inputMode="numeric"
      value={value || ''}
      isAllowed={(values) => {
        const { floatValue } = values;
        return floatValue <= 9999;
      }}
      disabled={disabled}
      onValueChange={handleValueChange}
      onKeyDown={handleKeyDown}
      getInputRef={inputRef}
      onFocus={handleFocus}
      onMouseDown={handleMouseDown}
      onMouseUp={handleMouseUp}
      onInput={handleInput}
      onSelect={handleSelect}
    />
  );
}

export default HourMask;

export const HourInput = ({
  defaultValue,
  value,
  onChange,
  label,
  type,
  classLabel = 'label',
  className = 'generic-input',
  placeholder,
  disabled = false,
  inputStyles,
}) => {
  const [controlledValue, setControlledValue] = useState(value);

  useEffect(() => {
    const timer = setTimeout(() => {
      if (controlledValue) {
        onChange(controlledValue);
      }
    }, 1000);

    return () => clearTimeout(timer);
  }, [controlledValue]);

  useEffect(() => {
    if (controlledValue !== value) {
      setControlledValue(value);
    }
  }, [value]);

  const onChangeData = (text) => {
    setControlledValue(text);
  };

  return (
    <FormGroup className={className}>
      {label && <Label className={classLabel}>{label}</Label>}
      <InputGroup style={{ width: '100%' }}>
        <div
          style={{ width: '100%' }}
          className={`default-input ${disabled && 'disabled-show'}`}
        >
          <HourMask
            inputStyles={inputStyles}
            disabled={disabled}
            className={`masked-input`}
            value={controlledValue}
            placeholder={placeholder}
            onChange={onChangeData}
          ></HourMask>
        </div>
      </InputGroup>
    </FormGroup>
  );
};

export const SelectInput = ({
  options,
  disabled,
  label,
  classLabel = 'label',
  className = 'generic-input',
  value,
  style = {},
  isClearable,
  isSearchable,
  defaultValue,
  placeholder,
  type,
  onChange,
  components: customComponents,
  inputClassname,
  inputGroupClassname,
  InputGroupLeft,
  InputGroupRight,
  isMulti,
}) => {
  const DropdownIndicator = (props) => {
    return (
      <components.DropdownIndicator {...props}>
        <BsCaretDownFill color="white" />
      </components.DropdownIndicator>
    );
  };
  return (
    <FormGroup className={className} style={style}>
      {label && <Label className={classLabel}>{label}</Label>}
      <Select
        isMulti={isMulti || false}
        isClearable={isClearable}
        isSearchable={isSearchable}
        value={defaultValue}
        components={{ DropdownIndicator }}
        disabled={disabled}
        onChange={onChange}
        placeholder={placeholder}
        className={className}
        options={options}
        noOptionsMessage={() => 'Não há opções de dados'}
      />
    </FormGroup>
  );
};
