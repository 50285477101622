import React, { useEffect, useState } from 'react';
import {
  CNPJInput,
  LabelInput,
  TableInput,
  LabelCreatable,
  LabelRadios,
  CellphoneInput,
  RGInput,
  CEPInput,
  DateMaskInput,
} from '../../components/Input/Input';
import { AiOutlineCheck, AiOutlineClose } from 'react-icons/ai';
import { DefaultButton } from '../../components/Button/Button';
import {
  BsCardText,
  BsCarFront,
  BsFillTrashFill,
  BsPencilFill,
  BsPerson,
} from 'react-icons/bs';
import { useHistory } from 'react-router-dom';
import 'react-phone-number-input/style.css';
import { Tab, Tabs, TabList, TabPanel } from 'react-tabs';
import 'react-tabs/style/react-tabs.css';
import { isMobile } from 'react-device-detect';
import { CustomSelectCountries } from './components/CustomSelectCountries';
import { COUNTRIES } from '../../core/constants/countries';
import { isArray } from 'lodash';

const ModalClients = ({
  origins,
  createOption,
  onSave,
  closeModal,
  onChange,
  values,
  isNew,
  responsible,
  onRemoveVehicle,
  compareValues,
  onRemove,
}) => {
  const currentCountrie = COUNTRIES.find(
    (country) => country?.iso === values.countrie
  );
  const [selectedCountry, setSelectedCountry] = useState(currentCountrie);

  const history = useHistory();

  useEffect(() => {
    onChange('countrie', values?.countrie || 'BR');
    setSelectedCountry(currentCountrie);
  }, [values.countrie]);

  return (
    <div className="modal-container">
      <div className="modal-header">
        <div className="header-title">
          <h4 className="modal-title">
            {isNew ? 'Adicionar Cliente' : 'Editar Cliente'}
          </h4>
        </div>
        <div className="header-buttons">
          {compareValues ? (
            <DefaultButton
              className="modal-footer-button"
              label={isMobile ? '' : 'Salvar'}
              icon={<AiOutlineCheck className="button-icon" />}
              onClick={onSave}
            />
          ) : (
            <div />
          )}
          <DefaultButton
            className="modal-footer-button cancel"
            label={isMobile ? '' : 'Cancelar'}
            icon={<AiOutlineClose className="button-icon-cancel" />}
            onClick={closeModal}
          />
        </div>
      </div>
      <Tabs classname="main-tab">
        <TabList
          classname="tablist-mobile-correction"
          style={{ display: 'flex', flexFlow: 'row' }}
        >
          <Tab classname="tab-mobile-correction" style={{ width: '100%' }}>
            <text> Dados </text>
            <BsPerson />
          </Tab>
          {!isNew ? (
            <Tab classname="tab-mobile-correction" style={{ width: '100%' }}>
              <text> Veículos </text>
              <BsCarFront />
            </Tab>
          ) : (
            <div></div>
          )}
          <Tab classname="tab-mobile-correction" style={{ width: '100%' }}>
            <text> Observações </text>
            <BsCardText />
          </Tab>
        </TabList>

        <TabPanel>
          <div className="desc-input company">
            <LabelInput
              value={values.name}
              onChange={(value) => onChange('name', value)}
              placeholder={'Insira o nome'}
              label={'Nome*'}
            />
            <div className="desc-line last">
              <CustomSelectCountries
                onChange={onChange}
                selectedCountry={selectedCountry}
                setSelectedCountry={setSelectedCountry}
              />
              <CellphoneInput
                value={values.cellphone}
                onChange={(value) => onChange('cellphone', value)}
                placeholder={'Insira o celular'}
                label={'WhatsApp*'}
                mask={`${
                  isArray(selectedCountry?.mask)
                    ? selectedCountry?.mask[0]
                    : selectedCountry?.mask
                }`}
              />
              <CellphoneInput
                value={values.telephone}
                onChange={(value) => onChange('telephone', value)}
                placeholder={'Insira o telefone'}
                label={'Telefone'}
                mask="(99) 9999-9999"
              />
            </div>
            <LabelInput
              value={values.email}
              onChange={(value) => onChange('email', value)}
              placeholder={'Insira o e-mail'}
              label={'E-mail'}
            />
            <DateMaskInput
              value={values.birthday}
              onChange={(value) => onChange('birthday', value)}
              placeholder={'DD/MM/AAAA'}
              label={'Data de Nascimento'}
            />

            <LabelCreatable
              defaultValue={values.origin}
              onChange={(item) => onChange(`origin`, item)}
              placeholder={'INSIRA A ORIGEM DO CLIENTE'}
              label={'Origem'}
              options={origins.map((item) => ({
                label: item.name,
                value: item.id,
              }))}
              className="generic-input select-creatable"
              formatCreateLabel={(value) => `Criar "${value}"`}
              onCreateOption={createOption}
            />

            <LabelRadios
              className="custom-radio"
              options={radios}
              value={personValue(values.person)}
              onClick={(event) => onChange(`person`, event)}
            />
            {values && values.person && values.person.value == 2 ? (
              <CNPJInput
                value={values.cnpj}
                onChange={(value) => onChange('cnpj', value)}
                placeholder={'Insira o CNPJ'}
                label={'CNPJ'}
              />
            ) : (
              <CNPJInput
                value={values.cpf}
                onChange={(value) => onChange('cpf', value)}
                placeholder={'Insira o CPF'}
                label={'CPF'}
              />
            )}
            {values && values.person && values.person.value === 1 ? (
              <RGInput
                value={values.rg}
                onChange={(value) => onChange('rg', value)}
                placeholder={'Insira o RG'}
                label={'RG'}
              />
            ) : (
              <div />
            )}
            <CEPInput
              value={values.cep}
              onChange={(value) => onChange('cep', value)}
              placeholder={'Insira o CEP'}
              label={'CEP'}
            />
            <div className="desc-line">
              <LabelInput
                value={values.street}
                onChange={(value) => onChange('street', value)}
                placeholder={'Rua'}
                label={'Rua'}
              />
              <LabelInput
                value={values.number}
                onChange={(value) => onChange('number', value)}
                placeholder={'Insira o número'}
                label={'Número'}
              />
              <LabelInput
                value={values.complement}
                onChange={(value) => onChange('complement', value)}
                placeholder={'Insira o Complemento'}
                label={'Complemento'}
              />
            </div>
            <LabelInput
              value={values.neighborhood}
              onChange={(value) => onChange('neighborhood', value)}
              placeholder={'Bairro'}
              label={'Bairro'}
            />
            <div className="desc-line last">
              <LabelInput
                value={values.city}
                onChange={(value) => onChange('city', value)}
                placeholder={'Cidade'}
                label={'Cidade'}
              />
              <LabelInput
                value={values.state}
                onChange={(value) => onChange('state', value)}
                placeholder={'UF'}
                label={'UF'}
              />
            </div>
          </div>
        </TabPanel>

        {!isNew ? (
          <TabPanel>
            <div className="client-vehicles-table">
              <TableInput
                componentHeader={
                  <DefaultButton
                    className="add-button"
                    label={'+'}
                    onClick={() =>
                      history.push('/vehicles/details/0', {
                        responsible: values.id,
                      })
                    }
                  />
                }
                onClickButton={() => {}}
                label={'Veículos'}
                columns={columns(onRemoveVehicle)}
                rows={responsible}
              />
            </div>
          </TabPanel>
        ) : (
          <div />
        )}

        <TabPanel>
          <LabelInput
            type={'textarea'}
            value={values.observation}
            onChange={(value) => onChange('observation', value)}
            placeholder={'Insira a observação'}
            inputClassname={'text-area-min'}
            label={'Observação'}
          />
        </TabPanel>
      </Tabs>
      {values && !values.id ? (
        <div />
      ) : (
        <DefaultButton
          className="modal-footer-button delete"
          label={'Excluir'}
          icon={<BsFillTrashFill className="button-icon-cancel" />}
          onClick={onRemove}
        />
      )}
    </div>
  );
};

export default ModalClients;

const personValue = (value) => {
  if (value) {
    if (value === 1) {
      return { value: 1, label: 'Pessoa fisica', field: 'physical' };
    } else if (value === 2) {
      return { value: 2, label: 'Pessoa juridica', field: 'legal' };
    } else {
      return value;
    }
  }
};

const radios = [
  { value: 1, label: 'Pessoa fisica', field: 'physical' },
  { value: 2, label: 'Pessoa juridica', field: 'legal' },
];

const columns = (onRemove) => [
  {
    id: 1,
    field: 'model',
    label: 'Modelo',
    width: '1fr',
    resizable: false,
    // cellRenderer: Username,
  },
  {
    id: 2,
    field: 'plate',
    label: 'Placa',
    width: '130px',
    resizable: false,
  },
  {
    id: 3,
    field: 'actions',
    label: 'Ações',
    resizable: false,
    width: '85px',
    cellRenderer: ({ data }) => <ActionCell data={data} onRemove={onRemove} />,
  },
];

const ActionCell = ({ data }) => {
  let history = useHistory();
  return (
    <div>
      <BsPencilFill
        className="icon-action edit"
        onClick={() => {
          history.push(`/vehicles/details/${data.id}`, { ...data });
        }}
      />
    </div>
  );
};
