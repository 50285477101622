import { useState, useEffect, useRef } from 'react';
import { COUNTRIES } from '../../../core/constants/countries';
import './styles.scss';
import { FaCaretDown } from 'react-icons/fa';

export const CustomSelectCountries = ({
  selectedCountry,
  setSelectedCountry,
  onChange,
}) => {
  const [isOpen, setIsOpen] = useState(false);
  const [searchTerm, setSearchTerm] = useState('');
  const selectRef = useRef(null);
  const selectedRef = useRef(null);

  const handleSelect = (country) => {
    onChange('cellphone', '');
    onChange('countrie', country.iso);
    setSelectedCountry(country);
    setIsOpen(false);
    setSearchTerm('');
  };

  const handleSearch = (event) => {
    setSearchTerm(event.target.value);
  };

  useEffect(() => {
    const handleClickOutside = (event) => {
      if (selectRef.current && !selectRef.current.contains(event.target)) {
        setIsOpen(false);
      }
    };

    document.addEventListener('mousedown', handleClickOutside);
    return () => {
      document.removeEventListener('mousedown', handleClickOutside);
    };
  }, []);

  useEffect(() => {
    if (isOpen && selectedRef.current) {
      selectedRef.current.scrollIntoView({
        behavior: 'auto',
        block: 'center',
      });
    }
  }, [isOpen]);

  const filteredCountries = COUNTRIES.filter(
    (country) =>
      country.iso.toLowerCase().includes(searchTerm.toLowerCase()) ||
      country.code.includes(searchTerm)
  );

  return (
    <div ref={selectRef} className="custom-select">
      <div className="select-box" onClick={() => setIsOpen(!isOpen)}>
        {selectedCountry ? (
          <>
            <img
              style={{ width: 24, height: 24, marginRight: 10 }}
              src={selectedCountry.flag}
              alt={selectedCountry.iso}
            />
            <span>{selectedCountry.iso}</span>

            <FaCaretDown
              style={{ color: 'white', marginLeft: 4, marginRight: 10 }}
            />
          </>
        ) : (
          <span>Selecione</span>
        )}
      </div>

      {isOpen && (
        <div className="dropdown-custom-select">
          <input
            type="text"
            value={searchTerm}
            onChange={handleSearch}
            placeholder="Procurar país..."
            className="dropdown-input"
          />
          <ul className="dropdown-options">
            {filteredCountries?.map((item) => (
              <li
                key={item.iso}
                ref={item?.iso === selectedCountry?.iso ? selectedRef : null}
                onClick={() => handleSelect(item)}
                className={`dropdown-li ${
                  item.iso === selectedCountry?.iso ? 'selected-countrie' : ''
                }`}
              >
                <img
                  style={{ width: 24, height: 24, marginRight: 10 }}
                  src={item.flag}
                  alt={item.iso}
                />
                <span>
                  {item.iso} (+{item.code})
                </span>
              </li>
            ))}

            {filteredCountries.length === 0 && (
              <li style={{ padding: '10px', textAlign: 'center' }}>
                não encontrado!
              </li>
            )}
          </ul>
        </div>
      )}
    </div>
  );
};
