import { useEffect, useState } from 'react'
import { useDispatch, useSelector } from 'react-redux';
import { swalRemove } from '../../components/Swal/Swal';
import { alertError, alertSuccess } from '../../components/Toast';
import { setData } from '../../core/redux/app/Actions';
import CompanyScreen from '../../screens/Company/CompanyScreen';
import { supabase } from '../../supabaseClient'

const CompanyContainer = (props) => {
  const dispatch = useDispatch()
  const [items, setItems] = useState([])
  const reloadCompany = useSelector(state => state.app.reloadCompany || false)
  const user = useSelector(state => state.app.user || {})

  useEffect(() => {
    loadData()
}, [reloadCompany])

  const loadData = async () => {
    let value = {}

    if (user && [1, 2].includes(user.id)) {
      let { data, error } = await supabase
        .from('Company')
        .select('*')
        .is('deleted_at', null)

      value = { data, error }
    } else {
      const { data, error } = await supabase
        .from('UserCompany')
        .select(`
          type,
          company (
              * 
          )`)
        .eq('user', user.id)
        .eq('type', 1)
        .is('deleted_at', null)

      if (!error) {
        const newData = data.map(({ company, type }) => ({ ...company, type }))
        value = { data: newData, error }
      } else {
        value = { data, error }
      }
    }

    const { data, error } = value

    if (!error) {
      setItems(data)
    }
  }

  return (
    <CompanyScreen
      data={items}
      user={user}
      // onRemove={item => swalRemove({ withError: false, functionConfirmRemove: () => onRemove(item) })}
    />
  )
}

export default CompanyContainer