import React from 'react';
import ModalServiceAestheticContainer from '../../containers/ModalServiceAestheticContainer/ModalServiceAestheticContainer';
import ModalProductionPhasesAestheticContainer from '../../containers/ModalProductionPhaseAestheticContainer/ModalProductionPhaseAestheticContainer';
import ModalPackAestheticContainer from '../../containers/ModalPackAestheticContainer/ModalPackAestheticContainer';
import { AESTHETIC_MODULES } from '../../core/constants/aesthetic_modules';

const ModalAesthetic = ({ aesthetic }) => {
  return (
    <>
      {aesthetic === AESTHETIC_MODULES.PRODUCTION_PHASES && (
        <ModalProductionPhasesAestheticContainer />
      )}
      {aesthetic === AESTHETIC_MODULES.SERVICES && (
        <ModalServiceAestheticContainer />
      )}
      {aesthetic === AESTHETIC_MODULES.PACKAGES && (
        <ModalPackAestheticContainer />
      )}
    </>
  );
};

export default ModalAesthetic;
