import { useEffect, useState } from 'react';
import { useHistory } from 'react-router-dom';
import { useLocation } from 'react-router-dom/cjs/react-router-dom.min';
import { supabase } from '../../supabaseClient';
import { saveBudget } from '../../core/utils/budget';
import ModalBudget from '../../screens/ModalExternalBudget/ModalExternalBudget';
import CryptoJS from 'crypto-js';
import { alertError, alertSuccess } from '../../components/Toast';

const ExternalBudgetContainer = () => {
  const location = useLocation();
  let history = useHistory();
  const id = location.pathname.split('/')[2];
  // const company = useSelector((state) => state.app.company || {});
  const [values, setValues] = useState({});
  const [client, setClient] = useState([]);
  const [vehicle, setVehicle] = useState([]);
  const [service, setService] = useState([]);
  const [aeService, setAeService] = useState([]);
  const [parts, setParts] = useState([]);
  const [loading, setLoading] = useState(false);
  const [printCompany, setPrintCompany] = useState({});

  const loadData = async () => {
    try {
      var decoded = CryptoJS.enc.Hex.parse(id).toString(CryptoJS.enc.Base64);
      var decrypted = CryptoJS.AES.decrypt(
        decoded,
        process.env.REACT_APP_PASSWORD_CRYPTO
      ).toString(CryptoJS.enc.Utf8);

      // console.log("decoded", decoded);
      // console.log("decrypted", decrypted);

      if (!decrypted) {
        history.push('/login');
      }

      let { data, error } = await supabase
        .from('Budget')
        .select(
          `
          *,
          Vehicle(responsible, *)
          `
        )
        .eq('id', decrypted)
        .is('deleted_at', null);

      if (data.length === 0) {
        history.push('/login');
      }
      if (!error) {
        setValues(data[0]);
        setService(JSON.parse(data[0].service) || []);
        setAeService(JSON.parse(data[0].ae_service) || []);
        setParts(JSON.parse(data[0].parts) || []);
      }
    } catch (e) {
      console.log(e);
      history.push('/login');
    }
  };

  useEffect(() => {
    loadData();
  }, [id]);

  useEffect(() => {
    if (values?.client?.value || values.client) {
      loadVehicle();
    } else {
      loadVehicleNoClient();
    }
  }, [values?.client?.value || (values && values.client)]);

  useEffect(() => {
    loadClient();
  }, [values?.client]);

  useEffect(() => {
    if (values?.id_company) {
      loadCompany();
    }
  }, [values?.id_company]);

  const loadClient = async () => {
    let { data, error } = await supabase
      .from('Client')
      .select(
        `
        *,
        Vehicle(responsible, *),
        Origin(id, *)
        `
      )
      .eq('id', values.client)
      .is('deleted_at', null);

    if (!error) {
      setClient(data.map((item) => ({ label: item.name, value: item.id })));
    }
  };

  const loadCompany = async () => {
    let { data, error } = await supabase
      .from('Company')
      .select('*')
      .eq('id', values?.id_company)
      .is('deleted_at', null);

    if (!error) {
      setPrintCompany(data[0]);
    } else {
      console.log(error);
    }
  };

  const loadVehicle = async () => {
    let { data, error } = await supabase
      .from('Vehicle')
      .select('*')
      .eq('responsible', values?.client?.value || values.client)
      .is('deleted_at', null);

    if (!error) {
      setVehicle(
        data.map((item) => ({
          label: `${item.brand} / ${item.model} / ${item.plate}`,
          value: item.id,
        }))
      );
    }
  };

  const loadVehicleNoClient = async () => {
    let { data, error } = await supabase
      .from('Vehicle')
      .select('*')
      // .eq("responsible", values?.client?.value || values.client)
      .is('deleted_at', null);

    if (!error) {
      setVehicle(
        data.map((item) => ({
          label: `${item.brand} / ${item.model} / ${item.plate}`,
          value: item.id,
        }))
      );
    }
  };

  const approve = async () => {
    setLoading(true);
    const { data, error } = await saveBudget(values?.id, {
      // status: 3,
      approved_at: new Date(),
      status_client: 3,
    });
    setLoading(false);

    if (!error) {
      setValues(data[0]);
      alertSuccess('Orçamento aprovado com sucesso!');
    } else {
      alertError('Erro ao aprovar orçamento!');
    }
  };

  const analyse = async () => {
    setLoading(true);
    const { data, error } = await saveBudget(values?.id, {
      // status: 5,
      analysed_at: new Date(),
      status_client: 5,
    });
    setLoading(false);

    if (!error) {
      setValues(data[0]);
      alertSuccess('Orçamento em análise');
    } else {
      alertError('Erro, tente mais tarde!');
    }
  };

  const inDoubt = async () => {
    setLoading(true);
    const { data, error } = await saveBudget(values?.id, {
      // status: 6,
      doubt_at: new Date(),
      status_client: 6,
    });
    setLoading(false);

    if (!error) {
      setValues(data[0]);
      alertSuccess('Orçamento em análise');
    } else {
      alertError('Erro, tente mais tarde!');
    }
  };

  return (
    <>
      <ModalBudget
        printCompany={printCompany}
        values={values}
        client={client}
        vehicle={vehicle}
        service={service}
        aeService={aeService}
        parts={parts}
        loading={loading}
        inDoubt={inDoubt}
        analyse={analyse}
        approve={approve}
      />
    </>
  );
};

export default ExternalBudgetContainer;
