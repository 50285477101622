import { supabase } from "../../supabaseClient"

export const saveVehicle = async (id = undefined, item) => {
    if (id) {
        return await supabase
            .from('Vehicle')
            .update({ ...item })
            .match({ id })
        } else {
            return await supabase
            .from('Vehicle')
            .insert([{ ...item }], {skipDuplicates:true})
    }
}