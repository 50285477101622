import react, { useEffect, useState } from 'react';
import { useDispatch, useSelector } from 'react-redux';
import { useHistory, useLocation } from 'react-router-dom/cjs/react-router-dom.min';
import { getAllCourseVideos } from '../../core/utils/CourseVideo';
import YouTubeVideo from '../../components/YoutubeVideo/YoutubeVideo';

const videoList = [
  { id: 'dQw4w9WgXcQ', title: 'Video 1' },
  { id: '3JZ_D3ELwOQ', title: 'Video 2' },
  { id: 'kJQP7kiw5Fk', title: 'Video 3' },
  { id: 'L_jWHffIx5E', title: 'Video 4' },
];

const ManualVideoScreen = () => {
  const dispatch = useDispatch();
  const history = useHistory();
  const company = useSelector((state) => state.app.company || {});
  const location = useLocation();
  const id = location.pathname.split('/')[2];
  const [videos, setVideos] = useState([])
  const [selectedVideo, setSelectedVideo] = useState(null);

  const loadData = async () => {
    let { data, error } = await getAllCourseVideos(id)

    if (!error) {
      setVideos(data)
      setSelectedVideo(data[0])
    }
  }

  useEffect(() => {
    loadData()
  }, [])

  const handleSelectChange = (event) => {
    setSelectedVideo(videos.find(v => v.youtube_url == event.target.value));
  };

  return (
    <div className='container-course'>

      <div className="menu-header">
        <label htmlFor="videoSelect" className="video-label">Escolha um vídeo:</label>
        <select
          id="videoSelect"
          className="video-select"
          value={selectedVideo?.youtube_url}
          onChange={handleSelectChange}
        >
          {videos?.map((video, index) => (
            <option key={index} value={video.youtube_url} selected={selectedVideo?.youtube_url == video.youtube_url}>
              {video.title}
            </option>
          ))}
        </select>
      </div>

      <div className="video-container">
        <h3 className='item-text'>{selectedVideo?.title}</h3>
        <div className="video-frame" style={selectedVideo?.youtube_url ? null : { marginTop: 46 }}>
          {selectedVideo && <YouTubeVideo id={selectedVideo?.youtube_url} title={selectedVideo?.title} />}
        </div>
      </div>
      <div className="menu-sidebar">
        <h3 className='menu-title item-text'>Menu de Vídeos</h3>
        {videos.map(video => (
          <div
            key={video.id}
            className={`menu-item ${selectedVideo?.youtube_url === video.youtube_url ? 'menu-item-active' : ''}`}
            onClick={() => setSelectedVideo(video)}
          >
            <span className="item-text">
              {video.title}
            </span>
          </div>
        ))}
      </div>
    </div >
  );
};

export default ManualVideoScreen;
