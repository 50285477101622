import React from 'react';
import ModalServiceAestheticContainer from '../../../../containers/ModalServiceAestheticContainer/ModalServiceAestheticContainer';

export const AddAeService = ({
  isModalToCreateServiceOpen,
  setIsModalToCreateServiceOpen,
}) => {
  return (
    isModalToCreateServiceOpen && (
      <div className="container-modal-create-part">
        <ModalServiceAestheticContainer
          setIsModalToCreateServiceOpen={setIsModalToCreateServiceOpen}
        />
      </div>
    )
  );
};
