import React from 'react';
import { FormGroup, Label } from 'reactstrap';

export function InputText({ label, classLabel = 'label', ...rest }) {
  return (
    <FormGroup className="generic-input">
      {label && <Label className={classLabel}>{label}</Label>}
      <input
        {...rest}
        style={{ paddingLeft: 10, paddingRight: 10 }}
        className={`default-input ${rest.className}`}
      />
    </FormGroup>
  );
}
