import { actionsCreator } from "../utils/Actions";
import ActionCreator from "../utils/ActionCreator";

const { set } = new ActionCreator("app");

const { actions, functions } = actionsCreator("app");
export { actions as appActions };

export const setData = set("SET_DATA");
export const setMergeData = set("SET_MERGE_DATA");
export const setLogout = set("SET_LOGOUT");
