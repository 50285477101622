import React from 'react';
import { BUDGET_TABS } from '../../utils/enum';
import GridTable from '@nadavshaar/react-grid-table';
import { columnsPiece } from '../../utils/constants';
import { LabelWithPlusButton } from '../LabelWithPlusButton';

export const Parts = (props) => {
  const {
    budget,
    isOpen,
    setOpen,
    onRemovePiece,
    changePartsValue,
    openEditModal,
    parts,
  } = props;

  if (budget !== BUDGET_TABS.PARTS) {
    return;
  }

  return (
    <div
      style={{
        display: 'flex',
        flexDirection: 'column',
        gap: '5px',
      }}
    >
      <LabelWithPlusButton setOpen={setOpen} tabNumber={2} label={'Peças'} />

      <div className="bodyshop-body modal-budget modal-budget-gridtable piece-gridtable">
        <GridTable
          columns={columnsPiece(
            onRemovePiece,
            changePartsValue,
            isOpen,
            setOpen,
            openEditModal
          )}
          rows={parts}
          enableColumnsReorder={false}
          showSearch={false}
          canReorder={false}
          isPaginated={false}
          showColumnVisibilityManager={false}
          showRowsInformation={false}
          texts={{ noResults: 'Nenhum resultado foi encontrado' }}
        />
      </div>
    </div>
  );
};
