import React, { useState } from 'react';
import {
  LabelInput,
  SelectInput,
  HourInput,
  TextInput,
  MoneyInput,
} from '../../components/Input/Input';
import { AiOutlineCheck, AiOutlineClose } from 'react-icons/ai';
import { DefaultButton } from '../../components/Button/Button';
import { BsFillTrashFill } from 'react-icons/bs';
import { isMobile } from 'react-device-detect';
import { inPlaceSort } from 'fast-sort';

const ModalService = ({
  values,
  closeModal,
  isNew,
  onChange,
  onSave,
  compareValues,
  onRemove,
  serviceType,
  pieces,
}) => {
  return (
    <div className="modal-service-container">
      <div className="modal-header">
        <div className="header-title">
          <h4 className="modal-title">Serviços Funilaria</h4>
        </div>
        <div className="header-buttons">
          {compareValues ? (
            <DefaultButton
              className="modal-footer-button"
              label={isMobile ? '' : 'Salvar'}
              icon={<AiOutlineCheck className="button-icon" />}
              onClick={onSave}
            />
          ) : (
            <div />
          )}
          <DefaultButton
            className="modal-footer-button cancel"
            label={isMobile ? '' : 'Cancelar'}
            icon={<AiOutlineClose className="button-icon-cancel" />}
            onClick={closeModal}
          />
        </div>
      </div>
      <div className="desc-input company">
        <br />
        <div className="bodyshop-title">
          <text>
            {isNew ? (
              <TextInput
                value={values.title}
                placeholder="Adicionar título"
                onChange={(event) =>
                  onChange('title', event.target.value.toUpperCase())
                }
                className="item-title"
              />
            ) : (
              <TextInput
                value={values.title}
                placeholder={values.title}
                onChange={(event) =>
                  onChange('title', event.target.value.toUpperCase())
                }
                className="item-title"
              />
            )}
          </text>
        </div>
        <br />
        <div className="lower-aesthetic-div">
          <div className="main-upper-div">
            {isMobile ? (
              <div style={{ width: '100%' }}>
                <div
                  style={{ width: '100%' }}
                  className="void-class service-part-type"
                >
                  <SelectInput
                    onChange={(value) => {
                      onChange('pieces', value.label);
                      onChange('parts', value.parts);
                    }}
                    // isSearchable={!isMobile}
                    placeholder={'Escolha a peça'}
                    label={'Peças'}
                    value={pieces.find((item) => item.pieces === values.name)}
                    defaultValue={pieces.find(
                      (item) => item.name === values.pieces
                    )}
                    options={inPlaceSort(pieces).by('name')}
                  />
                </div>
                <div
                  style={{ width: '100%' }}
                  className="void-class service-part-type"
                >
                  <SelectInput
                    onChange={(value) => onChange('service_type', value)}
                    // isSearchable={!isMobile}
                    placeholder={'Escolha o tipo de serviço'}
                    label={'Tipo de serviço'}
                    value={values.service_type}
                    defaultValue={values.service_type}
                    options={inPlaceSort(serviceType).by('title')}
                  />
                </div>
              </div>
            ) : (
              <div className="service-description">
                <div style={{ width: '100%' }} className="void-class">
                  <SelectInput
                    // isSearchable={!isMobile}
                    onChange={(value) => {
                      onChange('pieces', value.label);
                      onChange('parts', value.parts);
                    }}
                    placeholder={'Escolha a peça'}
                    label={'Peças'}
                    value={pieces.find((item) => item.pieces === values.name)}
                    defaultValue={pieces.find(
                      (item) => item.name === values.pieces
                    )}
                    options={inPlaceSort(pieces).by('name')}
                  />
                </div>
                <div style={{ width: '100%' }} className="void-class">
                  <SelectInput
                    // isSearchable={!isMobile}
                    onChange={(value) => onChange('service_type', value)}
                    placeholder={'Escolha o tipo de serviço'}
                    label={'Tipo de serviço'}
                    value={values.service_type}
                    defaultValue={values.service_type}
                    options={inPlaceSort(serviceType).by('title')}
                  />
                </div>
              </div>
            )}
          </div>
          <div style={{ paddingTop: '15px' }}>
            <div className={'mobile-gap'}>
              <div>
                <div className="service-size-content">
                  <LabelInput
                    disabled={true}
                    className={'small-size-input'}
                    placeholder={'P'}
                    label={'Tabela'}
                  />
                  <LabelInput
                    disabled={true}
                    className={'small-size-input'}
                    placeholder={'M'}
                    label={''}
                  />
                  <LabelInput
                    disabled={true}
                    className={'small-size-input'}
                    placeholder={'G'}
                    label={''}
                  />
                </div>
              </div>
              {isMobile ? (
                <div />
              ) : (
                <div className="mobile-line-modal-service">
                  <div
                    style={{
                      width: 1,
                      backgroundColor: 'gray',
                      marginTop: 40,
                    }}
                  />
                </div>
              )}
              <div className="service-gap">
                <div className="first-service-size-content-row">
                  <HourInput
                    className={'hour-input'}
                    onChange={(value) => onChange('p_time', value)}
                    label={'Horas'}
                    value={values.p_time}
                    type="number"
                  />
                  <MoneyInput
                    className={'normal-size-input'}
                    onChange={(value) => onChange('p_value', value)}
                    label={'Valor'}
                    placeholder={'____'}
                    value={values.p_value}
                    type="number"
                  />
                  <MoneyInput
                    className={'last-hour-input'}
                    disabled={true}
                    value={
                      values.p_time && values.p_value
                        ? fixMoney(values.p_time, values.p_value)
                        : ''
                    }
                    label={'Total'}
                    type="number"
                  />
                </div>
                <div className="middle-service-size-content">
                  <HourInput
                    className={'hour-input'}
                    onChange={(value) => onChange('m_time', value)}
                    label={''}
                    value={values.m_time}
                    type="number"
                  />
                  <MoneyInput
                    className={'normal-size-input'}
                    onChange={(value) => onChange('m_value', value)}
                    placeholder={'____'}
                    value={values.m_value}
                    // type="number"
                  />
                  <MoneyInput
                    className={'last-hour-input'}
                    disabled={true}
                    value={
                      values.m_time && values.m_value
                        ? fixMoney(values.m_time, values.m_value)
                        : ''
                    }
                    type="number"
                  />
                </div>
                <div className="last-service-size-content">
                  <HourInput
                    className={'hour-input'}
                    onChange={(value) => onChange('g_time', value)}
                    label={''}
                    value={values.g_time}
                    type="number"
                  />
                  <MoneyInput
                    className={'normal-size-input'}
                    onChange={(value) => onChange('g_value', value)}
                    placeholder={'____'}
                    value={values.g_value}
                    // type="number"
                  />
                  <MoneyInput
                    className={'last-hour-input'}
                    disabled={true}
                    value={
                      values.g_time && values.g_value
                        ? fixMoney(values.g_time, values.g_value)
                        : ''
                    }
                    type="number"
                  />
                </div>
              </div>
            </div>
            <div className="service-img-placement"></div>
            <div
              style={{ display: 'flex', paddingBottom: 10 }}
              className="repeat-days-modal"
            >
              <div className="notification-days-mobile">
                <text
                  style={{
                    paddingTop: 28,
                    paddingRight: 5,
                    fontWeight: 500,
                    fontSize: 16,
                    color: 'white',
                  }}
                >
                  {' '}
                  Notificar{' '}
                </text>
                <div
                  style={{
                    width: 155,
                    backgroundColor: '#67676a',
                    maxHeight: 50,
                    borderRadius: 10,
                    display: 'flex',
                    alignItems: 'center',
                    marginTop: 15,
                  }}
                >
                  <LabelInput
                    className={'small-size-input-frequency'}
                    onChange={(value) => onChange('notification_days', value)}
                    placeholder={'___'}
                    value={values.notification_days}
                    label={''}
                    type="number"
                  />
                  <div
                    style={{
                      fontSize: 16,
                      fontWeight: 500,
                      color: 'white',
                      marginRight: 20,
                      backgroundColor: '#67676a',
                    }}
                  >
                    dias
                  </div>
                </div>
              </div>
              <div className="notification-days-mobile">
                <text
                  className="repeat-text"
                  style={{
                    paddingTop: 28,
                    paddingRight: 15,
                    fontWeight: 500,
                    fontSize: 16,
                    color: 'white',
                  }}
                >
                  {' '}
                  Repetir{' '}
                </text>
                <div
                  style={{
                    width: 155,
                    backgroundColor: '#67676a',
                    maxHeight: 50,
                    borderRadius: 10,
                    display: 'flex',
                    alignItems: 'center',
                    marginTop: 15,
                  }}
                >
                  <LabelInput
                    className={'small-size-input-frequency'}
                    onChange={(value) => onChange('repeat', value)}
                    placeholder={'___'}
                    value={values.repeat}
                    label={''}
                    type="number"
                  />
                  <div
                    style={{
                      fontSize: 16,
                      fontWeight: 500,
                      color: 'white',
                      marginRight: 20,
                      backgroundColor: '#67676a',
                    }}
                  >
                    vezes
                  </div>
                </div>
              </div>
            </div>
            <div>
              <LabelInput
                type={'textarea'}
                className="message-area-min"
                value={values.notification_msg}
                onChange={(value) => onChange('notification_msg', value)}
                placeholder={'Insira a mensagem da notificação'}
              />
            </div>
            <div className="modal-service-footer">
              {/* <div style={{ marginTop: 5 }}>
                <ActiveSwitch
                  value={values.active}
                  onChange={(value) => onChange("active", value)}
                />
              </div> */}
              {values && !values.id ? (
                <div />
              ) : (
                <DefaultButton
                  className="modal-footer-button delete"
                  label={'Excluir'}
                  icon={<BsFillTrashFill className="button-icon-cancel" />}
                  onClick={onRemove}
                />
              )}
            </div>
          </div>
        </div>
      </div>
    </div>
  );
};

export default ModalService;

const fixMoney = (value1, value2) => {
  value2 = value2.toString();

  value2 = value2.replace(/[^\d,.]/g, '');
  value2 = value2.replace(/(?<=\.\d*?)0+$/, '');

  if (value1 && value2) {
    const number1 = formatHourToNumber(value1);
    const number2 = parseFloat(value2.replace(',', '.'));

    const result = number1 * number2;

    return result.toFixed(2);
  }
};

const formatHourToNumber = (hora) => {
  const partes = hora.split(':');
  const horas = parseInt(partes[0], 10);
  const minutos = partes[1] ? parseInt(partes[1], 10) : 0;
  return horas + minutos / 60;
};
