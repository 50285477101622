import React, { useEffect, useState } from "react";
import { Helmet } from "react-helmet";

const webUrl = "https://prospera.app.br";

const MetaDecorator = () => {
  const [url, setUrl] = useState(webUrl);

  useEffect(() => {
    setUrl(webUrl + window.location.pathname + window.location.search);
  }, []);

  return (
    <div>
      <Helmet>
        <title>Prospera</title>
        <meta property="og:title" content="Prospera" />
        <meta property="og:type" content="website" />
        <meta property="og:url" content={url} />
        <meta property="og:description" content="Prospera" />
        <meta property="og:image" content={`${webUrl}/logoShare.png`} />
        <meta name="twitter:card" content="summary_large_image" />
        <meta name="twitter:image:alt" content="imagem" />
        <meta property="twitter:card" content="summary_large_image" />
        <meta property="twitter:url" content={url} />
        <meta property="twitter:title" content="Prospera" />
        <meta property="twitter:description" content="Prospera" />
        <meta property="twitter:image" content={`${webUrl}/logoShare.png`} />
      </Helmet>
    </div>
  );
};

export default MetaDecorator;
