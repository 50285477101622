import {
  LabelInput,
  MoneyInput,
  TextInput,
} from "../../components/Input/Input";
import { AiOutlineCheck, AiOutlineClose } from "react-icons/ai";
import { DefaultButton } from "../../components/Button/Button";
import { BsFillTrashFill } from "react-icons/bs";
import { isMobile } from "react-device-detect";

const ModalValueHourChart = ({
  onRemove,
  values,
  closeModal,
  isNew,
  onChange,
  onSave,
  compareValues,
  subService,
}) => {
  return (
    <div className="modal-container">
      <div className="modal-header">
        <div className="header-title">
          <h4 className="modal-title">Valor x Hora</h4>
        </div>
        <div className="header-buttons">
          {compareValues ? (
            <DefaultButton
              className="modal-footer-button"
              label={isMobile ? "" : "Salvar"}
              icon={<AiOutlineCheck className="button-icon" />}
              onClick={onSave}
            />
          ) : (
            <div />
          )}
          <DefaultButton
            className="modal-footer-button cancel"
            label={isMobile ? "" : "Cancelar"}
            icon={<AiOutlineClose className="button-icon-cancel" />}
            onClick={closeModal}
          />
        </div>
      </div>
      <div className="desc-input company">
        <br />
        <div style={{ gap: 10 }} className="bodyshop-title step">
          {!isNew && (
            <LabelInput
              onChange={(value) => onChange("index", value)}
              className={"small-size-input step"}
              placeholder={""}
              label={""}
              type={"number"}
              value={values?.index}
            />
          )}
          {isNew ? (
            <TextInput
              value={values?.title}
              placeholder="Adicionar título"
              onChange={(event) =>
                onChange("title", event.target.value.toUpperCase())
              }
              className="item-title"
            />
          ) : (
            <TextInput
              value={values?.title}
              placeholder={values?.title}
              onChange={(event) =>
                onChange("title", event.target.value.toUpperCase())
              }
              className="item-title"
            />
          )}
        </div>
        <div>
          <div style={{ paddingTop: 5, paddingBottom: 5, marginTop: 20 }}>
            <text style={{ color: "white", fontWeight: 500, fontSize: 14 }}>
              {" "}
              Tipos de Serviço{" "}
            </text>
          </div>
          {subService && subService.length > 0 ? (
            subService.map((item) => {
              return (
                <div
                  style={{ display: "flex", paddingBottom: 10 }}
                  className="div-hour-value-description"
                >
                  <LabelInput
                    value={item.title}
                    disabled={true}
                    placeholder={""}
                  />
                  <div>
                    <MoneyInput
                      type="number"
                      min={0}
                      onChange={(value) =>
                        onChange("number", { id: item.id, value })
                      }
                      // defaultValue={item?.value}
                      className="value-hour-price-input"
                      value={
                        item?.value && typeof item?.value === "number"
                          ? item?.value.toFixed(2)
                          : item?.value &&
                            typeof item.value === "string" &&
                            !item.value.includes("NaN")
                          ? item.value
                          : ""
                      }
                      placeholder={"___"}
                    />
                  </div>
                </div>
              );
            })
          ) : (
            <div
              style={{
                display: "flex",
                justifyContent: "center",
                marginTop: 20,
              }}
            >
              <text
                style={{ color: "#9e9e9e", fontSize: 14, fontWeight: "700" }}
              >
                Nenhum dado encontrado. Adicione informações na aba 'Tipo de
                Serviço'.
              </text>
            </div>
          )}
        </div>
      </div>
      <div className="modal-footer-table">
        {values && !values.id ? (
          <div />
        ) : (
          <DefaultButton
            className="modal-footer-button delete"
            label={"Excluir"}
            icon={<BsFillTrashFill className="button-icon-cancel" />}
            onClick={onRemove}
          />
        )}
      </div>
    </div>
  );
};

export default ModalValueHourChart;
