import './App.scss';
import { useState, useEffect } from 'react';
import { supabase } from './supabaseClient';
import { useLocation } from 'react-router-dom';
import {
  AuthRoutes,
  ExternalRoute,
  FirstRoute,
} from './core/router/AuthRoutes';
import { PublicRoutes } from './core/router/PublicRoutes';
import { alertError, ToastComponent } from './components/Toast';
import { useDispatch } from 'react-redux';
import { setData, setLogout } from './core/redux/app/Actions';
import { version } from '../package.json';
import CacheBuster from 'react-cache-buster';
import { Spinner } from 'reactstrap';

export default function App() {
  // const isProduction = process.env.NODE_ENV === 'production';
  const location = useLocation();
  const dispatch = useDispatch();
  const [loading, setLoading] = useState(false);
  const [session, setSession] = useState(null);
  const [isFirst, setIsFirst] = useState(false);

  useEffect(() => {
    setSession(supabase.auth.session());
    supabase.auth.onAuthStateChange((_event, session) => {
      if (_event == 'PASSWORD_RECOVERY') {
        setSession(null);
      } else {
        setSession(session);
      }
    });
  }, []);

  useEffect(() => {
    if (session && session.user && session.user.email) {
      loadUser(session.user.email);
    }
  }, [session && session.user && session.user.email]);

  const loadUser = async (email) => {
    setLoading(true);

    const { data: users, error } = await supabase
      .from('User')
      .select(
        `
        *,
        UserCompany (
            type
        )`
      )
      .eq('email', email)
      .is('UserCompany.deleted_at', null);

    if (!error) {
      const user = users[0];
      if (
        (user && user.UserCompany && user.UserCompany.length > 0) ||
        (user && [1, 2].includes(user.id))
      ) {
        dispatch(setData({ user }));
        const isFirst = user && !user.isRegistered;
        setIsFirst(isFirst);
      } else {
        alertError('Seu usuário não está associado a nenhuma empresa!');
        supabase.auth.signOut();
        dispatch(setLogout({}));
      }
    } else {
      setSession(null);
      alertError('Erro ao entrar, tente novamente mais tarde!');
    }
    setLoading(false);
  };

  const reloadApp = () => {
    setIsFirst(false);
  };

  return (
    <CacheBuster
      currentVersion={version}
      isEnabled={false} //If false, the library is disabled.
      isVerboseMode={true} //If true, the library writes verbose logs to console.
      loadingComponent={
        <div className="spinner-clear">
          <Spinner color="primary" size="sm" />
        </div>
      } //If not pass, nothing appears at the time of new version check.
      // metaFileDirectory={"."} //If public assets are hosted somewhere other than root on your server.
    >
      {loading ? (
        <div className="spinner-clear">
          <Spinner color="primary" size="sm" />
        </div>
      ) : (
        <div>
          {location?.pathname?.includes('/external-budget/') ? (
            <ExternalRoute />
          ) : isFirst ? (
            <FirstRoute reloadApp={reloadApp} />
          ) : session ? (
            <AuthRoutes session={session} />
          ) : (
            <PublicRoutes />
          )}
          <ToastComponent />
        </div>
      )}
    </CacheBuster>
  );
}
