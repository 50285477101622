import { Button, InputGroupText } from 'reactstrap'
import { LabelInput } from '../../components/Input/Input'
import { BsEyeSlash } from 'react-icons/bs'
import { DefaultButton, LinkButton, NavLink } from '../../components/Button/Button'
import { openRoutes } from '../../core/router/routes';
import { AiFillEye, AiFillEyeInvisible } from 'react-icons/ai';


const LoginScreen = ({ handleLogin, onChange, values, loading, forgetPassword, history, hidden, setHidden }) => {
  return (
    <>
      <div className='image'>
        <img className='backWorker' src={require("../../assets/images/worker.png").default} />
      </div>
      <div className="login-container">
        <div className="form">
          <div>
            <img className="logo" src={require("../../assets/images/web.png").default} />
          </div>
          <div className='loginInfo'>
            <h2 className="title">Entrar</h2>
            <div className="line" />
            <h5 className="description">Insira seus dados abaixo para acessar</h5>
            <div className="inputs">
              <LabelInput
                label={"Email"}
                className={'login-input'}
                classLabel={'loginLabel'}
                value={values.email}
                placeholder={"Insira seu email"}
                type={"email"}
                onChange={value => onChange("email", value)}
              />
              <LabelInput
                // className="generic-input"
                classLabel={'loginLabel'}
                className={'login-input password'}
                label={"Senha"}
                value={values.password}
                placeholder={"Insira sua senha"}
                type={hidden ? "email" : 'password'}
                onChange={value => onChange("password", value)}
                InputGroupRight={() => <div className='div-icon-eye'>
                  {hidden ? <AiFillEye onClick={() => setHidden(false)} /> : <AiFillEyeInvisible onClick={() => setHidden(true)} />}
                </div>}
              />
              <h6 className='forgotPassword' onClick={() => history.push("/recovery")}>Esqueceu sua senha?</h6>
              <span></span>
              <div className='navButonLog'>
                <div className="loginBtn">
                  <DefaultButton
                    disabled={loading}
                    label={loading ? "Entrando.." : "Acessar"}
                    onClick={handleLogin}
                    className={"shadow-5"}
                  />
                </div>
              </div>
            </div>
          </div>
        </div>
      </div>
    </>
  )
}

export default LoginScreen