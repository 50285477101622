import GridTable from "@nadavshaar/react-grid-table";
import { inPlaceSort } from "fast-sort";
import SearchHeader from "../../components/SearchHeader/SearchHeader";

const PartsScreen = ({ columns, rows, filter, setFilter }) => {
  inPlaceSort(rows).by("name");

  return (
    <div className="bodyshop-container">
      <div
        style={{ display: "flex", flexDirection: "column" }}
        className="bodyshop-body default-table gridtable-for-add-parts"
      >
        <SearchHeader filter={filter} setFilter={setFilter} />
        <GridTable
          columns={columns}
          rows={rows}
          enableColumnsReorder={false}
          canReorder={false}
          showSearch={false}
          isPaginated={false}
          showColumnVisibilityManager={false}
          showRowsInformation={false}
          texts={{
            noResults: "Nenhum resultado foi encontrado",
            search: "Buscar:",
          }}
        />
      </div>
    </div>
  );
};

export default PartsScreen;
