import React, { useState } from 'react';

function Selector({ name, values, handleClick, buttonClass }) {
  // const [isHighlighted, setIsHighlighted] = useState(false);

  // const handleClick = () => {
  //   setIsHighlighted(!isHighlighted);
  // };

  // const buttonClass = isHighlighted ? 'highlighted' : '';

  return (
    <button value={values} className={`custom-button ${buttonClass}`} onClick={handleClick}>
      {name}
    </button>
  );
}


export default Selector;
