import { useEffect, useCallback } from "react";
import { useDropzone } from "react-dropzone";
import { FaImages, FaTrashAlt } from "react-icons/fa";
import { supabase } from "../../supabaseClient";
import { alertError } from "../Toast";

export const SingleDropzone = ({
  onChange = () => {},
  value,
  table = "company",
}) => {
  const { acceptedFiles, getRootProps, getInputProps } = useDropzone({
    validator: typeValidator,
    maxFiles: 1,
  });

  const uploadImage = async (file) => {
    const { data, error } = await supabase.storage
      .from(table)
      .upload(`images/${new Date().getTime()}`, file);

    if (error) {
      console.log("Erro enviando imagem.", error.message);
      alertError("Erro ao enviar imagem");
    } else {
      onChange(data.Key);
      console.log("Imagem enviada com sucesso.", data.Key);
    }
  };

  useEffect(() => {
    if (acceptedFiles && acceptedFiles[0]) {
      uploadImage(acceptedFiles[0]);
    }
  }, [acceptedFiles]);

  return (
    <section
      className="dropzone-container"
      style={{ display: "flex", justifyContent: "center" }}
    >
      <div {...getRootProps({ className: "dropzone" })}>
        <input {...getInputProps()} />
        {value ? (
          <img
            src={`${process.env.REACT_APP_SUPABASE_URL}/storage/v1/object/public/${value}`}
            alt="Imagem carregada"
            style={{
              maxWidth: "88px",
              maxHeight: "150px",
              alignSelf: "center",
            }}
          />
        ) : (
          <p>Arraste e solte uma imagem aqui ou clique para selecionar.</p>
        )}
      </div>
    </section>
  );
};

export const MultipleDropzone = ({
  onChange = () => {},
  value,
  table = "company",
}) => {
  const onDrop = useCallback(
    (acceptedFiles) => {
      uploadImage(acceptedFiles);
    },
    [value]
  );

  const uploadImage = async (files) => {
    const promises = [];

    for (let i = 0; i < files.length; i++) {
      promises.push(
        supabase.storage
          .from(table)
          .upload(`images/${new Date().getTime()}`, files[i])
      );
    }

    let { data, error } = await Promise.all(promises).then((images) => {
      if (
        images.filter((item) => item && item.error).length === images.length
      ) {
        return { data: undefined, error: true };
      }
      return {
        data: images.map((item) => item && item.data && item.data.Key),
        error: undefined,
      };
    });

    if (error) {
      console.log("Erro enviando imagem.", error.message);
      alertError("Erro ao enviar imagem");
    } else {
      onChange([...new Set(value)].concat(data));
      console.log("Imagem enviada com sucesso.", data);
    }
  };

  const { getRootProps, getInputProps } = useDropzone({
    validator: typeValidator,
    onDrop,
  });

  const sendToFirst = (e, item) => {
    e.preventDefault();
    e.stopPropagation();
    const newArray = value.filter((row) => row !== item);
    onChange([item].concat(newArray));
  };

  const removeImage = (e, item) => {
    e.preventDefault();
    e.stopPropagation();
    onChange(value.filter((row) => row !== item));
  };

  return (
    <section
      className="dropzone-container multiple"
      style={{ display: "flex", justifyContent: "center" }}
    >
      <div {...getRootProps({ className: "dropzone" })}>
        <input {...getInputProps()} />
        {value && value.length > 0 ? (
          <div className="div-multiples">
            {value.map((item, index) => (
              <div className="div-multiple">
                <img
                  src={`${process.env.REACT_APP_SUPABASE_URL}/storage/v1/object/public/${item}`}
                  alt="Imagem carregada"
                  className="multiple-image"
                />
                <div className="div-icons-multiple">
                  {index > 0 && (
                    <FaImages
                      className="icon-multiple"
                      onClick={(e) => sendToFirst(e, item)}
                    />
                  )}
                  <FaTrashAlt
                    className="icon-multiple delete"
                    onClick={(e) => removeImage(e, item)}
                  />
                </div>
              </div>
            ))}
          </div>
        ) : (
          <p>Arraste e solte imagens aqui ou clique para selecionar.</p>
        )}
      </div>
    </section>
  );
};

function typeValidator(file) {
  if (
    !["image/jpeg", "image/jpeg", "image/jpeg", "image/png"].includes(file.type)
  ) {
    return {
      code: "type-is-diferent",
      message: `Tipo de arquivo não suportado`,
    };
  }

  if (file.size > 25 * 1024 * 1024) {
    return {
      code: "file-size-too-large",
      message: `Tamanho do arquivo deve ser menor que 25 MB`,
    };
  }

  return null;
}
