import { React } from "react";
import GridTable from "@nadavshaar/react-grid-table";
import { BsPencilFill } from "react-icons/bs";
import { useHistory } from "react-router-dom";
import { inPlaceSort } from "fast-sort";
import ReactImageFallback from "react-image-fallback";

const CompanyScreen = ({ data, onRemove, user }) => {
  let history = useHistory();

  inPlaceSort(data).by("name");
  
  return (
    <div className="company-container">
      <div className="company-body default-table user-gridtable">
        <GridTable
          columns={columns(onRemove, user, history)}
          rows={data}
          canReorder={false}
          enableColumnsReorder={false}
          showSearch={true}
          isPaginated={false}
          showColumnVisibilityManager={false}
          showRowsInformation={false}
          texts={{
            noResults: "Nenhum resultado foi encontrado",
            search: "Buscar:",
            totalRows: "Número de itens:",
            rows: "Itens:",
            selected: "Selecionado",
            rowsPerPage: "Itens por página:",
            page: "Página:",
            of: "de",
            prev: "Anterior",
            next: "Próximo",
            columnVisibility: "Colunas visíveis",
          }}
        />
      </div>
    </div>
  );
};

export default CompanyScreen;

const columns = (onRemove, user, history) => {
  return [
    {
      id: 3,
      field: "logo",
      label: "",
      width: "50px",
      resizable: true,
      cellRenderer: ({ data }) => {
        return (
          <div className="image-cell">
            <div className="image-container">
              <ReactImageFallback
                src={`${process.env.REACT_APP_SUPABASE_URL}/storage/v1/object/public/${data.logo}`}
                fallbackImage={
                  require("../../assets/images/company_fallback_img.jpg")
                    .default
                }
                initialImage={
                  require("../../assets/images/dark_loader.gif").default
                }
                alt=""
                className="my-image"
              />
            </div>
          </div>
        );
      },
    },
    {
      id: 1,
      field: "name",
      label: "Nome",
      width: "1fr",
      resizable: false,
      cellRenderer: ({ data }) => (
        <div
          className="clickable-name"
          onClick={() => history.push(`/company/details/${data.id}`, data)}
        >
          {data.name}
        </div>
      ),
    },
    {
      id: 2,
      field: "actions",
      label: "Ações",
      resizable: false,
      width: "70px",
      cellRenderer: ({ data }) => (
        <ActionCell
          data={data}
          onRemove={onRemove}
          user={user}
          history={history}
        />
      ),
    },
  ];
};

const ActionCell = ({ data, history }) => {
  return (
    <div className="field-actions">
      <BsPencilFill
        className="icon-action edit"
        onClick={() => history.push(`/company/details/${data.id}`, data)}
      />
      {/* {user && [1, 2].includes(user.id) && <BsTrashFill className='icon-action trash' onClick={() => onRemove(data)} />} */}
    </div>
  );
};
