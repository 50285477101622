import React, { useEffect, useState } from "react";
import { BsTextIndentLeft, BsTextIndentRight } from "react-icons/bs";
import {
  ProSidebar,
  Menu,
  MenuItem,
  SidebarContent,
  SubMenu,
} from "react-pro-sidebar";
import { menuRoutes } from "../../core/router/routes";
import { useDispatch } from "react-redux";
import { setData } from "../../core/redux/app/Actions";
import { useHistory } from "react-router-dom";
import { isMobile } from "react-device-detect";
import { useClearCache } from "react-clear-cache";

export const SidebarScreen = ({
  collapsed,
  setCollapsed,
  pathname,
  loggedUser,
  company,
}) => {
  const { isLatestVersion, emptyCacheStorage } = useClearCache();
  const dispatch = useDispatch();
  const history = useHistory();

  return (
    <ProSidebar collapsed={collapsed}>
      <SidebarContent>
        {isMobile ? (
          <></>
        ) : (
          <div
            onClick={() => setCollapsed((collapsed) => !collapsed)}
            key={`menu-routes-exit`}
            className={`icon-collapse  ${collapsed ? "collapsed" : ""}`}
          >
            {collapsed ? <BsTextIndentLeft /> : <BsTextIndentRight />}
            {!collapsed ? (
              <div className="text-collapse">Encolher menu</div>
            ) : (
              <div />
            )}
          </div>
        )}
        <div className="logo">
          {collapsed ? (
            <img
              className="sidebar-logo mini"
              src={require("../../assets/images/logoP.png").default}
            />
          ) : (
            <img
              className="sidebar-logo"
              src={require("../../assets/images/web.png").default}
            />
          )}
        </div>
        <Menu iconShape="square">
          {menuRoutes(loggedUser, company)
            .filter((item) => !item.hidden)
            .map((route, index) => (
              <>
                {collapsed ? (
                  <MenuItem
                    onClick={(e) => {
                      dispatch(setData({ navigate: route.path }));
                      history.push(route.path);
                      if (!collapsed) {
                        setCollapsed(true);
                      }
                      if (!isLatestVersion) {
                        emptyCacheStorage();
                      }
                    }}
                    id={route.selector}
                    key={`menu-routes-${index}`}
                    className={route.mobile && "mobile"}
                    icon={route.icon}
                    active={route.path === pathname}
                  >
                    {route.title}
                  </MenuItem>
                ) : route.childs && route.childs.length > 0 ? (
                  <SubMenu
                    icon={route.icon}
                    title={route.title}
                    defaultOpen={true}
                  >
                    {route.childs.map((child) => (
                      <MenuItem
                        onClick={(e) => {
                          dispatch(setData({ navigate: child.path }));
                          history.push(child.path);
                          if (!collapsed) {
                            setCollapsed(true);
                          }
                        }}
                        active={child.path === pathname}
                      >
                        {child.title}
                      </MenuItem>
                    ))}
                  </SubMenu>
                ) : (
                  <MenuItem
                    onClick={(e) => {
                      dispatch(setData({ navigate: route.path }));
                      history.push(route.path);
                      if (!collapsed) {
                        setCollapsed(true);
                      }
                    }}
                    id={route.selector}
                    key={`menu-routes-${index}`}
                    className={route.mobile && "mobile"}
                    icon={route.icon}
                    active={route.path === pathname}
                  >
                    {route.title}
                  </MenuItem>
                )}
              </>
            ))}
        </Menu>
      </SidebarContent>
    </ProSidebar>
  );
};
