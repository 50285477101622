import { useEffect, useState } from "react";
import { BsChevronDown, BsChevronUp, BsGear } from "react-icons/bs";
import { useDispatch, useSelector } from "react-redux";
import { useHistory, useLocation } from "react-router-dom";
import {
  Dropdown,
  DropdownToggle,
  DropdownMenu,
  DropdownItem,
  UncontrolledDropdown,
} from "reactstrap";
import { setData } from "../../core/redux/app/Actions";
import { isMobile } from "react-device-detect";

export const DropDownMenu = ({ collapsed }) => {
  const [dropdownOpen, setDropdownOpen] = useState(false);
  const [isSelected, setIsSelected] = useState(false);
  const toggle = () => {
    if (!collapsed) setDropdownOpen((prevState) => !prevState);
  };
  let history = useHistory();
  let location = useLocation();

  useEffect(() => {
    setIsSelected((e) => !e);
  }, [location]);

  const action = () => {
    toggle();
    history.push("/modules");
  };

  return (
    <UncontrolledDropdown isOpen={dropdownOpen} className="btn-rotate">
      <DropdownToggle
        aria-haspopup={true}
        caret={false}
        color="#000"
        data-toggle="dropdown"
        nav
        onClick={() => action()}
      >
        <div className="modules">
          <div className="icon-and-text">
            <div
              className={
                isSelected || !dropdownOpen
                  ? "icon-container"
                  : "icon-container-open"
              }
            >
              <BsGear
                className={isSelected || !dropdownOpen ? "small" : "small-open"}
              />
            </div>
            {!dropdownOpen ? (
              <h6 className="icon-text">Módulos</h6>
            ) : (
              <h6 className="icon-text-open">Módulos</h6>
            )}
          </div>
          {!dropdownOpen && !collapsed ? (
            <BsChevronUp size={14} color={"white"} />
          ) : (
            <BsChevronDown size={14} color={"#70E000"} />
          )}
        </div>
      </DropdownToggle>
      <DropdownMenu
        className="dropdown-menu-logout"
        aria-labelledby="navbarDropdownMenuLink"
        right
      >
        <DropdownItem
          onClick={() => setDropdownOpen(false)}
          className="drop-hover"
        >
          <h6 className="drop-text">Funilaria e pintura </h6>
        </DropdownItem>
        <DropdownItem
          onClick={() => setDropdownOpen(false)}
          className="drop-hover"
        >
          <h6 className="drop-text">Estética </h6>
        </DropdownItem>
        <DropdownItem
          onClick={() => setDropdownOpen(false)}
          className="drop-hover"
        >
          <h6 className="drop-text">Mecânica</h6>
        </DropdownItem>
      </DropdownMenu>
    </UncontrolledDropdown>
  );
};

export const DropDownTitle = ({ collapsed, options, module }) => {
  const dispatch = useDispatch();
  const field = module === 1 ? "bodyShop" : "aesthetic";
  const value = useSelector((state) => state.app[field]);
  const [dropdownOpen, setDropdownOpen] = useState(false);
  const toggleDropdown = () => {
    setDropdownOpen(!dropdownOpen);
  };
  const showDropdownItems = (id) => {
    dispatch(setData({ [field]: id }));
  };

  return (
    <span
      style={{
        fontSize: "18px",
        fontWeight: 600,
      }}
    >
      {options?.find((item) => item.id === value)?.name}
    </span>
  );
  // : (
  //   <div style={{ display: "flex", flexDirection: "row" }}>
  //     <span
  //       style={{
  //         paddingTop: "8px",
  //         fontSize: "18px",
  //         paddingRight: "15px",
  //         marginBottom: "10px",
  //       }}
  //     >
  //       Funilária e pintura
  //     </span>
  //     <Dropdown
  //       isOpen={dropdownOpen}
  //       toggle={toggleDropdown}
  //       className="Dropdown-items"
  //       data={options}
  //     >
  //       <DropdownToggle caret>
  //         {options?.find((item) => item.id === value)?.name ||
  //           "Selecione uma opção"}
  //       </DropdownToggle>
  //       <DropdownMenu>
  //         {options.map((item) => (
  //           <DropdownItem
  //             key={item.id}
  //             onClick={() => showDropdownItems(item.id, item.name, item.label)}
  //           >
  //             {item.name}
  //           </DropdownItem>
  //         ))}
  //       </DropdownMenu>
  //     </Dropdown>
  //   </div>
  // );
};
