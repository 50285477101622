import React from 'react';
import GridTable from '@nadavshaar/react-grid-table';
import { BsPencilFill } from 'react-icons/bs';
import { useHistory } from 'react-router-dom';
import { inPlaceSort } from 'fast-sort';

const VehiclesScreen = ({ data, onRemove }) => {
    let history = useHistory()

    inPlaceSort(data).by("model");

    return (
      <div className='vehicle-container'>
        <div className='vehicle-body default-table-vehicle gridtable-for-vehicle'>
          <GridTable
            columns={columns(onRemove, history)}
            rows={data}
            enableColumnsReorder={false}
            canReorder={false}
            showSearch={true}
            isPaginated={false}
            showColumnVisibilityManager={false}
            showRowsInformation={false}
            texts={{ noResults: 'Nenhum resultado foi encontrado', search: 'Buscar:' }}
                />
        </div>
      </div>
    )
}

export default VehiclesScreen;

const columns = (onRemove, history) => {

    return [
        {
            id: 1,
            field: 'model',
            label: 'Modelo',
            width: '1fr',
            paddingLeft: '30px!important',
            resizable: false,
            cellRenderer: ({ data }) => <div className='clickable-name' onClick={() => history.push(`/vehicles/details/${data.id}`, data)} >
              {typeof data.model === 'string' && data.model.trim().startsWith('{') && data.model.trim().endsWith('}')
                    ? JSON.parse(data.model)?.label || 'Modelo Desconhecido'
                    : typeof data.model === 'string'
                        ? data.model
                        : 'Modelo Desconhecido'}
            </div>
        },
        {
            id: 2,
            field: 'plate',
            label: 'Placa',
            width: '1fr',
            resizable: false,
            cellRenderer: ({ data }) => <div className='clickable-name plate' onClick={() => history.push(`/vehicles/details/${data.id}`, data)} >
              {data.plate}
            </div>
        },
        {
            id: 3,
            field: 'actions',
            label: 'Ações',
            resizable: false,
            width: '70px',
            cellRenderer: ({ data }) => <ActionCell data={data} onRemove={onRemove} history={history} />,
        },
    ]
}

const ActionCell = ({ data, onRemove, history }) => {
    return <div className='field-actions'>
      <BsPencilFill className='icon-action edit' onClick={() => history.push(`/vehicles/details/${data.id}`, data)} />
      {/* <BsTrashFill className='icon-action trash' onClick={() => onRemove(data)} /> */}
    </div>
}
