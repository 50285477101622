import { appActions } from "./Actions";
import { failure, begin, success } from "../utils/Reducers";

export const initialState = {
  isLoading: false,
  error: null,
};

export default function (state = initialState, action) {
  if (appActions) {
    switch (action.type) {
      case "SET_DATA":
        state = success(state, { ...action.payload });
        break;
      case "SET_MERGE_DATA":
        state = success(state, {
          [action.payload.key]: {
            ...state[action.payload.key],
            ...action.payload.data,
          },
        });
        break;
      case "SET_LOGOUT":
        state = success(initialState, { ...action.payload });
      default:
        break;
    }
  }
  return state;
}
