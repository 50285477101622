import React, { useEffect, useState } from 'react';
import ModalAddParts from '../../../ModalAddParts/ModalAddParts';
import { compareValues } from '../../../../containers/ModalClients/ModalClientsContainer';
import Swal from 'sweetalert2';
import { savePart } from '../../../../core/utils/parts';
import { alertError, alertSuccess } from '../../../../components/Toast';
import { setData } from '../../../../core/redux/app/Actions';
import { useSelector } from 'react-redux';

export const AddParts = ({
  isModalToCreatePartOpen,
  setIsModalToCreatePartOpen,
}) => {
  const [values, setValues] = useState({});
  const [oldValues, setOldValues] = useState({});
  const company = useSelector((state) => state.app.company || {});

  const closeModal = () => {
    if (compareValues(oldValues, values)) {
      Swal.fire({
        title: 'Deseja sair sem salvar?',
        showCancelButton: true,
        showConfirmButton: false,
        showDenyButton: true,
        icon: 'warning',
        text: 'Ao sair, seus dados serão descartados.',
        denyButtonText: `Descartar`,
        cancelButtonText: 'Cancelar',
      }).then((result) => {
        if (result.isDenied) {
          setIsModalToCreatePartOpen(false);
        }
      });
    } else {
      setIsModalToCreatePartOpen(false);
    }
  };

  const onChange = (field, value) => {
    setValues((values) => ({ ...values, [field]: value }));
  };

  const onSave = async () => {
    const extraProps = {};
    if (values && !values.name) {
      alertError('O nome é obrigatório!');
      return;
    }
    if (values && values.images) {
      extraProps['images'] = JSON.stringify(values.images);
    }

    const { error } = await savePart(undefined, {
      company: company.id,
      ...values,
      ...extraProps,
    });
    if (error) {
      alertError('Erro ao salvar a lista, tente novamente mais tarde.');
    } else {
      alertSuccess('Sucesso ao salvar a peça!');
      setIsModalToCreatePartOpen(false);
      dispatch(setData({ reloadAddParts: !reloadAddParts }));
    }
  };

  useEffect(() => {
    setValues({});
  }, [isModalToCreatePartOpen]);

  return (
    isModalToCreatePartOpen && (
      <div className="container-modal-create-part">
        <ModalAddParts
          compareValues={compareValues(oldValues, values)}
          closeModal={closeModal}
          onChange={onChange}
          values={values}
          isNew={true}
          setValues={setValues}
          onSave={onSave}
        />
      </div>
    )
  );
};
