import { LabelInput, TextInput } from "../../components/Input/Input";
import { AiOutlineCheck, AiOutlineClose } from "react-icons/ai";
import { DefaultButton } from "../../components/Button/Button";
import { BsFillTrashFill } from "react-icons/bs";
import { Button } from "reactstrap";
import { isMobile } from "react-device-detect";

const ModalProductionPhase = ({
  auxValues,
  setAuxValues,
  values,
  closeModal,
  isNew,
  onChange,
  onSave,
  compareValues,
  onRemove,
  addChecklistItem,
  subService,
  onDeleteChecklistItem,
}) => {
  return (
    <div className="modal-container">
      <div className="modal-header">
        <div className="header-title">
          <h4 className="modal-title">Etapas do processo</h4>
        </div>
        <div className="header-buttons">
          {compareValues ? (
            <DefaultButton
              className="modal-footer-button"
              label={isMobile ? "" : "Salvar"}
              icon={<AiOutlineCheck className="button-icon" />}
              onClick={onSave}
            />
          ) : (
            <div />
          )}
          <DefaultButton
            className="modal-footer-button cancel"
            label={isMobile ? "" : "Cancelar"}
            icon={<AiOutlineClose className="button-icon-cancel" />}
            onClick={closeModal}
          />
        </div>
      </div>
      <div className="desc-input company">
        <br />
        <div className="bodyshop-title step" style={{ gap: 10 }}>
          {!isNew && (
            <LabelInput
              onChange={(value) => onChange("index", value)}
              className={"small-size-input step"}
              placeholder={""}
              label={""}
              type={"number"}
              value={values.index}
            />
          )}
          <TextInput
            value={values.title}
            placeholder="Adicionar título"
            onChange={(event) =>
              onChange("title", event.target.value.toUpperCase())
            }
            className="item-title"
          />
        </div>
        <br />
        <div className="production-phase-main-upper-div">
          <div className="div-production-description">
            <LabelInput
              className="new-checklist-description"
              value={auxValues.description}
              onChange={(value) =>
                setAuxValues((values) => ({ ...values, description: value }))
              }
              placeholder={"Insira o item"}
              label={"Novo item para o checklist"}
            />
          </div>
          <div style={{ display: "inline-flex" }}>
            <div className="description-add-button-placement">
              <DefaultButton
                className="description-add-button"
                label={"+"}
                onClick={addChecklistItem}
              />
            </div>
          </div>
        </div>
        <div className="lower-div">
          <div
            style={{
              display: "flex",
              flexDirection: "column",
              marginBottom: 20,
            }}
          >
            <text
              style={{
                color: "white",
                fontSize: "14px",
                fontWeight: "500",
                fontFamily: "Poppins, sans-serif",
              }}
            >
              Conferência obrigatória
            </text>
            <text
              style={{
                color: "white",
                fontSize: "10px",
                fontWeight: "500",
                fontFamily: "Poppins, sans-serif",
              }}
            >
              * Ao marcar o checkbox como obrigatório o checklist precisa ser
              preenchido
            </text>
          </div>
          <div className="main-upper-div-for-checklist">
            {subService &&
              subService.map((item) => (
                <div
                  style={{
                    display: "flex",
                    paddingBottom: 10,
                    justifyContent: "space-between",
                    width: "100%",
                  }}
                  className="checklist-items"
                >
                  <div className="checkbox-placement">
                    <input
                      type="checkbox"
                      checked={item.checked}
                      className="checkbox-item"
                      onChange={(value) =>
                        onChange("number", {
                          ...item,
                          checked: value.target.checked,
                        })
                      }
                    />
                    <text
                      style={{
                        color: "white",
                        textTransform: "uppercase",
                        paddingLeft: 10,
                      }}
                    >
                      {" "}
                      {item.description}{" "}
                    </text>
                  </div>
                  <div className="row-input-check">
                    <Button
                      style={{
                        backgroundColor: "transparent",
                        borderRadius: 10,
                        height: 40,
                        width: 50,
                      }}
                      onClick={() => onDeleteChecklistItem(item)}
                    >
                      <BsFillTrashFill className="button-icon-delete" />
                    </Button>
                  </div>
                </div>
              ))}
          </div>
        </div>
      </div>
      <div className="modal-footer-table">
        {values && !values.id ? (
          <div />
        ) : (
          <DefaultButton
            className="modal-footer-button delete"
            label={"Excluir"}
            icon={<BsFillTrashFill className="button-icon-cancel" />}
            onClick={onRemove}
          />
        )}
      </div>
    </div>
  );
};

export default ModalProductionPhase;
