import { FormGroup, Input, Label } from "reactstrap";

const ButtonSwitch = ({ value, onChange, hasLabel = true, disabled }) => {
  return (
    <FormGroup switch>
      <Input
        type="switch"
        checked={value}
        disabled={disabled}
        onClick={() => {
          onChange(!value);
        }}
        style={{
          backgroundColor: value ? "green" : "gray",
          width: "40px",
          height: "25px",
          position: "relative",
          display: "flex",
          border: "none",
        }}
      />
      {hasLabel && (
        <Label className="switch-label" check>
          {value ? "Ativado" : "Desativado"}
        </Label>
      )}
    </FormGroup>
  );
};

export default ButtonSwitch;
