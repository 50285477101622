import React, { useState } from 'react';
import ServiceTypeContainer from '../../containers/ServiceType/ServiceTypeContainer';
import ProductionPhaseContainer from '../../containers/ProductionPhase/ProductionPhaseContainer';
import ValueHourChartContainer from '../../containers/ValueHourChart/ValueHourContainer';
import ServiceContainer from '../../containers/ServiceContainer/ServiceContainer';

const BodyShopScreen = ({ bodyShop }) => {
    return (
      <div className='bodyshop-container'>
        <div className='bodyshop-body default-table-bodyshop'>
          {bodyShop === 1 && <ServiceTypeContainer />}
          {bodyShop === 2 && <ProductionPhaseContainer />}
          {bodyShop === 3 && <ValueHourChartContainer />}
          {bodyShop === 4 && <ServiceContainer />}
        </div>
      </div>
    );
};

export default BodyShopScreen;
