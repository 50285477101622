import React from 'react';
import ProductionPhasesAestheticContainer from '../../containers/ProductionPhaseAesthetic/ProductioPhaseAestheticContainer';
import ServiceAestheticContainer from '../../containers/ServiceAestheticContainer/ServiceAestheticContainer';
import PackAestheticContainer from '../../containers/PackAestheticContainer/PackAestheticContainer';
import { AESTHETIC_MODULES } from '../../core/constants/aesthetic_modules';

const AestheticScreen = ({ aesthetic }) => {
  return (
    <div className="bodyshop-container">
      <div className="bodyshop-body default-table-bodyshop">
        {aesthetic === AESTHETIC_MODULES.PRODUCTION_PHASES && (
          <ProductionPhasesAestheticContainer />
        )}
        {aesthetic === AESTHETIC_MODULES.SERVICES && (
          <ServiceAestheticContainer />
        )}
        {aesthetic === AESTHETIC_MODULES.PACKAGES && <PackAestheticContainer />}
      </div>
    </div>
  );
};

export default AestheticScreen;
