import React from 'react';
import { DefaultButton } from '../../../components/Button/Button';

export const LabelWithPlusButton = (props) => {
  const { setOpen, tabNumber, label } = props;

  return (
    <div {...props} className="budget-label-plus-button-container">
      <text style={{ color: 'white' }}> {label} </text>
      <DefaultButton
        className="add-button"
        label={'+'}
        onClick={() => setOpen(tabNumber)}
      />
    </div>
  );
};
