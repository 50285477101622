import { useEffect, useState } from "react";
import { useSelector } from "react-redux";
import VehiclesScreen from "../../screens/Vehicles/VehiclesScreen";
import { supabase } from "../../supabaseClient";

const VehiclesContainer = ({}) => {
  const [data, setData] = useState([]);
  const reloadVehicles = useSelector(
    (state) => state.app.reloadVehicles || false
  );
  const company = useSelector((state) => state.app.company || {});
  const user = useSelector((state) => state.app.user || {});

  useEffect(() => {
    loadData();
  }, [reloadVehicles, company]);

  const loadData = async () => {
    let { data, error } = await supabase
      .from("Vehicle")
      .select("*")
      .is("deleted_at", null)
      .eq("company", company.id);
    if (!error) {
      setData(data);
    }
  };

  return (
    <VehiclesScreen
      data={data}
      user={user}
      // onRemove={item => swalRemove({ withError: false, functionConfirmRemove: () => onRemove(item) })}
    />
  );
};

export default VehiclesContainer;
