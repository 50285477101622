import React from 'react';
import GridTable from '@nadavshaar/react-grid-table';

const ServiceAestheticScreen = ({ columns, rows }) => {
  return (
    <div className="bodyshop-container">
      <div className="bodyshop-body default-table-bodyshop gridtable-for-service">
        <GridTable
          columns={columns}
          rows={rows}
          enableColumnsReorder={false}
          canReorder={false}
          showSearch={true}
          isPaginated={false}
          showColumnVisibilityManager={false}
          showRowsInformation={false}
          texts={{
            noResults: 'Nenhum resultado foi encontrado',
            search: 'Buscar:',
          }}
        />
      </div>
    </div>
  );
};

export default ServiceAestheticScreen;
