import React from "react";
import { isMobile } from "react-device-detect";

const SearchHeader = ({ filter, setFilter }) => {

  return (
    <div className="header-mobile">
      <div style={{ display: "flex", flexDirection: "row" }}>
        <div style={{ display: "flex", width: "100%", paddingRight: "10px" }}>
          {isMobile ? (
            <span className="rgt-search-icon custom-header-icon">⚲</span>
          ) : (
            <label className="rgt-search-label" htmlFor="rgt-search">
              <span className="rgt-search-icon custom-header-icon">⚲</span>
              Buscar:
            </label>
          )}
          <input
            className="rgt-search-input custom-header-input"
            type="search"
            placeholder="Digite para Pesquisar"
            value={filter?.search}
            onChange={(e) =>
              setFilter((filter) => ({ ...filter, search: e.target.value }))
            }
            style={{ backgroundColor: "#D9DBE9", color: "black" }}
          />
        </div>
      </div>
    </div>
  );
};

export default SearchHeader;
