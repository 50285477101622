import { useEffect, useState } from 'react';
import { useDispatch, useSelector } from 'react-redux';
import { useHistory, useLocation, useParams } from 'react-router-dom';
import { alertError, alertSuccess } from '../../components/Toast';
import { setData } from '../../core/redux/app/Actions';
import { saveVehicle } from '../../core/utils/vehicles';
import ModalVehicles from '../../screens/ModalVehicles/ModalVehicles';
import { supabase } from '../../supabaseClient';
import { compareValues } from '../ModalClients/ModalClientsContainer';
import Swal from 'sweetalert2';
import { loadClient } from '../../core/utils/client';

const ModalVehiclesContainer = (props) => {
  const dispatch = useDispatch();
  const history = useHistory();
  const location = useLocation();
  const params = useParams();
  const id = location.pathname.split('/')[3];
  const isNew = params.id === '0';
  const user = useSelector((state) => state.app.user || {});
  const reloadVehicles = useSelector(
    (state) => state.app.reloadVehicles || false
  );
  const company = useSelector((state) => state.app.company || {});
  const [values, setValues] = useState({});
  const [oldValues, setOldValues] = useState({});
  const [responsible, setResponsible] = useState([]);
  const [loadingPlate, setLoadingPlate] = useState(false);
  const [brandOptions, setBrandOptions] = useState([]);
  const [modelOptions, setModelOptions] = useState([]);

  const loadData = async () => {
    let { data, error } = await supabase
      .from('Vehicle')
      .select('*')
      .eq('id', id)
      .is('deleted_at', null);

    if (!error) {
      const item = data[0];
      setValues(item);
      setOldValues(item);
    }
  };

  useEffect(() => {
    if (id !== '0') {
      loadData();
    }
  }, [id]);

  useEffect(() => {
    if (brandOptions.length > 0 && values?.brand && !values?.brand?.label) {
      const selectedBrand =
        brandOptions?.find((brand) => brand.nome === values?.brand) || {};
      setValues((old) => ({
        ...old,
        brand: {
          value: selectedBrand.codigo || old.brand,
          label: selectedBrand.nome || old.brand,
        },
      }));
    }
    if (values?.brand?.label && values?.model && !values?.model?.label) {
      const selectedModel =
        modelOptions?.find((model) => model.nome === values.model) || {};
      setValues((old) => ({
        ...old,
        model: {
          value: selectedModel?.codigo || old?.model,
          label: selectedModel?.nome || old?.model,
        },
      }));
    }
  }, [values, brandOptions, modelOptions]);

  const onBackButtonEvent = (e) => {
    e.preventDefault();
    closeModal();
  };

  useEffect(() => {
    window.history.pushState(null, null, window.location.pathname);
    window.addEventListener('popstate', onBackButtonEvent);
    return () => {
      window.removeEventListener('popstate', onBackButtonEvent);
    };
  }, [values]);

  const closeModal = () => {
    if (compareValues(oldValues, values)) {
      Swal.fire({
        title: 'Deseja sair sem salvar?',
        showCancelButton: true,
        showConfirmButton: false,
        showDenyButton: true,
        icon: 'warning',
        text: 'Ao sair, seus dados serão descartados.',
        denyButtonText: `Descartar`,
        cancelButtonText: 'Cancelar',
      }).then((result) => {
        if (result.isDenied) {
          history.push('/vehicles');
        }
      });
    } else {
      history.push('/vehicles');
    }
  };

  useEffect(() => {
    loadResponsible();
    loadBrand();
  }, [company.id]);

  useEffect(() => {
    const createdBrand = brandOptions.find(
      (item) => item.id === values?.brand?.value
    );
    if (!createdBrand) {
      loadModel();
    }
  }, [values?.brand?.value]);

  const loadBrand = async () => {
    const response = await fetch(
      'https://parallelum.com.br/fipe/api/v1/carros/marcas',
      {
        method: 'GET',
        redirect: 'follow',
      }
    )
      .then((response) => response.text())
      .then((result) => JSON.parse(result))
      .catch((error) => console.log('error', error));
    setBrandOptions(response);
  };

  const loadModel = async () => {
    if (values?.brand?.value && values?.brand?.value !== values?.brand?.label) {
      const response = await fetch(
        `https://parallelum.com.br/fipe/api/v1/carros/marcas/${values?.brand?.value}/modelos`,
        {
          method: 'GET',
          redirect: 'follow',
        }
      )
        .then((response) => response.text())
        .then((result) => JSON.parse(result))
        .catch((error) => console.log('error', error));
      if (!response?.error) {
        setModelOptions(response.modelos);
      }
    }
  };

  const loadResponsible = async () => {
    let { data, error } = await supabase
      .from('Client')
      .select('*')
      .eq('id_company', company.id)
      .is('deleted_at', null);

    if (!error) {
      setResponsible(
        data.map((item) => ({ label: item.name, value: item.id }))
      );
    }
  };

  const createOption = async (name, field) => {
    setValues((values) => ({
      ...values,
      [field]: { label: name, value: '0' },
    }));
  };

  const onChange = (field, value) => {
    setValues((values) => ({ ...values, [field]: value }));
  };

  const onSave = async () => {
    const existedPlate = await getExistedPlate(
      values.plate,
      company.id,
      values.id
    );

    try {
      const extraData = {};

      if (values && !values.brand) {
        alertError('Marca do veículo é obrigatória.');
        return;
      } else {
        if (values?.brand?.label) {
          extraData['brand'] = values?.brand?.label;
        }
      }

      if (values && !values.model) {
        alertError('Modelo do veículo é obrigatório.');
        return;
      } else {
        if (values?.model?.label) {
          extraData['model'] = values?.model?.label;
        }
      }

      if (values && !values.plate) {
        alertError('Placa do veículo é obrigatória.');
        return;
      }

      if (existedPlate?.length > 0) {
        alertError('Placa já cadastrada.');
        return;
      }

      const save = await saveVehicle(values.id, {
        company: company.id,
        ...values,
        ...extraData,
      });
      alertSuccess('Sucesso ao salvar o veículo!');
      history.push('/vehicles');
      dispatch(setData({ reloadVehicles: !reloadVehicles }));
    } catch (error) {
      console.log({ error });
      alertError('Erro ao salvar o veículo, tente novamente mais tarde.');
    }
  };

  const changePlate = (oldPlate) => {
    const plate = oldPlate.toUpperCase();
    onChange('plate', plate);
    if (plate !== oldValues?.plate) {
      if (
        plate &&
        plate.length > 6 &&
        /[A-Z]{3}-[0-9][0-9A-Z][0-9]{2}/.test(plate)
      ) {
        setLoadingPlate(true);
        fetch(`https://cluster.apigratis.com/api/v2/vehicles/dados`, {
          method: 'POST',
          body: JSON.stringify({ placa: plate.replace('-', '') }),
          headers: {
            'Content-Type': 'application/json',
            Authorization: `Bearer ${process.env.REACT_APP_BEARER_API_BRASIL}`,
            DeviceToken: process.env.REACT_APP_DEVICE_API_BRASIL,
          },
        })
          .then((response) => {
            response.json().then((data) => {
              if (
                data.response.message ===
                `Veículo ${plate.replace(
                  '-',
                  ''
                )} não foi encontrado no banco de dados, nem na redundância!`
              ) {
                alertError(
                  'Erro ao achar o veículo, por favor continue o cadastro manualmente.'
                );
                setLoadingPlate(false);
                return;
              }
              const {
                MODELO,
                MARCA,
                ano,
                anoModelo,
                combustivel,
                cor,
                extra = {},
              } = data.response;
              const { chassi, motor } = extra;
              setValues((values) => ({
                ...values,
                plate,
                model: MODELO?.includes(MARCA)
                  ? MODELO.replace(MARCA, '').replace('/', '')
                  : MODELO,
                yearmodel: anoModelo,
                chassi: chassi,
                engine: motor,
                color: cor,
                fuel: combustivel,
                brand: MARCA,
                year: ano,
              }));
              setLoadingPlate(false);
            });
          })
          .catch((err) => {
            setLoadingPlate(false);
          });
      }
    }
  };

  const onRemove = async () => {
    const confirmation = await Swal.fire({
      title: 'Deseja mesmo excluir?',
      showCancelButton: true,
      showConfirmButton: false,
      showDenyButton: true,
      icon: 'warning',
      text: 'Seus dados serão completamente perdidos!',
      denyButtonText: 'Excluir',
      cancelButtonText: 'Cancelar',
    });

    if (confirmation.isDenied) {
      if (values && values.id) {
        const { data, error } = await supabase
          .from('Vehicle')
          .update({ deleted_at: new Date(), deleted_by: user.id })
          .eq('id', values.id);

        if (!error) {
          alertSuccess('Veículo removido com sucesso!');
          history.push('/vehicles');
          dispatch(setData({ reloadVehicles: !reloadVehicles }));
        } else {
          alertError('Erro ao remover o veículo, tente novamente mais tarde');
        }
      } else {
        alertError('Erro ao remover o veículo, tente novamente mais tarde');
      }
    } else {
      history.push('/vehicles');
    }
  };

  return (
    <ModalVehicles
      compareValues={compareValues(oldValues, values)}
      responsible={responsible}
      closeModal={closeModal}
      onRemove={onRemove}
      onChange={onChange}
      onSave={onSave}
      values={values}
      isNew={isNew}
      changePlate={changePlate}
      loadingPlate={loadingPlate}
      brandOptions={brandOptions}
      modelOptions={modelOptions}
      createOption={createOption}
    />
  );
};

export default ModalVehiclesContainer;

const getExistedPlate = async (plate, companyId, vehicleId) => {
  try {
    const clients = await loadClient(companyId);
    const clientIds = clients.map((client) => client.id);

    const { data, error } = await supabase
      .from('Vehicle')
      .select('*')
      .in('responsible', clientIds)
      .eq('plate', plate)
      .is('deleted_at', null);

    if (error) {
      throw error;
    }
    const filteredData = data.filter((vehicle) => vehicle.id !== vehicleId);

    return filteredData;
  } catch (error) {
    console.error('Error fetching existing plate:', error.message);
    return [];
  }
};
