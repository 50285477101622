import { useState, useEffect } from 'react';
import { useSelector } from 'react-redux';
import { ModalBody, FormGroup } from 'reactstrap';
import Modal from 'react-modal';
import { supabase } from '../../supabaseClient';
import {
  DefaultInput,
  HourInput,
  MoneyInput,
} from '../../components/Input/Input';
import { AiOutlineCheck, AiOutlineClose } from 'react-icons/ai';
import { DefaultButton } from '../../components/Button/Button';
import GridTable from '@nadavshaar/react-grid-table';
import { isMobile } from 'react-device-detect';
import { inPlaceSort } from 'fast-sort';
import { AddAeService } from '../ModalBudget/components/TabsContent/AddAeService';

const BudgetAddAeServiceScreen = ({
  setOpen,
  isOpen,
  budget,
  values,
  setValues,
  setAddBudget,
  setServiceType,
  addedServices,
  service,
}) => {
  const company = useSelector((state) => state.app.company || {});
  const [valuesBasedOnSize, setValuesBasedOnSize] = useState([]);
  const [data, setData] = useState([]);
  const [search, setSearch] = useState('');
  const [isModalToCreateServiceOpen, setIsModalToCreateServiceOpen] =
    useState(false);

  useEffect(() => {
    decidePriceBasedOnSize(values, data);
  }, [values, data]);

  const decidePriceBasedOnSize = async (values, data) => {
    if (values.size) {
      const filteredValuesData = data.map((item) => {
        return {
          value: item.hasOwnProperty('p_value') ? item.p_value : null,
          time: item.hasOwnProperty('p_time') ? item.p_time : null,
          title: item.title,
          id: item.id,
        };
      });
      const newValuesBasedOnSize = filteredValuesData.filter((item) =>
        Object.values(item).every((value) => value !== null)
      );
      setValuesBasedOnSize(newValuesBasedOnSize);
    }
  };

  const getServiceTable = async (data) => {
    const dataFormatter =
      values.price && values.price.service_types
        ? JSON.parse(values.price.service_types)
        : [];

    const newData = data.map((item) => ({
      ...item,
      unitaryValue: dataFormatter?.find((type) => type.id === item.service_type)
        ?.value,
    }));

    setData(newData);
  };

  useEffect(() => {
    getServiceTable(data);
  }, [budget]);

  useEffect(() => {
    loadServiceType();
  }, [company]);

  const loadServiceType = async () => {
    let { data, error } = await supabase
      .from('ServiceTypes')
      .select('*')
      .eq('id_company', company.id)
      .is('deleted_at', null);
    if (!error) {
      setServiceType(
        data.map((item) => ({ ...item, label: item.title, value: item.id }))
      );
    }
  };

  useEffect(() => {
    loadServices();
  }, [company, isOpen, isModalToCreateServiceOpen]);

  const loadServices = async () => {
    let { data, error } = await supabase
      .from('AestheticServices')
      .select(
        `
      *,
      PackAestheticServices!id_service_pack(*)
    `
      )
      .eq('id_company', company.id)
      .is('deleted_at', null);
    if (!error) {
      data?.forEach((d) => {
        d.services = data.filter((dat) =>
          d?.PackAestheticServices?.map((p) => p.id_service).includes(dat.id)
        );
      });
      getServiceTable(data);
    }
  };

  const filteredData = (data, search, budget) => {
    return data.filter((item) => doFilter(item, search, budget));
  };

  const doFilter = (item, search, budget) => {
    // Verifica se o título do item está presente em algum item do array 'service'
    const isMatchingTitle =
      service?.length > 0 &&
      service.some((serviceItem) => serviceItem.title === item.title);

    let isSearch = true;
    if (search) {
      isSearch = item.title.toLowerCase().includes(search.toLowerCase());
    }

    let isBudget = true;
    if (budget !== null && budget.length > 0) {
      // isBudget = item.service_type === budget;
      isBudget =
        budget.filter((value) => value.id === item.service_type).length > 0;
    }

    // Retorna false se o título do item corresponder a algum item em 'service'
    return !isMatchingTitle && isSearch && isBudget;
  };

  const onSave = async () => {
    const filteredData = data?.filter((item) => item.check);
    const setNewData = filteredData.map((item) => ({
      ...item,
      unitaryValue: item.unitaryValue || item.p_value,
      quantity: item.quantity || 1,
    }));
    setValues(setNewData);
    setData([]);
    setSearch('');
    setAddBudget(null);
    setOpen((open) => !open);
  };

  const closeModal = async () => {
    setData([]);
    setSearch('');
    setAddBudget(null);
    setOpen((open) => !open);
  };

  const changeCheck = (item) => {
    setData((values) =>
      values.map((verify) => {
        const check = !verify.check ? true : false;
        return verify.id === item.id ? { ...verify, check } : { ...verify };
      })
    );
  };

  const changeAllChecked = (isChecked) => {
    setData((data) => {
      const filteredData = data.filter(
        (item) => !service?.some((service) => service.title === item.title)
        // &&
        //   !item.check
      );
      return filteredData.map((item) => ({
        ...item,
        check: isChecked,
      }));
    });
  };

  const changeValue = (field, data, value, valuesBasedOnSize) => {
    setData((array) =>
      array.map((item) => {
        if (item.id === data.id) {
          let fixedPrice =
            item.unitaryValue !== undefined
              ? item.unitaryValue
              : valuesBasedOnSize.find((item) => item.id === data.id)?.value ||
                (values.price != 0
                  ? values.price
                  : valuesBasedOnSize.find((item) => item.id === data.id)
                      ?.value);

          const sanitizedFixedPrice =
            fixedPrice !== undefined && fixedPrice !== null && fixedPrice !== 0
              ? parseFloat(
                  fixedPrice
                    .toString()
                    .replace(/[^\d.,]/g, '')
                    .replace(',', '.')
                )
              : 0;

          let newValue;

          if (field === 'time' && value !== undefined) {
            newValue = calculateTotalValue(value, sanitizedFixedPrice);
          } else if (field === 'unitaryValue' && data.time !== undefined) {
            newValue = calculateTotalValue(data.time, sanitizedFixedPrice);
          } else {
            newValue = 0;
          }

          return {
            ...item,
            unitaryValue: sanitizedFixedPrice,
            [field]: value,
            value: newValue,
          };
        } else {
          return item;
        }
      })
    );
  };

  const calculateTotalValue = (hours, unitaryValue) => {
    if (
      typeof hours !== 'string' ||
      typeof unitaryValue !== 'number' ||
      isNaN(unitaryValue) ||
      unitaryValue < 0
    ) {
      return 0;
    }

    const [hoursInt, minutesDecimal] = hours.split(':');
    const totalMinutes = parseInt(hoursInt, 10) * 60;
    const realMinutes = totalMinutes + parseFloat(minutesDecimal);

    if (isNaN(realMinutes) || isNaN(unitaryValue) || unitaryValue < 0) {
      return 0;
    }

    const total = realMinutes * (unitaryValue / 60);

    return total.toFixed(3);
  };

  const serviceChecklist = filteredData(data, search, budget).filter((item) => {
    const filteredServices = addedServices?.find(
      (row) => item?.title === row.title
    );
    return filteredServices?.title !== item.title;
  });

  const columns = [
    {
      id: 'checkbox',
      field: 'checkbox',
      label: '',
      resizable: false,
      width: '60px',
      headerCellRenderer: ({ data }) => {
        return (
          <div className="field-actions-budget">
            {serviceChecklist.length > 0 ? (
              <input
                type="checkbox"
                checked={data?.check}
                className="checkbox-item"
                onChange={(event) => changeAllChecked(event.target.checked)}
              />
            ) : (
              <></>
            )}
          </div>
        );
      },
      cellRenderer: ({ data }) => {
        return (
          <div className="field-actions-budget">
            <input
              type="checkbox"
              checked={data?.check}
              className="checkbox-item"
              onChange={(event) => changeCheck(data)}
            />
          </div>
        );
      },
    },
    {
      id: 2,
      field: 'title',
      label: 'Serviço',
      width: '1fr',
      className: 'field-description',
      resizable: false,
      cellRenderer: ({ data }) => (
        <div>
          <div>{data.title}</div>
          <div style={{ display: 'none' }}>
            <div>
              <div className="field-time-budget gridtable-hour-add-service">
                <HourInput
                  onChange={(value) =>
                    changeValue('time', data, value, valuesBasedOnSize)
                  }
                  type="number"
                  placeholder={'Hora'}
                  value={
                    valuesBasedOnSize.find((item) => item.id === data.id)?.time
                  }
                />
              </div>
              <div className="field-actions-budget gridtable-value-add-service">
                <MoneyInput
                  onChange={(value) =>
                    changeValue('unitaryValue', data, value, valuesBasedOnSize)
                  }
                  type="number"
                  placeholder={'Valor unit'}
                  value={
                    data.unitaryValue
                      ? data.unitaryValue
                      : typeof values.price === 'string'
                      ? values.price
                      : valuesBasedOnSize.find((item) => item.id === data.id)
                          ?.value
                  }
                />
              </div>
              <div className="field-actions-budget gridtable-total-add-service">
                <MoneyInput
                  disabled={true}
                  onChange={(value) =>
                    changeValue('value', data, value, valuesBasedOnSize)
                  }
                  type="number"
                  value={isNaN(data.value) ? 'R$0' : data.value}
                />
              </div>
            </div>
          </div>
        </div>
      ),
    },
  ];

  inPlaceSort(serviceChecklist).by('title');

  return (
    <Modal
      onRequestClose={closeModal}
      className={'prospera-modal'}
      isOpen={isOpen}
    >
      <input style={{ visibility: 'hidden', position: 'absolute' }} />
      <ModalBody className="modal-body">
        {isModalToCreateServiceOpen ? (
          <AddAeService
            isModalToCreateServiceOpen={isModalToCreateServiceOpen}
            setIsModalToCreateServiceOpen={setIsModalToCreateServiceOpen}
          />
        ) : (
          <div
            className="modal-container add-service-modal"
            // style={{ overflowY: "auto !important" }}
          >
            <div className="modal-header" style={{ paddingBottom: 10 }}>
              <DefaultButton
                className="modal-footer-button margin-0"
                label={isMobile ? '' : 'Salvar'}
                icon={<AiOutlineCheck className="button-icon" />}
                onClick={onSave}
              />
              <DefaultButton
                className="modal-footer-button cancel"
                label={isMobile ? '' : 'Cancelar'}
                icon={<AiOutlineClose className="button-icon-cancel" />}
                onClick={closeModal}
              />
            </div>
            <div
              style={{
                paddingTop: '15px',
                paddingLeft: 5,
                display: 'flex',
                justifyContent: 'space-between',
                alignItems: 'center',
              }}
            >
              <text
                style={{
                  color: 'white',
                  fontStyle: 'normal',
                  fontFamily: 'poppins',
                  fontSize: '14px',
                  fontWeight: '500',
                  marginBottom: '0.5rem',
                  margin: '5px 0px 0px 5px',
                }}
              >
                Serviços
              </text>

              <DefaultButton
                className="add-button"
                label={'Cadastrar Serviço'}
                onClick={() => setIsModalToCreateServiceOpen(true)}
              />
            </div>

            <div>
              <div>
                <FormGroup className={'field-actions-budget'}>
                  <DefaultInput
                    placeholder={'Buscar serviço'}
                    typeChange={2}
                    onChange={(e) => {
                      setSearch(e);
                    }}
                  />
                </FormGroup>
              </div>

              <div className="bodyshop-body modal-budget modal-budget-gridtable add-gridtable-service">
                <GridTable
                  columns={columns}
                  rows={serviceChecklist}
                  showSearch={false}
                  canReorder={false}
                  isPaginated={false}
                  enableColumnsReorder={false}
                  showColumnVisibilityManager={false}
                  showRowsInformation={false}
                  texts={{ noResults: 'Nenhum resultado foi encontrado' }}
                />
              </div>
            </div>
          </div>
        )}
      </ModalBody>
    </Modal>
  );
};

export default BudgetAddAeServiceScreen;
