import React from 'react';
import { BUDGET_TABS } from '../../utils/enum';
import Selector from '../../../../components/Selector';
import './styles.scss';
import { Parts } from './Parts';
import { BodyshopServices } from './BodyshopServices';
import { AestheticsServices } from './AestheticsServices';

export const Tables = (props) => {
  const {
    budget,
    showBudgetItems,
    isOpen,
    setOpen,
    onRemovePiece,
    changePartsValue,
    openEditModal,
    parts,
    values,
    onChange,
    setIsntLinearHour,
    isntLinearHour,
    hvTable,
    onRemoveService,
    changeValue,
    bodyshopService,
    onRemoveAeService,
    aeService,
  } = props;

  if (budget !== BUDGET_TABS.TABLES) {
    return;
  }
  return (
    <div className="mb-2">
      <div className="tables-tabs-wrapper">
        <Selector
          handleClick={() => showBudgetItems(1)}
          buttonClass={budget === 1 ? 'highlighted' : ''}
          id={'Detalhes'}
          name={'Detalhes'}
        />
        <Selector
          handleClick={() => showBudgetItems(4)}
          buttonClass={budget === 4 ? 'highlighted' : ''}
          id={'Tabelas'}
          name={'Tabelas'}
        />
      </div>

      <div className="budget-tables-container">
        <div className="budget-tables-borders">
          <Parts
            budget={BUDGET_TABS.PARTS}
            isOpen={isOpen}
            setOpen={setOpen}
            onRemovePiece={onRemovePiece}
            changePartsValue={changePartsValue}
            openEditModal={openEditModal}
            parts={parts}
          />
        </div>

        <div className="budget-tables-borders">
          <AestheticsServices
            budget={BUDGET_TABS.AESTHETICS}
            setOpen={setOpen}
            onRemoveAeService={onRemoveAeService}
            values={values}
            changeValue={changeValue}
            aeService={aeService}
          />
        </div>

        <div className="budget-tables-borders">
          <BodyshopServices
            budget={BUDGET_TABS.BODYSHOP}
            values={values}
            onChange={onChange}
            isntLinearHour={isntLinearHour}
            hvTable={hvTable}
            setIsntLinearHour={setIsntLinearHour}
            setOpen={setOpen}
            bodyshopService={bodyshopService}
            onRemoveService={onRemoveService}
            changeValue={changeValue}
          />
        </div>
      </div>
    </div>
  );
};
