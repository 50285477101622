import React, { useState, useEffect } from "react"
import { useDispatch, useSelector } from 'react-redux';
import { useHistory } from 'react-router-dom/cjs/react-router-dom.min';
import ProductionPhasesScreen from "../../screens/ProductionPhases/ProductionPhasesScreen"
import { BsPencilFill } from "react-icons/bs";
import { BiBrush, BiReset } from "react-icons/bi";
import { FiScissors } from "react-icons/fi";
import { supabase } from '../../supabaseClient';
import { sort } from 'fast-sort';

const ProductionPhaseContainer = ({ }) => {
    const [data, setData] = useState([])
    const company = useSelector(state => state.app.company || {})
    const reloadProductionPhase = useSelector(state => state.app.reloadProductionPhase || false)

    const columns = [
        {
            id: 1,
            field: 'additionalColumn',
            label: 'Ordem',
            width: '100px',
            pinned: true,
            resizable: false,
            cellRenderer: ({ data, rowIndex }) => {
                return <div style={{ paddingLeft: '40px' }}>{data.index || rowIndex}</div>;
            }
        },
        {
            id: 2,
            field: 'title',
            label: 'Etapas do processo',
            width: '1fr',
            paddingLeft: '30px !important',
            resizable: false,
        },
        {
            id: 3,
            field: 'actions',
            label: 'Ações',
            pinned: true,
            resizable: false,
            width: '70px',
            cellRenderer: (row) => {
                return <ActionCell data={{ ...row.data, index: row.data.index || row.rowIndex }} />
            },
        },
    ];

    const ActionCell = ({ data }) => {
        let history = useHistory();
        return (
          <div className='field-actions'>
            <BsPencilFill className='icon-action edit' onClick={() => { history.push(`/bodyshop/details/${data.id}`, data) }} />
          </div>
        );
    };

    useEffect(() => {
        loadData()
    }, [company, reloadProductionPhase])

    const loadData = async () => {
        let { data, error } = await supabase
            .from('ProcSteps')
            .select('*')
            .eq("id_company", company.id)
            .is('deleted_at', null)
        if (!error) {
            const ordenateData = sort(data).asc(u => u.index)
            setData(ordenateData)
        }
    }

    function obterIcone(serviceType) {
        const icones = {
            paintjob: <BiBrush className='body-shop-icons' />,
            taphestry: <FiScissors className='body-shop-icons' />,
            recover: <BiReset className='body-shop-icons' />,
        };

        return icones[serviceType] || null;
    }

    return (
      <ProductionPhasesScreen
        ActionCel={ActionCell}
        obterIcone={obterIcone}
        columns={columns}
        rows={data}
        />
    )

}

export default ProductionPhaseContainer