import { useState } from "react";

export const useSidebarControls = () => {
    const [collapsed, setCollap] = useState(true)

    const setCollapsed = () => {
        setCollap(collapsed => !collapsed)
    }

    const expand = () => {
        setCollap(false)
    }

    const collapse = () => {
        setCollap(true)
    }

    return {
        collapsed, setCollapsed, expand, collapse
    }
}

// export const useSidebarControls = () => {
//     const dispatch = useDispatch();
//     const collapsed = useSelector(state => state.login.collapsed);

//     const setCollapsed = () => {
//         dispatch(setLoginData({ collapsed: !collapsed }))
//     }

//     const expand = () => {
//         dispatch(setLoginData({ collapsed: false }))
//     }

//     const collapse = () => {
//         dispatch(setLoginData({ collapsed: true }))
//     }

//     return {
//         collapsed, setCollapsed, expand, collapse
//     }
// }