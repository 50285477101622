import React, { useState, useEffect } from "react";
import { useSelector } from "react-redux";
import { useHistory } from "react-router-dom/cjs/react-router-dom.min";
import { BsPencilFill } from "react-icons/bs";
import { supabase } from "../../supabaseClient";
import PartsScreen from "../../screens/Parts/PartsScreen";

const PartsContainer = ({}) => {
  const [data, setData] = useState([]);
  const [filter, setFilter] = useState({});
  const company = useSelector((state) => state.app.company || {});
  const reloadAddParts = useSelector(
    (state) => state.app.reloadAddParts || false
  );

  const columns = [
    {
      id: 1,
      field: "name",
      label: "Nome",
      width: "1fr",
      paddingLeft: "30px !important",
      resizable: false,
    },
    {
      id: 2,
      field: "actions",
      label: "Ações",
      pinned: true,
      resizable: false,
      width: "70px",
      cellRenderer: ({ data }) => <ActionCell data={data} />,
    },
  ];

  const ActionCell = ({ data }) => {
    let history = useHistory();
    return (
      <div className="field-actions">
        <BsPencilFill
          className="icon-action edit"
          onClick={() => history.push(`/parts/details/${data.id}`, data)}
        />
      </div>
    );
  };

  useEffect(() => {
    loadData();
  }, [company, reloadAddParts]);

  const loadData = async () => {
    let { data, error } = await supabase
      .from("Part")
      .select("*")
      .eq("company", company.id)
      .is("deleted_at", null);
    if (!error) {
      setData(data);
    }
  };

  return (
    <PartsScreen
      ActionCel={ActionCell}
      columns={columns}
      rows={filteredData(data, filter)}
      setFilter={setFilter}
    />
  );
};

export default PartsContainer;

  const filteredData = (data, filter, splitted) => {
    return data.filter((item) => doFilter(item, filter, splitted));
  };

  const doFilter = (item, filter, xD) => {
    let isSearch = true;
    if (filter && filter.search) {
      isSearch = 
      item?.name?.toLowerCase().includes(filter.search.toLowerCase()) ||
      item?.synonymous?.toLowerCase().includes(filter.search.toLowerCase());
    }

    return isSearch;
  };
