import { supabase } from "../../supabaseClient"

export const saveClient = async (id = undefined, item) => {

    const person = item && item.person && item.person.value ? item.person.value : item.person ? item.person : null
    if (id) {
        return await supabase
            .from('Client')
            .update({ ...item, person })
            .match({ id })
    } else {
        return await supabase
            .from('Client')
            .insert([{ ...item, person }])
    }
}

export const loadClient = async (idCompany) => {

    let { data, error } = await supabase
        .from('Client')
        .select('*')
        .eq("id_company", idCompany)
        .is('deleted_at', null)

    if (!error) {
        return data
    }
}

export const saveOrigin = async (item) => {
    const id = item && item.id || undefined
    if (id) {
        return await supabase
            .from('Origin')
            .update({ ...item })
            .match({ id })
    } else {
        return await supabase
            .from('Origin')
            .insert([{ ...item }])
    }
}

export const loadOrigins = async (idCompany) => {
    var allItems = []

    let withoutCompany = await supabase
        .from('Origin')
        .select('*')
        .is("company", null)
        .is('deleted_at', null)
    if (withoutCompany && !withoutCompany.error) {
        allItems = allItems.concat(withoutCompany.data)
    }

    let withCompany = await supabase
        .from('Origin')
        .select('*')
        .eq("company", idCompany)
        .is('deleted_at', null)
    if (withCompany && !withCompany.error) {
        allItems = allItems.concat(withCompany.data)
    }

    return allItems
}