import { useState } from 'react';
import { supabase } from '../../../supabaseClient';

export const useAestheticPackages = ({ packageId }) => {
  const [values, setValues] = useState({});
  const [oldValues, setOldValues] = useState({});

  const calcOriginValue = (item) => {
    const finalValue = Number((item?.p_value ?? '0').replace(',', '.'));
    const percentage = Number(
      (item?.discount_percentage + '' ?? '0').replace(',', '.')
    );
    return (
      (finalValue / (1 - percentage / 100) - finalValue).toFixed(2) || undefined
    );
  };

  const calcPercentValue = (item) => {
    const discount = Number((item.discount ?? '0').replace(',', '.'));
    const currentValue = Number((item.p_value ?? '0').replace(',', '.'));
    return (
      ((discount / (discount + currentValue)) * 100).toFixed(2) || undefined
    );
  };

  const loadData = async () => {
    try {
      const [serviceResult, packResult] = await Promise.all([
        supabase
          .from('AestheticServices')
          .select('*')
          .eq('id', packageId)
          .is('deleted_at', null),

        supabase
          .from('PackAestheticServices')
          .select('id_service')
          .eq('id_service_pack', packageId),
      ]);

      if (serviceResult.error || packResult.error) {
        console.error(serviceResult.error || packResult.error);
        return;
      }

      const item = serviceResult.data[0];
      const relatedServiceIds = packResult.data.map((pack) => pack.id_service);

      let { data: relatedServices, error: relatedServicesError } =
        await supabase
          .from('AestheticServices')
          .select('*')
          .in('id', relatedServiceIds)
          .is('deleted_at', null);

      if (relatedServicesError) {
        console.error(relatedServicesError);
        return;
      }

      const obj = {
        ...item,
        finalValue: item.p_value,
        discount_temp: item.discount_percentage && calcOriginValue(item),
        discount_percentage_temp: item.discount && calcPercentValue(item),
        relatedServices,
      };
      setValues(obj);
      setOldValues(obj);
    } catch (error) {
      console.error('Erro ao carregar dados:', error);
    }
  };

  return { loadData, values, oldValues, setValues };
};
