import React from 'react';
import AestheticScreen from '../../screens/Aesthetic/AestheticScreen.jsx';
import { useSelector } from 'react-redux';

const AestheticContainer = () => {
  const aesthetic = useSelector((state) => state.app.aesthetic || 1);

  return <AestheticScreen aesthetic={aesthetic} />;
};

export default AestheticContainer;
