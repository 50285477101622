import Swal from "sweetalert2";

const swalMixinConfirmCancel = Swal.mixin({
  customClass: {
    confirmButton: "btn btn-swal danger",
    denyButton: "btn btn-swal danger",
    cancelButton: "btn btn-swal cancel-button",
  },
  buttonsStyling: false,
});

const swalMixinConfirm = Swal.mixin({
  customClass: {
    confirmButton: "btn btn-swal confirm",
  },
  buttonsStyling: false,
});

const swalMixinError = Swal.mixin({
  customClass: {
    confirmButton: "btn btn-swal danger",
  },
  buttonsStyling: false,
});

export const swalRemove = ({
  title = "Deseja remover este item?",
  text = "Ao remover, este item não será mais exibido, esta ação não é reversível, deseja mesmo remover?",
  confirmButtonText = "Sim",
  confirmTitle = "Removido",
  confirmText = "Seu item foi removido!",
  cancelButtonText = "Não",
  cancelTitle = "Cancelado",
  cancelText = "Seu item está mantido!",
  functionConfirmRemove = () => {},
  withError = true,
}) => {
  return swalMixinConfirmCancel
    .fire({
      title,
      text,
      icon: "warning",
      showCancelButton: true,
      confirmButtonText,
      cancelButtonText,
      reverseButtons: true,
    })
    .then((result) => {
      if (result.isConfirmed) {
        functionConfirmRemove(
          () => swalConfirm(confirmTitle, confirmText),
          () =>
            swalError(
              "Ocorreu um erro",
              "Algo aconteceu e seu item não foi removido, tente novamente!"
            )
        );
      } else if (result.dismiss === Swal.DismissReason.cancel && withError) {
        swalError(cancelTitle, cancelText);
      }
    });
};

const swalConfirm = (confirmTitle, confirmText) => {
  return swalMixinConfirm.fire(confirmTitle, confirmText, "success");
};

const swalError = (cancelTitle, cancelText) => {
  return swalMixinError.fire(cancelTitle, cancelText, "error");
};

export const swalQuestion = ({
  title = "Deseja remover este item?",
  text = "Ao remover, este item não será mais exibido, esta ação não é reversível, deseja mesmo remover?",
  confirmButtonText = "Sim",
  cancelButtonText = "Não",
  denyButtonText = undefined,
  showDenyButton = false,
  functionConfirm = () => {},
  functionDeny = () => {},
}) => {
  return swalMixinConfirmCancel
    .fire({
      text,
      icon: "warning",
      showCancelButton: true,
      confirmButtonText,
      cancelButtonText,
      reverseButtons: true,
      title,
      showDenyButton,
      denyButtonText,
    })
    .then((result) => {
      if (result.isConfirmed) {
        functionConfirm();
      } else if (result.isDenied) {
        functionDeny();
      }
    });
};

export const swalInput = async () => {
  let passwordInput = "";
  return await swalMixinConfirmCancel.fire({
    title: "Alteração de senha",
    html: `
      <input type="password" id="password" class="swal2-input" placeholder="Senha">
    `,
    confirmButtonText: "Alterar",
    focusConfirm: false,
    showCancelButton: true,
    cancelButtonText: "Cancelar",
    didOpen: () => {
      const popup = Swal.getPopup();
      passwordInput = popup.querySelector("#password");
      passwordInput.onkeyup = (event) =>
        event.key === "Enter" && Swal.clickConfirm();
    },
    preConfirm: () => {
      const password = passwordInput.value;
      if (!password) {
        Swal.showValidationMessage(`Insira uma senha`);
      }
      return password;
    },
  });
};
