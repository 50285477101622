import { isMobile } from 'react-device-detect';
import { AiOutlineCheck, AiOutlineClose } from 'react-icons/ai';
import { BsFillTrashFill } from 'react-icons/bs';
import { DefaultButton } from '../../components/Button/Button';
import {
  LabelInput,
  SelectInput,
  TextInput,
} from '../../components/Input/Input';
import { inPlaceSort } from 'fast-sort';
import { totalValue } from '../../core/utils/utils';
import { useMemo, useState } from 'react';
import { RelatedServices } from './components/RelatedServices';

const ModalPackAesthetic = ({
  values,
  setValues,
  closeModal,
  isNew,
  onChange,
  onSave,
  compareValues,
  onRemove,
  serviceList,
}) => {
  const [prevRelatedServices, setPrevRelatedServices] = useState(
    values.relatedServices
  );

  const calcValue = () => {
    setValues((val) => ({
      ...val,
      discount: null,
      discount_percentage: null,
      finalValue: null,
      discount_temp: null,
      discount_percentage_temp: null,
    }));
  };

  useMemo(() => {
    if (
      prevRelatedServices &&
      JSON.stringify(prevRelatedServices) !==
        JSON.stringify(values.relatedServices)
    ) {
      setPrevRelatedServices(
        values.relatedServices.map((item) => ({
          ...item,
          p_time: item.p_time || 1,
        }))
      );
      calcValue();
    }
  }, [values.relatedServices, prevRelatedServices]);

  return (
    <div className="modal-service-container responsive-padding-aesthetic">
      <div className="modal-header">
        <div className="header-title">
          <h4 className="modal-title">Pacotes</h4>
        </div>
        <div className="header-buttons">
          {compareValues ? (
            <DefaultButton
              className="modal-footer-button"
              label={isMobile ? '' : 'Salvar'}
              icon={<AiOutlineCheck className="button-icon" />}
              onClick={onSave}
            />
          ) : (
            <div />
          )}
          <DefaultButton
            className="modal-footer-button cancel"
            label={isMobile ? '' : 'Cancelar'}
            icon={<AiOutlineClose className="button-icon-cancel" />}
            onClick={closeModal}
          />
        </div>
      </div>
      <div className="desc-input company">
        <br />
        <div className="bodyshop-title">
          <text>
            {isNew ? (
              <TextInput
                value={values.title}
                placeholder="Adicionar título"
                onChange={(event) =>
                  onChange('title', event.target.value.toUpperCase())
                }
                className="item-title"
              />
            ) : (
              <TextInput
                value={values.title}
                placeholder={values.title}
                onChange={(event) =>
                  onChange('title', event.target.value.toUpperCase())
                }
                className="item-title"
              />
            )}
          </text>
        </div>
        <br />
        <div className="lower-aesthetic-div">
          <div className="main-upper-div">
            <div className="service-description">
              <div style={{ width: '100%' }} className="void-class">
                <SelectInput
                  isMulti={true}
                  onChange={(value) => {
                    onChange('relatedServices', value);
                    setPrevRelatedServices(values.relatedServices);
                    onChange('p_value', null);
                    onChange('finalValue', null);
                  }}
                  placeholder={'Escolha os serviços'}
                  label={'Serviços do pacote'}
                  value={values?.relatedServices?.map((item) => ({
                    ...item,
                    label: item.title,
                    value: item.id,
                  }))}
                  defaultValue={values?.relatedServices?.map((item) => ({
                    ...item,
                    label: item.title,
                    value: item.id,
                  }))}
                  options={inPlaceSort(serviceList).by('title')}
                />
              </div>
            </div>
          </div>
          <div style={{ paddingTop: '15px' }}>
            <label className="label form-label">Tabela</label>
            {values?.relatedServices && values?.relatedServices.length > 0 ? (
              <RelatedServices
                onChange={onChange}
                values={values}
                setValues={setValues}
              />
            ) : (
              <h4 className="modal-title">Nenhum serviço selecionado</h4>
            )}

            <div
              style={{ display: 'flex', paddingBottom: 10 }}
              className="repeat-days-modal"
            >
              <div className="notification-days-mobile">
                <text
                  style={{
                    paddingTop: 28,
                    paddingRight: 5,
                    fontWeight: 500,
                    fontSize: 16,
                    color: 'white',
                  }}
                >
                  {' '}
                  Notificar{' '}
                </text>
                <div
                  style={{
                    width: 155,
                    backgroundColor: '#67676a',
                    maxHeight: 50,
                    borderRadius: 10,
                    display: 'flex',
                    alignItems: 'center',
                    marginTop: 15,
                  }}
                >
                  <LabelInput
                    className={'small-size-input-frequency'}
                    onChange={(value) => onChange('notification_days', value)}
                    placeholder={'___'}
                    value={values.notification_days}
                    label={''}
                    type="number"
                  />
                  <div
                    style={{
                      fontSize: 16,
                      fontWeight: 500,
                      color: 'white',
                      marginRight: 20,
                      backgroundColor: '#67676a',
                    }}
                  >
                    dias
                  </div>
                </div>
              </div>
              <div className="notification-days-mobile">
                <text
                  className="repeat-text"
                  style={{
                    paddingTop: 28,
                    paddingRight: 15,
                    fontWeight: 500,
                    fontSize: 16,
                    color: 'white',
                  }}
                >
                  {' '}
                  Repetir{' '}
                </text>
                <div
                  style={{
                    width: 155,
                    backgroundColor: '#67676a',
                    maxHeight: 50,
                    borderRadius: 10,
                    display: 'flex',
                    alignItems: 'center',
                    marginTop: 15,
                  }}
                >
                  <LabelInput
                    className={'small-size-input-frequency'}
                    onChange={(value) => onChange('repeat', value)}
                    placeholder={'___'}
                    value={values.repeat}
                    label={''}
                    type="number"
                  />
                  <div
                    style={{
                      fontSize: 16,
                      fontWeight: 500,
                      color: 'white',
                      marginRight: 20,
                      backgroundColor: '#67676a',
                    }}
                  >
                    vezes
                  </div>
                </div>
              </div>
            </div>
            <div>
              <LabelInput
                type={'textarea'}
                className="message-area-min"
                value={values.notification_msg}
                onChange={(value) => onChange('notification_msg', value)}
                placeholder={'Insira a mensagem da notificação'}
              />
            </div>
            <div className="modal-footer-table">
              {values && !values.id ? (
                <div />
              ) : (
                <DefaultButton
                  className="modal-footer-button delete"
                  label={'Excluir'}
                  icon={<BsFillTrashFill className="button-icon-cancel" />}
                  onClick={onRemove}
                />
              )}
            </div>
          </div>
        </div>
      </div>
    </div>
  );
};

export default ModalPackAesthetic;
