import React from 'react';

function YouTubeVideo({ id, title }) {
    return (
        <iframe
            width="853"
            height="480"
            src={`https://www.youtube.com/embed/${id}`}
            title={title}
            allow="accelerometer; autoplay; clipboard-write; encrypted-media; gyroscope; picture-in-picture"
            allowFullScreen
        />
    );
}

export default YouTubeVideo;
