import React, { useState } from 'react';
import GridTable from '@nadavshaar/react-grid-table';

const ProductionPhasesAestheticScreen = ({ data,  columns, rows}) => {
    const [filteredData, setFilteredData] = useState(data);
    const [selectedItem, setSelectedItem] = useState(null);

    return (
      <div className='production-container'>
        <div className='production-body gridtable-for-production-phase'>
          <GridTable
            columns={columns}
            rows={rows}
            enableColumnsReorder={false}
            canReorder={false}
            showSearch={true}
            isPaginated={false}
            showColumnVisibilityManager={false}
            showRowsInformation={false}
            texts={{ noResults: 'Nenhum resultado foi encontrado', search: 'Buscar:' }}
                />
        </div>
      </div>
    )
}

export default ProductionPhasesAestheticScreen