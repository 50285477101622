import { supabase } from "../../supabaseClient";

export const savePart = async (id = undefined, item) => {
  if (id) {
    return await supabase
      .from("Part")
      .update({ ...item })
      .match({ id });
  } else {
    return await supabase.from("Part").insert([item]);
  }
};
