import { useEffect, useState } from 'react';
import { useHistory } from 'react-router-dom';
import { alertError, alertSuccess } from '../../components/Toast';
import { getTranslation } from '../../core/Translator';
import RecoveryScreen from '../../screens/Recovery/RecoveryScreen';
import { supabase } from '../../supabaseClient';

const RecoveryContainer = ({ }) => {
  let history = useHistory();
  const [loading, setLoading] = useState(false)
  const [values, setValues] = useState({ email: '', newPassword: '', password: '' });
  const [session, setSession] = useState(null)

  useEffect(() => {
    setSession(supabase.auth.session())

    supabase.auth.onAuthStateChange((_event, session) => {
      setSession(session)
    })
  }, [])

  useEffect(() => {
    if (!session || (session && !session.access_token)) {
      // navigate("/");
    }
  }, [session])

  const handleRecovery = async () => {
    try {
      setLoading(true)
      if (values.email && values.email !== "") {
        let { data, error } = await supabase.auth.api.resetPasswordForEmail(
          values.email,
          { redirectTo: 'http://localhost:3000/reset-password' }
        )
        if (error) throw error
        alertSuccess('O link de recuperação foi enviado para o seu email')
        history.push("/login");
      }
      else {
        alertError('Insira um email válido')

      }

    } catch (error) {
      alertError(getTranslation(error.error_description || error.message))
      // console.log('erros', [error])
    }
    finally {
      setLoading(false)

    }

  }

  const onChange = (field, value) => {
    setValues(values => ({ ...values, [field]: value }))
  }


  return (
    <RecoveryScreen
      handleRecovery={handleRecovery}
      onChange={onChange}
      values={values}
      loading={loading}
      history={history}
    />
  )
}

export default RecoveryContainer