import { LabelInput } from '../../components/Input/Input';
import { BsArrowLeft } from 'react-icons/bs';
import { DefaultButton } from '../../components/Button/Button';

const RecoveryScreen = ({ handleRecovery, onChange, values, loading, history }) => {
  return (
    <>
      <div className='image'>
        <img className='backWorker' src={require("../../assets/images/worker.png").default} />
      </div>
      <div className="login-container">
        <div className="form">
          <div>
            <img className="logo" src={require("../../assets/images/web.png").default} />
          </div>
          <div className='loginInfo'>
            <h2 className="title">Recuperar senha</h2>
            <div className="line" />
            <h5 className="description">Insira seus dados abaixo para alterar senha</h5>
            <div className="inputs">
              <LabelInput
                label={"Email"}
                className={'login-input'}
                classLabel={'loginLabel'}
                value={values.email}
                placeholder={"Insira seu email"}
                type={"email"}
                onChange={value => onChange("email", value)}
              />
            </div>
          </div>
          <div>
            <div className='backBtn' onClick={() => history.push('login')}>
              <BsArrowLeft className='backIcon' />
              <h6 className='forgotPassword'>Voltar para o login</h6>
            </div>
            <div style={{ marginTop: 10 }}>
              <DefaultButton
                disabled={loading}
                label={loading ? "Enviando.." : "Enviar"}
                onClick={handleRecovery}
                className={"shadow-5"}
              />
            </div>
          </div>
        </div>
      </div>
    </>
  )
}

export default RecoveryScreen

export const SucessComp = ({ handleRecovery, onChange, values, loading, history }) => {
  return (
    <>
      <div className='image'>
        <img className='backWorker' src={require("../../assets/images/worker.png").default} />
      </div>
      <div className="login-container">
        <div className="form">
          <div>
            <img className="logo" src={require("../../assets/images/web.png").default} />
          </div>
          <div className='loginInfo'>
            <h2 className="title">Recuperar senha</h2>
            <div className="line" />
            <h5 className="description">Insira seus dados abaixo para alterar senha</h5>
            <div className="inputs">
              <LabelInput
                label={"Nova senha"}
                value={values.newPassword}
                placeholder={"Insira sua nova senha"}
                classLabel={'loginLabel'}
                type={"password"}
                onChange={value => onChange("newPassword", value)}
              />
              <LabelInput
                label={"Repita sua nova senha"}
                value={values.repeatPassword}
                placeholder={"Insira sua nova senha"}
                classLabel={'loginLabel'}
                type={"password"}
                onChange={value => onChange("repeatPassword", value)}
              />
            </div>
          </div>
          <div>
            <div className='backBtn'>
              <BsArrowLeft className='backIcon' />
              <h6 className='forgotPassword'>Voltar para o login</h6>
            </div>
            <div style={{ marginTop: 10 }}>
              <DefaultButton
                disabled={loading}
                label={loading ? "Enviando.." : "Definir"}
                onClick={handleRecovery}
                className={"shadow-5"}
              />
            </div>
          </div>
        </div>
      </div>
    </>
  )
}

