import React from 'react';
import { LabelInput, MoneyInput } from '../../../components/Input/Input';
import { FaPercentage } from 'react-icons/fa';
import { BsTrashFill } from 'react-icons/bs';

export const ColumnsPieceServiceRenderer = ({ data, changePartsValue }) => {
  const handleQuantityValueChange = (value) => {
    if (data.quantity !== value) {
      changePartsValue('quantity', data, value);
    }
  };

  const handlePartsUnitaryValueChange = (value) => {
    changePartsValue('unitaryValue', data, value);
  };

  const handlePartsTotal = (value) => {
    changePartsValue('value', data, value);
  };

  return (
    <div
      className="column-service"
      style={{ maxHeight: '100%', height: '100%', padding: '8px 0' }}
    >
      <div style={{ paddingLeft: '10px' }}>{data.name}</div>
      <div
        style={{
          gap: '5px',
          display: 'flex',
          alignItems: 'center',
          paddingLeft: '5px',
        }}
      >
        <div className="field-actions-budget budget-piece-quantity">
          <LabelInput
            type="text"
            className="budget-service-input"
            onChange={handleQuantityValueChange}
            placeholder={'QUANTIDADE'}
            value={data.quantity}
          />
        </div>
        <div className="field-actions-budget budget-piece-unit">
          <MoneyInput
            type="number"
            className="budget-service-input"
            onChange={handlePartsUnitaryValueChange}
            placeholder={'Valor unit'}
            value={
              data.value && data.quantity
                ? data?.profit ||
                  data?.profit_margin ||
                  data?.discount ||
                  data?.discount_percentage
                  ? parseFloat(data.value) / data.quantity
                  : data.unitaryValue
                : data.unitaryValue
            }
          />
        </div>
        <div className="field-actions-budget budget-piece-total">
          <MoneyInput
            type="number"
            disabled={true}
            className="budget-service-input"
            onChange={handlePartsTotal}
            value={
              isNaN(parseFloat(data.value)) ? 'R$0' : parseFloat(data.value)
            }
          />
        </div>
      </div>
    </div>
  );
};

export const PieceActionCell = ({ data, onRemove, openEditModal }) => {
  return (
    <div className="field-actions">
      <FaPercentage
        className="icon-action edit"
        onClick={() => openEditModal(data)}
      />
      <BsTrashFill
        className="icon-action trash"
        onClick={() => onRemove(data)}
      />
    </div>
  );
};
