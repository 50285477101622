import { useSelector } from 'react-redux';
import BodyShopScreen from '../../screens/BodyShop/BodyShopScreen';

const BodyShopContainer = ({ }) => {
  const bodyShop = useSelector(state => state.app.bodyShop || 1)

  return (
    <BodyShopScreen
      bodyShop={bodyShop}
    />
  )
}

export default BodyShopContainer