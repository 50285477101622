import React, { useEffect, useState } from "react";
import { BiBrush, BiReset } from "react-icons/bi";
import { BsPencilFill } from "react-icons/bs";
import { FiScissors } from "react-icons/fi";
import { useSelector } from 'react-redux';
import { useHistory } from 'react-router-dom/cjs/react-router-dom.min';
import ProductionPhasesAestheticScreen from "../../screens/ProductionPhasesAesthetic/ProductionPhasesAestheticScreen";
import { supabase } from '../../supabaseClient';

const ProductionPhaseAestheticContainer = ({ }) => {
    const [data, setData] = useState([])
    const company = useSelector(state => state.app.company || {})
    const reloadAeProductionPhase = useSelector(state => state.app.reloadAeProductionPhase || false)

    const columns = [
        {
            id: 1,
            field: 'additionalColumn',
            label: 'Ordem',
            width: '100px',
            pinned: true,
            resizable: false,
            cellRenderer: ({ data, rowIndex }) => {

                return <div style={{ paddingLeft: '40px' }}>{rowIndex}</div>;
            }
        },
        {
            id: 2,
            field: 'title',
            label: 'Etapas do processo',
            width: '1fr',
            paddingLeft: '30px !important',
            resizable: false,
        },
        {
            id: 3,
            field: 'actions',
            label: 'Ações',
            pinned: true,
            resizable: false,
            width: '70px',
            cellRenderer: ({ data }) => <ActionCell data={data} />,
        },
    ];

    const rows = [
        {
            serviceType: "",
            name: 'Lavagem',
            value: 2,
        },
        {
            serviceType: "",
            name: 'Desmontagem',
            value: 2,
        },
        {
            serviceType: "",
            name: 'Recuperação',
            value: 2,
        },
        {
            serviceType: "",
            name: 'Preparação',
            value: 2,
        },
    ]

    const ActionCell = ({ data }) => {
        let history = useHistory();
        return (
          <div className='field-actions'>
            <BsPencilFill className='icon-action edit' onClick={() => history.push(`/aesthetic/details/${data.id}`, data)} />
            {/* <BsTrashFill className='icon-action trash' onClick={() => onRemove(data)} /> */}
          </div>
        );
    };

    useEffect(() => {
        loadData()
    }, [company, reloadAeProductionPhase])

    const loadData = async () => {
        let { data, error } = await supabase
            .from('AestheticProcSteps')
            .select('*')
            .eq("id_company", company.id)
            .is('deleted_at', null)
        if (!error) {
            setData(data)
        }
    }

    function obterIcone(serviceType) {
        const icones = {
            paintjob: <BiBrush className='body-shop-icons' />,
            taphestry: <FiScissors className='body-shop-icons' />,
            recover: <BiReset className='body-shop-icons' />,
        };

        return icones[serviceType] || null;
    }

    return (
      <ProductionPhasesAestheticScreen
        ActionCel={ActionCell}
        obterIcone={obterIcone}
        columns={columns}
        rows={data}
        />
    )

}

export default ProductionPhaseAestheticContainer