export function capitalizeFirstLetter(string) {
  return string.charAt(0).toUpperCase() + string.slice(1);
}

export function numberExtractorEditor(value, type = 'R$') {
  if (!value) return 0;
  return `${value}`
    .replaceAll(type, '')
    .replaceAll('.', '|')
    .replaceAll(',', '.')
    .replace('|', ',');
}

export function moneyExtractor(value) {
  if (!value || value === 'null') return null;
  if (`${value}`.includes('R$')) {
    return `${value}`
      .replaceAll('R$', '')
      .replaceAll('.', '')
      .replaceAll(',', '.');
  } else {
    return value;
  }
}

export function moneyBDExtractor(value) {
  return `${value}`
    .replaceAll('$', '')
    .replaceAll(',', '')
    .replaceAll(',', '.');
}

export function percentExtractor(value) {
  if (!value || value === 'null') return null;
  if (`${value}`.includes('%')) {
    return `${value}`
      .replaceAll('%', '')
      .replaceAll('.', '')
      .replaceAll(',', '.');
  } else {
    return value;
  }
}

export function percentBDExtractor(value) {
  return `${value}`
    .replaceAll('%', '')
    .replaceAll(',', '')
    .replaceAll(',', '.');
}

export function justNumbers(text) {
  var numbers = text.replace(/[^0-9]/g, '');
  return numbers;
}

export const validateEmail = (email) => {
  return email.match(
    /^(([^<>()[\]\\.,;:\s@\"]+(\.[^<>()[\]\\.,;:\s@\"]+)*)|(\".+\"))@((\[[0-9]{1,3}\.[0-9]{1,3}\.[0-9]{1,3}\.[0-9]{1,3}\])|(([a-zA-Z\-0-9]+\.)+[a-zA-Z]{2,}))$/
  );
};
