import { supabase } from "../../supabaseClient";

export const saveService = async (id = undefined, item) => {
  if (id) {
    return await supabase
      .from("Services")
      .update({ ...item })
      .match({ id });
  } else {
    return await supabase.from("Services").insert([{ ...item }]);
  }
};
