import { supabase } from "../../supabaseClient";

export const syncAePackServices = async (mainServiceId, relatedServices) => {
    const { data: existingRelations, error: fetchError } = await supabase
        .from('PackAestheticServices')
        .select('id, id_service')
        .eq('id_service_pack', mainServiceId);

    if (fetchError) {
        console.error('Erro ao buscar relações existentes:', fetchError);
        return { error: fetchError };
    }

    const existingRelatedServiceIds = existingRelations.map(r => r.id_service);
    const newRelatedServiceIds = relatedServices.map(s => s.id);

    const toAdd = newRelatedServiceIds.filter(id => !existingRelatedServiceIds.includes(id));
    const toRemove = existingRelatedServiceIds.filter(id => !newRelatedServiceIds.includes(id));

    if (toRemove.length > 0) {
        const { error: deleteError } = await supabase
            .from('PackAestheticServices')
            .delete()
            .in('id_service', toRemove)
            .eq('id_service_pack', mainServiceId);

        if (deleteError) {
            console.error('Erro ao remover relações:', deleteError);
            return { error: deleteError };
        }
    }

    if (toAdd.length > 0) {
        const newRelations = toAdd.map(relatedServiceId => ({
            id_service_pack: mainServiceId,
            id_service: relatedServiceId
        }));

        const { error: insertError } = await supabase
            .from('PackAestheticServices')
            .insert(newRelations);

        if (insertError) {
            console.error('Erro ao adicionar novas relações:', insertError);
            return { error: insertError };
        }
    }

    return { success: true };
};
